import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '../../common';
import { useQueryFilter } from '../../../hooks';
import { FILTERS } from '../../../data/constants';

const HideScoresFilter = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { changeFilter } = useQueryFilter(FILTERS.HIDE_SCORES, onChange);

  const handleChange = status => {
    changeFilter(status);
    onChange(status);
  };

  return (
    <Checkbox
      value={value}
      onChange={handleChange}
      label={t('MATCHES.HIDE_SCORES')}
      style={{
        padding: '14px 18px',
        fontSize: '14px'
      }}
    />
  );
};

HideScoresFilter.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { HideScoresFilter };
