import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  Navigation,
  Marketplace,
  Missions,
  Users,
  Redemptions,
} from '../components/admin';
import { useScrollTop } from '../hooks';

const Template = ({ children }) => {
  useScrollTop();
  return (
    <Row>
      <Col xs={{ order: 2, span: 12 }} sm={{ order: 1, span: 9 }} md={{ order: 1, span: 10 }} className="mt-20">
        {children}
      </Col>
      <Col xs={{ order: 1, span: 12 }} sm={{ order: 2, span: 3 }} md={{ order: 2, span: 2 }} className="mt-20">
        <Navigation />
      </Col>
    </Row>
  );
};

Template.propTypes = {
  children: PropTypes.node.isRequired
};

const AdminPage = () => (
  <Template>
    AdminPage
  </Template>
);

const AdminMarketplace = () => (
  <Template>
    <Marketplace />
  </Template>
);

const AdminMissions = () => (
  <Template>
    <Missions />
  </Template>
);

const AdminUsers = () => (
  <Template>
    <Users />
  </Template>
);

const AdminRedemptions = () => (
  <Template>
    <Redemptions uid="all" />
  </Template>
);

export { AdminPage, AdminMarketplace, AdminMissions, AdminUsers, AdminRedemptions };
