import React, { Fragment, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useFormik } from 'formik';

import { ImageUpload } from './ImageUpload';
import { LoadingSpinner } from '../../LoadingSpinner';
import { LocaleFilter } from '../../filters';

import { marketplaceSelectors } from '../../../redux/slices';
import { MARKETPLACE_RARITY } from '../../../data/constants';
import { useDeepEffect } from '../../../hooks';

const getDefaultItem = languages => ({
  slug: languages.reduce((map, l) => ({ ...map, [l]: '' }), {}),
  image: '',
  color: '#ee5b5b',
  chance: 0,
  codes: []
});

const ModifyModal = ({ nextOrder, onClose, onSubmit, data }) => {
  const { t, i18n: { language, languages } } = useTranslation();
  const [lang, setLang] = useState(language);
  const [codesEditor, setCodesEditor] = useState(null);
  const [inputCodes, setInputCodes] = useState('');

  const isUpdating = useSelector(marketplaceSelectors.selectIsUpdating);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      _id: data?._id,
      slug: data?.slug || languages.reduce((map, l) => ({ ...map, [l]: '' }), {}),
      rarity: data?.rarity || MARKETPLACE_RARITY.COMMON,
      order: data?.order || nextOrder,
      countries: data?.countries || [],
      price: data?.price || 0,
      quantity: data?.quantity || 0,
      chanceVisible: Boolean(data?.chanceVisible),
      items: data?.items || [getDefaultItem(languages)],
    },
    onSubmit: values => onSubmit(values),
    validate: value => {
      const error = {};
      languages.forEach(l => {
        if (!value.slug[l]) {
          error.slug = { ...(error.slug || {}), [l]: true };
        }
      });
      if (!Object.values(MARKETPLACE_RARITY).includes(value.rarity)) {
        error.rarity = true;
      }
      if (typeof value.order !== 'number' || !value.order) {
        error.order = true;
      }
      if (typeof value.price !== 'number' || !value.price) {
        error.price = true;
      }
      if (typeof value.chanceVisible !== 'boolean') {
        error.chanceVisible = true;
      }
      if (!value.items.length) {
        error.noItems = true;
      }
      value.items.forEach((it, ind) => {
        languages.forEach(l => {
          if (!it.slug[l]) {
            if (!error.items) error.items = [];
            error.items[ind] = {
              ...(error.items[ind] || {}),
              slug: {
                ...((error.items[ind] || {}).slug || {}),
                [l]: true
              }
            };
          }
        });

        if (!it.image) {
          if (!error.items) error.items = [];
          error.items[ind] = {
            ...(error.items[ind] || {}),
            image: true,
          };
        }

        if (!it.color) {
          if (!error.items) error.items = [];
          error.items[ind] = {
            ...(error.items[ind] || {}),
            color: true,
          };
        }

        if (typeof it.chance !== 'number' || !it.chance) {
          if (!error.items) error.items = [];
          error.items[ind] = {
            ...(error.items[ind] || {}),
            chance: true,
          };
        }

        if (typeof it.quantity !== 'number' || !it.chance) {
          if (!error.items) error.items = [];
          error.items[ind] = {
            ...(error.items[ind] || {}),
            quantity: true,
          };
        }
      });
      return error;
    }
  });

  const handleOpenEditCodes = useCallback(index => () => {
    setCodesEditor(index);
    setInputCodes((values.items[index]?.codes || []).map(c => c.code).join(','));
  }, []);

  const handleAddItem = () => {
    const items = [...values.items];
    items.push(getDefaultItem(languages));
    setFieldValue('items', items);
  };

  const handleDeleteItem = ind => () => {
    const items = [...values.items];
    items.splice(ind, 1);
    setFieldValue('items', items);
  };

  useEffect(() => {
    if (codesEditor !== null) {
      const codes = [...(values.items[codesEditor].codes || []).filter(c => c.used)];
      const codesSet = new Set(codes.map(c => c.code));
      inputCodes.split(',').forEach(item => {
        if (item.trim() && !codesSet.has(item.trim())) {
          codes.push({ code: item.trim(), used: false });
          codesSet.add(item.trim());
        }
      });
      setFieldValue(`items.${codesEditor}.codes`, codes);
      setFieldValue(`items.${codesEditor}.quantity`, codes.filter(c => !c.used).length);
    }
  }, [inputCodes, codesEditor]);

  useDeepEffect(() => {
    setFieldValue('quantity', Math.max(...values.items.map(item => item.quantity)));
  }, [values.items]);

  return (
    <>
      <Modal show onHide={onClose} dialogClassName="max-width-large">
        {isUpdating && <LoadingSpinner absolute />}
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{t(`MARKETPLACE.${data?._id ? 'UPDATE' : 'CREATE'}_TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-height-none">
          <LocaleFilter className="mb-20" setSelection={setLang} selection={lang} />
          <Row>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.ORDER')}</Form.Label>
              <Form.Control
                type="number"
                value={values.order}
                isInvalid={touched.order && errors.order}
                onChange={({ target }) => (
                  setFieldValue('order', target.value ? parseInt(target.value, 10) : target.value)
                )}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.COUNTRY_SUPPORT')}</Form.Label>
              <Form.Select
                multiple
                value={values.countries}
                isInvalid={touched.countries && errors.countries}
                onChange={({ target }) => {
                  const countries = [...values.countries];
                  const index = countries.indexOf(target.value);
                  if (index > -1) {
                    countries.splice(index, 1);
                  } else {
                    countries.push(target.value);
                  }
                  setFieldValue('countries', countries);
                }}
              >
                {languages.map(lang => (
                  <option key={lang} value={lang}>{lang.toUpperCase()}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.SLUG')}</Form.Label>
              <Form.Control
                value={values.slug[lang]}
                isInvalid={(touched.slug || {})[lang] && (errors.slug || {})[lang]}
                onChange={({ target }) => setFieldValue(`slug.${lang}`, target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.RARITY')}</Form.Label>
              <Form.Select
                value={values.rarity}
                isInvalid={touched.rarity && errors.rarity}
                onChange={({ target }) => setFieldValue('rarity', target.value)}
              >
                {Object.values(MARKETPLACE_RARITY).map(rarity => (
                  <option key={rarity} value={rarity}>{t(`MARKETPLACE.RARITY_${rarity.toUpperCase()}`)}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.PRICE')}</Form.Label>
              <Form.Control
                type="number"
                min={1}
                value={values.price}
                isInvalid={touched.price && errors.price}
                onChange={({ target }) => (
                  setFieldValue('price', target.value ? parseInt(target.value, 10) : target.value)
                )}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2">
              <Form.Label>{t('MARKETPLACE.QUANTITY')}</Form.Label>
              <Form.Control
                readOnly
                title={t('MARKETPLACE.QUANTITY_HINT')}
                value={values.quantity}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-2 text-white mt-2">
              <Form.Check
                type="checkbox"
                label={t('MARKETPLACE.CHANCE_VISIBLE')}
                checked={values.chanceVisible}
                isInvalid={touched.chanceVisible && errors.chanceVisible}
                onChange={() => setFieldValue('chanceVisible', !values.chanceVisible)}
              />
            </Form.Group>
            <Col xs={12} className="text-white mt-3 mb-3 d-flex align-items-center justify-content-between">
              <h4>{t('MARKETPLACE.ITEMS')}</h4>
              <div className="cursor-pointer" onClick={handleAddItem}>
                <MdAdd style={{ fontSize: 25 }} />
              </div>
            </Col>
            {errors.noItems && (
              <div className="text-danger mt-20">
                {t('MARKETPLACE.NO_ITEMS')}
              </div>
            )}
            {values.items.map((item, ind) => (
              <Fragment key={ind}>
                <Form.Group as={Col} xs={1} className="mb-2">
                  <Form.Label>{t('MARKETPLACE.IMAGE')}</Form.Label>
                  <ImageUpload
                    ind={ind}
                    errors={errors}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={5} className="mb-2">
                  <Form.Label>{t('MARKETPLACE.SLUG')}</Form.Label>
                  <Form.Control
                    value={item.slug?.[lang]}
                    isInvalid={touched.items?.[ind]?.slug?.[lang] && errors.items?.[ind]?.slug?.[lang]}
                    onChange={({ target }) => setFieldValue(`items.${ind}.slug.${lang}`, target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={1} className="mb-2">
                  <Form.Label>{t('MARKETPLACE.COLOR')}</Form.Label>
                  <Form.Control
                    type="color"
                    className="border-0 bg-transparent"
                    value={item.color}
                    isInvalid={touched.items?.[ind]?.color && errors.items?.[ind]?.color}
                    onChange={({ target }) => setFieldValue(`items.${ind}.color`, target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={2} className="mb-2">
                  <Form.Label>{t('MARKETPLACE.QUANTITY')}</Form.Label>
                  <Form.Control
                    type="number"
                    readOnly={item.codes.length}
                    value={item.quantity}
                    isInvalid={touched.items?.[ind]?.quantity && errors.items?.[ind]?.quantity}
                    onChange={({ target }) => (
                      setFieldValue(`items.${ind}.quantity`, target.value ? parseFloat(target.value, 10) : target.value)
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={1} className="mb-2">
                  <Form.Label>{t('MARKETPLACE.CODES')}</Form.Label>
                  <Form.Control
                    readOnly
                    value={item.codes?.length || 0}
                    onClick={handleOpenEditCodes(ind)}
                  />
                </Form.Group>
                <Col xs={2} className="d-flex justify-content-between gap-2">
                  <Form.Group className="flex-grow-1">
                    <Form.Label>{t('MARKETPLACE.CHANCE')}</Form.Label>
                    <Form.Control
                      type="number"
                      value={item.chance}
                      min={0}
                      max={100}
                      step={0.1}
                      isInvalid={touched.items?.[ind]?.chance && errors.items?.[ind]?.chance}
                      onChange={({ target }) => (
                        setFieldValue(`items.${ind}.chance`, target.value ? parseFloat(target.value, 10) : target.value)
                      )}
                    />
                  </Form.Group>
                  <div
                    className={cx('text-white mb-20 p-1 cursor-pointer d-flex align-items-end', {
                      'opacity-25': item.codes?.length && item.codes?.find(c => c.used)
                    })}
                    onClick={item.codes?.length && item.codes?.find(c => c.used) ? undefined : handleDeleteItem(ind)}
                  >
                    <MdDelete />
                  </div>
                </Col>
              </Fragment>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          {Object.keys(errors).length && (
            <div className="text-danger mt-20">
              {t('MARKETPLACE.CHECK_ANOTHER_LANG')}
            </div>
          )}
          <div className="mt-20 d-flex justify-content-end gap-2">
            <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
            <Button type="submit" className="btn-midnight selected" onClick={handleSubmit}>
              {t('SUBMIT')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {codesEditor !== null && (
        <Modal show onHide={() => setCodesEditor(null)} fullscreen>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>{t('MARKETPLACE.CODES')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-height-none">
            <p className="text-white">{t('MARKETPLACE.CODES_TYPE_HINT')}</p>
            <Form.Control
              as="textarea"
              defaultValue={inputCodes}
              onBlur={({ target }) => setInputCodes(target.value)}
              size={5}
            />
            <Table variant="dark" className="mt-20">
              <thead>
                <tr>
                  <th>{t('MARKETPLACE.CODES_TABLE.CODE')}</th>
                  <th>{t('MARKETPLACE.CODES_TABLE.USED')}</th>
                </tr>
              </thead>
              <tbody>
                {values.items[codesEditor].codes.map(item => (
                  <tr key={`${item.code}_${item.used}`}>
                    <td>{item.code}</td>
                    <td>{`${item.used}`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

ModifyModal.propTypes = {
  nextOrder: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export { ModifyModal };
