import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Accordion, } from 'react-bootstrap';

import { ALL_FILTER } from '../../../data/constants';
import { matchesSelectors, seriesSelectors } from '../../../redux/slices';

import { MatchMapFilter } from '../../filters';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MatchMap } from '../MatchMap';

const MatchDetails = ({ matchId }) => {
  const { t } = useTranslation();

  const [filterMap, setFilterMap] = useState(ALL_FILTER);

  const serie = useSelector(state => seriesSelectors.selectSerieById(state, matchId));
  const { matches, isLoading } = useSelector(matchesSelectors.selectMatchDetails);
  const mapsOptions = useSelector(matchesSelectors.selectMatchMapsIndexes);

  const filteredMatches = useMemo(() => (
    (matches || []).filter(match => (
      !!match.map && (filterMap !== ALL_FILTER ? match.index === filterMap : true)
    ))
  ), [filterMap, matches]);

  return (
    <Accordion defaultActiveKey="match-details" flush>
      <Accordion.Item eventKey="match-details">
        <Accordion.Header className="d-lg-none">
          {t('MATCH_DETAILS.TABS.PREDICTIONS')}
        </Accordion.Header>
        <Accordion.Body>
          <Row className="show-grid mb-20">
            <Col xs={12} lg={3} xxl={2} className="d-none d-lg-flex align-items-center">
              <h3 className="mb-0 d-none d-lg-block text-nowrap">{t('MATCH_DETAILS.TABS.PREDICTIONS')}</h3>
            </Col>
            <Col xs={12} lg={9} xxl={10} className="d-flex justify-content-end">
              <MatchMapFilter
                options={mapsOptions}
                value={filterMap}
                onChange={setFilterMap}
              />
            </Col>
          </Row>
          <Row className="mb-20">
            {isLoading && (
              <LoadingSpinner />
            )}
            {!isLoading && filteredMatches.length === 0 && (
              <div className="text-white text-center">
                {t('MATCH_DETAILS.MAPS.NO_RESULTS')}
              </div>
            )}
            {filteredMatches.map(match => (
              <Col xs={12} md={6} key={match.id} className="mb-4">
                <MatchMap
                  key={match.id}
                  match={match}
                  serie={serie}
                />
              </Col>
            ))}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

MatchDetails.propTypes = {
  matchId: PropTypes.string.isRequired
};

export { MatchDetails };
