import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';

import { missionsActions, missionsSelectors } from '../../../redux/slices';
import { LocaleFilter } from '../../filters';
import { useGames } from '../../../hooks';

import { MissionItem } from './MissionItem';
import { ModifyModal } from './ModifyModal';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Checkbox } from '../../common';

const Missions = () => {
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(null);
  const [lang, setLang] = useState(language);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const items = useSelector(missionsSelectors.selectData);
  const isLoading = useSelector(missionsSelectors.selectIsLoading);
  const isUpdating = useSelector(missionsSelectors.selectIsUpdating);

  useGames();

  useEffect(() => {
    dispatch(missionsActions.loadData.base({
      includeDeleted: includeDeleted || undefined,
      includeUnVisible: true
    }));
    return () => dispatch(missionsActions.clearData.base());
  }, [includeDeleted]);

  useEffect(() => {
    if (!isUpdating) {
      setModal(null);
    }
  }, [isUpdating]);

  const handleOpenCreate = useCallback(() => setModal({}), []);
  const handleOpenEdit = useCallback(data => () => setModal(data), []);
  const handleClose = useCallback(() => setModal(null), []);

  const handleCreateUpdate = useCallback(data => {
    if (data._id) {
      dispatch(missionsActions.update.base({ id: data._id, data }));
    } else {
      dispatch(missionsActions.create.base({ data }));
    }
  }, []);

  const handleDelete = useCallback(data => () => dispatch(missionsActions.delete.base({ id: data._id })), []);

  return (
    <div className="show-grid mb-20">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-2 text-nowrap">{t('MISSIONS.ADMIN_PAGE_TITLE')}</h3>
        <div className="d-flex gap-3">
          <Checkbox
            value={includeDeleted}
            onChange={() => setIncludeDeleted(!includeDeleted)}
            label={t('MISSIONS.INCLUDE_DELETED')}
            style={{
              padding: '14px 18px',
              fontSize: '14px'
            }}
          />
          <Button
            className={cx('btn-midnight', { selected: modal?.mode === 'create' })}
            onClick={handleOpenCreate}
          >
            {t('CREATE')}
          </Button>
        </div>
      </div>
      <LocaleFilter className="mt-20" selection={lang} setSelection={setLang} />
      <Table variant="dark" className="mt-20" hover>
        <thead>
          <tr>
            <th>{t('MISSIONS.ORDER')}</th>
            <th>{t('MISSIONS.TYPE')}</th>
            <th>{t('MISSIONS.TITLE')}</th>
            <th>{t('MISSIONS.DESCRIPTION')}</th>
            <th>{t('MISSIONS.PRICE')}</th>
            <th>{t('MISSIONS.CONDITIONS')}</th>
            <th>{t('MISSIONS.VISIBLE')}</th>
            <th>{t('MISSIONS.STATUS')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isLoading && !items?.length && (
            <tr><td colSpan={9} className="text-center">{t('MISSIONS.NO_RESULTS')}</td></tr>
          )}
          {items?.map(item => (
            <MissionItem
              key={item._id}
              data={item}
              lang={lang}
              onEdit={handleOpenEdit(item)}
              onDelete={handleDelete(item)}
            />
          ))}
        </tbody>
      </Table>
      {isLoading && (
        <LoadingSpinner />
      )}
      {modal && (
        <ModifyModal
          nextOrder={items.length + 1}
          data={modal}
          onSubmit={handleCreateUpdate}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export { Missions };
