import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { PredictionSlip } from '../components/Match';
import { LatestTips, TipsterRank } from '../components/Tips';
import { MissionListWidget } from '../components/Rewards/Missions';
import { LeaderboardEvent } from '../components/Leaderboards';
// import { PartnerBanner } from '../components/PartnerBanner';

const LeaderboardDetailsPage = () => {
  const { id } = useParams();
  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        {/* <PartnerBanner className="mb-20" partner={PARTNERS.BETWAYESPORTS} /> */}
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <LeaderboardEvent id={id} />
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { LeaderboardDetailsPage };
