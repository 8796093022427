import React from 'react';
import { Dropdown, Image, Placeholder } from 'react-bootstrap';
import TimerIcon from '../../../assets/images/icons/timer-icon.svg';

const NotificationItemPlaceholder = () => (
  <Dropdown.Item as={Placeholder} animation="glow" style={{ lineHeight: 'normal' }}>
    <div>
      <Image src={TimerIcon} alt="" className="me-1" style={{ height: 12, marginTop: -1 }} />
      <Placeholder style={{ width: 100, height: 12, minHeight: 12 }} />
    </div>
    <Placeholder style={{ width: '100%', height: 12, minHeight: 12 }} />
    <Placeholder style={{ width: '100%', height: 12, minHeight: 12 }} />
  </Dropdown.Item>
);

export { NotificationItemPlaceholder };
