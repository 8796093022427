import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Nav, Navbar, Offcanvas, Image, Button,
} from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { Aside } from '../common';
import { Languages } from './Languages';
import { DiamondsCount } from './DiamondsCount';
import { Notifications } from './Notifications';
import { UserMenu, UserMenuSmall } from './UserMenu';

import { userSelectors } from '../../redux/slices';

import Logo from '../../assets/images/logo-gold-white.png';
import { menuPages, pages, routes } from '../../data/menu';
import { NAVIGATION_ADMIN } from '../../data/constants';

import './styles.scss';

const Navigation = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeKey, setActiveKey] = useState('/');
  const [offcanvasShown, setOffcanvasShown] = useState(false);

  const user = useSelector(userSelectors.selectUserDetails);
  const isAdmin = !!user?.roles?.find(r => NAVIGATION_ADMIN.includes(r))?.length;

  useEffect(() => {
    const active = menuPages.find((item) => (
      item.routes.find(r => pathname.includes(r.split('/')[1])) && item.slug !== pages.home
    ));
    if (active) {
      setActiveKey(active.slug);
    }
    if (pathname === '/') {
      setActiveKey(pages.home);
    }
  }, [pathname]);

  const handleChangeRoute = useCallback((slug) => {
    const item = menuPages.find(m => m.slug === slug) || menuPages[0];
    setActiveKey(slug);
    navigate(item.routes[0]);
    setOffcanvasShown(false);
  }, []);

  const handleOpenOffcanvas = useCallback(() => {
    setOffcanvasShown(true);
  }, []);

  const handleCloseOffcanvas = useCallback(() => {
    setOffcanvasShown(false);
  }, []);

  const handleOpenProfile = useCallback(() => {
    if (user) {
      navigate(routes[pages.profile].replace(':username', user.username));
    }
    setOffcanvasShown(false);
  }, [user]);

  const handleOpenAdmin = useCallback(() => {
    if (user) {
      navigate('/admin');
    }
    setOffcanvasShown(false);
  }, [user]);

  return (
    <Navbar variant="dark" bg="dark" expand={false} fixed="top">
      <div className="navbar-menu d-flex flex-nowrap justify-content-between flex-grow-1">
        <Navbar.Brand onClick={() => handleChangeRoute(pages.home)}>
          <Image src={Logo} alt="logo" />
          <div className="text-midnight fs-medium">beta</div>
        </Navbar.Brand>
        <Nav
          className="w-100 flex-row d-none d-xxl-flex"
          activeKey={activeKey}
          onSelect={handleChangeRoute}
        >
          {menuPages.map((item) => (
            <Nav.Link key={item.slug} eventKey={item.slug}>{t(`TOPBAR.MENU.${item.slug.toUpperCase()}`)}</Nav.Link>
          ))}
        </Nav>
        {user && (
          <div className="d-xl-none d-flex p-2 align-items-center gap-2">
            <DiamondsCount count={user?.wallet?.diamonds} />
            <Notifications />
          </div>
        )}
      </div>
      <div className="navbar-settings d-flex align-items-center justify-content-end justify-content-xl-center">
        <Languages className="d-xxl-flex d-none" />
        <UserMenu onOpenProfile={handleOpenProfile} onOpenAdmin={isAdmin ? handleOpenAdmin : undefined} />
        <Navbar.Toggle onClick={handleOpenOffcanvas} aria-controls="navbar" className="d-xxl-none" />
      </div>
      <Aside
        ariaLabelledby="navbar"
        show={offcanvasShown}
        onClose={handleCloseOffcanvas}
        classes={{
          body: 'd-flex flex-column justify-content-between'
        }}
        title={(
          <Offcanvas.Title onClick={() => handleChangeRoute(pages.home)}>
            <Image src={Logo} alt="small logo" />
          </Offcanvas.Title>
        )}
      >
        <div>
          <UserMenuSmall onOpenProfile={handleOpenProfile} />
          <Nav
            className="justify-content-end"
            activeKey={activeKey}
            onSelect={handleChangeRoute}
          >
            {menuPages.map((item) => (
              <Nav.Link key={item.slug} eventKey={item.slug}>{t(`TOPBAR.MENU.${item.slug.toUpperCase()}`)}</Nav.Link>
            ))}
            {isAdmin && (
              <Nav.Link onClick={handleOpenAdmin}>{t('TOPBAR.PROFILE.ADMIN')}</Nav.Link>
            )}
          </Nav>
        </div>
        <div className="buttons-down">
          <Languages className="navbar-btn-small btn-lang me-auto mt-3 dropup" />
          {user && (
            <Button
              size="lg"
              variant="light"
              className="navbar-btn-small btn-missions mt-3 text-uppercase"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              {t('TOPBAR.PROFILE.LOGOUT')}
            </Button>
          )}
        </div>
      </Aside>
    </Navbar>
  );
};

export { Navigation };
