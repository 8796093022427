import { store, injectReducer, sagaMiddleware } from '../helpers/store';

import sagas from './sagas';
import {
  user, tips, series, matches, teams, missions, notifications, tournaments, marketplace, redemptions
} from './slices';
import { initSockets } from './sockets';

injectReducer('user', user);
injectReducer('tips', tips);
injectReducer('series', series);
injectReducer('matches', matches);
injectReducer('teams', teams);
injectReducer('missions', missions);
injectReducer('notifications', notifications);
injectReducer('tournaments', tournaments);
injectReducer('marketplace', marketplace);
injectReducer('redemptions', redemptions);

sagaMiddleware.run(sagas);
initSockets(store);

export { store };
