export const TASKS_KEYS = {
  CREATE_TIP: 'CREATE_TIP',
  LIKE_TIP: 'LIKE_TIP',
  VOTE: 'VOTE',
  MATCH_WON: 'MATCH_WON',
  MAP_WON: 'MAP_WON'
};

export const TASKS = [
  {
    action: TASKS_KEYS.CREATE_TIP,
    diamonds: 10,
    limitPerDay: 5
  },
  {
    action: TASKS_KEYS.LIKE_TIP,
    diamonds: 2,
    limitPerDay: 10
  },
  {
    action: TASKS_KEYS.VOTE,
    diamonds: -2,
    // limitPerDay: 5
  },
  {
    action: TASKS_KEYS.MATCH_WON,
    diamonds: 10,
  },
  {
    action: TASKS_KEYS.MAP_WON,
    diamonds: 5,
  },
];
