import { all, put, call, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { tipsActions, seriesActions } from '../slices';

export function* loadLatestTipsFlow() {
  try {
    const response = yield call(api.getLatestTips);
    const ids = response.results.map(r => r.serie);
    yield all([
      put(seriesActions.loadSeries.base({ ids })),
      put(tipsActions.loadLatestTips.success(response))
    ]);
  } catch (error) {
    yield put(tipsActions.loadLatestTips.failed({ error }));
  }
}

export function* likeDislikeTipFlow({ payload: { id, key } }) {
  try {
    let status;
    if (key === 'likes') {
      const response = yield call(api.sendLikeTip, id);
      status = response === 'TIP_LIKED';
    } else {
      const response = yield call(api.sendDislikeTip, id);
      status = response === 'TIP_DISLIKED';
    }
    yield put(tipsActions.likeDislikeTip.success({ id, key, status }));
  } catch (error) {
    yield put(tipsActions.likeDislikeTip.failed({ error }));
  }
}

export function* loadTipsFlow({ payload: { serie, filter, game, page, limit } }) {
  try {
    const response = yield call(api.getTips, { serie, filter, game, page, limit });
    const series = Array.from(new Set(response.results.map(r => r?.serie).filter(r => !!r)));
    yield all([
      put(tipsActions.loadTips.success(response)),
      put(seriesActions.loadSeries.base({ ids: series })),
    ]);
  } catch (error) {
    yield put(tipsActions.loadTips.failed({ error }));
  }
}

export function* createTipFlow({ payload: { callback, ...data } }) {
  try {
    const response = yield call(api.createTip, data);
    yield put(tipsActions.createTip.success(response));
  } catch (error) {
    yield put(tipsActions.createTip.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* updateTipFlow({ payload: { callback, id, comment } }) {
  try {
    const response = yield call(api.updateTip, id, { comment });
    yield put(tipsActions.updateTip.success(response));
  } catch (error) {
    yield put(tipsActions.updateTip.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* deleteTipFlow({ payload: { callback, id } }) {
  try {
    yield call(api.deleteTip, id);
    yield put(tipsActions.deleteTip.success({ id }));
  } catch (error) {
    yield put(tipsActions.deleteTip.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* checkCreatedTipsFlow({ payload: { ids } }) {
  try {
    const response = yield call(api.checkCreatedTips, ids);
    yield put(tipsActions.checkCreatedTips.success(response));
  } catch (error) {
    yield put(tipsActions.checkCreatedTips.failed({ error }));
  }
}

export function* loadTipsForProfileFlow({ payload: { username, page, game } }) {
  try {
    const response = yield call(api.getLatestTips, { username, page, game });
    const ids = response.results.map(r => r.serie);
    yield all([
      put(seriesActions.loadSeries.base({ ids })),
      put(tipsActions.loadTipsForProfile.success(response))
    ]);
  } catch (error) {
    yield put(tipsActions.loadTipsForProfile.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(tipsActions.loadLatestTips.types.BASE, loadLatestTipsFlow),
    takeLatest(tipsActions.likeDislikeTip.types.BASE, likeDislikeTipFlow),
    takeLatest(tipsActions.loadTips.types.BASE, loadTipsFlow),
    takeLatest(tipsActions.createTip.types.BASE, createTipFlow),
    takeLatest(tipsActions.updateTip.types.BASE, updateTipFlow),
    takeLatest(tipsActions.deleteTip.types.BASE, deleteTipFlow),
    takeLatest(tipsActions.checkCreatedTips.types.BASE, checkCreatedTipsFlow),
    takeLatest(tipsActions.loadTipsForProfile.types.BASE, loadTipsForProfileFlow),
  ]);
}
