import io from 'socket.io-client';

import { TOKEN_STORAGE } from '../data/constants';

const socket = io(`${process.env.REACT_APP_API_ENDPOINT}`, {
  auth: {
    token: localStorage.getItem(TOKEN_STORAGE)
  }
});

let socketId = null;
let reconnectionAttempts = 0;

socket.on('connect', () => {
  socketId = socket.id;
  // eslint-disable-next-line no-console
  console.log(`[SOCKET] [connect] ${socketId}`);
});

socket.on('disconnect', () => {
  // eslint-disable-next-line no-console
  console.log(`[SOCKET] [disconnect] ${socketId}`);
});

socket.on('connect_error', reason => {
  reconnectionAttempts = Math.max(reconnectionAttempts + 1, 10);
  // eslint-disable-next-line no-console
  console.log(`[SOCKET] [connect_error] ${socketId}: ${reason}`);
  setTimeout(() => {
    socket.auth.token = localStorage.getItem(TOKEN_STORAGE);
    socket.connect();
  }, reconnectionAttempts * 1000);
});

export { socket };
