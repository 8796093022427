import React from 'react';
import PropTypes from 'prop-types';

const MatchLiveStatsWidget = ({ matchId, ...props }) => (
  <iframe
    id="abios-live-stats"
    name="abios-live-stats"
    src={`https://widget.abiosgaming.com/estack-gg/live-stats/${matchId}`}
    width="100%"
    {...props}
  />
);

MatchLiveStatsWidget.propTypes = {
  matchId: PropTypes.string.isRequired
};

export { MatchLiveStatsWidget };
