import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import { teamsActions, teamsSelectors } from '../../../redux/slices';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TeamDetails } from './TeamDetails';

const TournamentParticipants = ({ tournamentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, rosters } = useSelector(teamsSelectors.selectTournamentParticipants);

  useEffect(() => {
    dispatch(teamsActions.loadTournamentParticipants.base({ id: tournamentId }));
  }, [tournamentId]);

  return (
    <Card bg="dark" className="large-card always">
      <Card.Header>
        {t('TOURNAMENTS.TITLE_PARTICIPANTS', { count: rosters.length })}
      </Card.Header>
      <div style={{ maxHeight: 500, overflowY: 'auto' }}>
        {rosters.map(data => (
          <TeamDetails key={data.id} data={data} />
        ))}
      </div>
      {!rosters.length && !isLoading && (
        <Card.Body className="text-center text-white">
          {t('TOURNAMENTS.NO_PARTICIPANTS')}
        </Card.Body>
      )}
      {isLoading && (
        <LoadingSpinner />
      )}
    </Card>
  );
};

TournamentParticipants.propTypes = {
  tournamentId: PropTypes.string.isRequired
};

export { TournamentParticipants };
