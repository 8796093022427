import moment from 'moment';
import { TASKS } from '../data/diamonds';

export const getTournamentTitle = title => {
  if (!title) return title;
  const arr = title.split(' ');
  arr.shift();
  return arr.join(' ');
};

export const getPredictionMax = predictions => parseInt(
  ((predictions?.teamA?.predictors || 0) + (predictions?.teamB?.predictors || 0) || 1),
  10
);

export const getPredictionPercent = (predictions, roster) => parseInt(
  (
    (predictions?.teamA?.roster === roster
      ? (predictions?.teamA?.predictors || 0)
      : (predictions?.teamB?.predictors || 0)
    ) / getPredictionMax(predictions)
  ) * 100,
  10
);

export const getIsStarted = serie => Boolean(serie?.start ? (
  +new Date(serie?.start) < +new Date()
  || serie?.lifecycle === 'live'
) : false);

export const getIsEnded = serie => Boolean(serie?.start ? (
  serie?.end
  || (serie?.end ? +new Date(serie?.end) : +new Date()) < +new Date()
) : false);

export const processLikeDislike = (tipsData, { id, key, status }) => {
  const index = tipsData.findIndex(item => item._id === id);
  const isLiked = key === 'likes';
  if (index > -1) {
    if (status && typeof tipsData[index].isLiked !== 'undefined') {
      if (tipsData[index].isLiked && isLiked) {
        tipsData[index].likes--;
      } else if (tipsData[index].isLiked && !isLiked) {
        tipsData[index].likes--;
        tipsData[index].dislikes++;
      } else if (!tipsData[index].isLiked && isLiked) {
        tipsData[index].likes++;
        tipsData[index].dislikes--;
      } else {
        tipsData[index].dislikes--;
      }
      tipsData[index].isLiked = isLiked;
    } else if (status) {
      tipsData[index][key]++;
      tipsData[index].isLiked = isLiked;
    } else {
      tipsData[index][key]--;
      delete tipsData[index].isLiked;
    }
  }
  return tipsData;
};

export const transformPredictionSlipDataInStore = (predictionSlip, predictions) => (
  predictionSlip.reduce((map, { prediction, participants }) => {
    const id = prediction.prediction === 'serie' ? prediction.serie : prediction.match;
    const existed = (predictions || {})[id];
    return {
      ...map,
      [id]: {
        myVote: prediction.team,
        teamA: {
          team: participants[0].roster?.id === prediction.roster ? prediction.team : undefined,
          roster: participants[0].roster?.id,
          predictors: (prediction.roster === participants[0].roster?.id ? 1 : 0) + (existed?.teamA?.predictors || 0)
        },
        teamB: {
          team: participants[1].roster?.id === prediction.roster ? prediction.team : undefined,
          roster: participants[1].roster?.id,
          predictors: (prediction.roster === participants[1].roster?.id ? 1 : 0) + (existed?.teamB?.predictors || 0)
        },
      }
    };
  }, {})
);

export const getClaimDiamonds = (key, claims, size, negative) => {
  let diamonds = 0;
  const today = moment().format('YYYY-MM-DD');
  const task = TASKS.find(t => t.action === key);
  if (moment(claims?.[key]?.claimDate).format('YYYY-MM-DD') !== today) {
    diamonds += (task.diamonds * (task.limitPerDay ? Math.min(task.limitPerDay, size) : size));
  } else {
    diamonds += (task.diamonds * (
      task.limitPerDay ? Math.min(task.limitPerDay - (claims?.[key]?.count || 0), size) : size
    ));
  }

  return negative ? Math.min(0, diamonds) : Math.max(0, diamonds);
};

export const updatePredictions = (state, payload) => {
  state.predictions = {
    ...state.predictions,
    ...Object.keys(payload).reduce((map, key) => ({
      ...map,
      [key]: {
        ...state.predictions[key],
        ...payload[key]
      }
    }), {})
  };
};

export const getActivityMetadata = (metadata, language) => ({
  ...metadata,
  slug: metadata.slug?.[language],
  item: metadata.item ? {
    ...metadata.item,
    slug: metadata.item.slug?.[language]
  } : undefined,
  claimDate: moment(metadata.claimDate).format('LL'),
  teamName: metadata.teamA?.id === metadata.team
    ? metadata.teamA?.name
    : metadata.teamB?.name,
  tournamentTitle: getTournamentTitle(metadata.tournamentTitle),
  missionName: metadata.title?.[language],
});
