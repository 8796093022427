import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ButtonGroup, Image, Dropdown, Badge, Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BellIcon from '../../../assets/images/icons/notifications.svg';

import { notificationsActions, notificationsSelectors } from '../../../redux/slices';
import { NOTIFICATIONS_PAGE_SIZE as LIMIT, NOTIFICATIONS_FILTERS as FILTERS } from '../../../data/constants';

import { NotificationItem } from './NotificationItem';
import { NotificationItemPlaceholder } from './NotificationItemPlaceholder';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <ButtonGroup
    ref={ref}
    onClick={onClick}
    className={cx(className, 'text-white cursor-pointer')}
    {...props}
  >
    {children}
  </ButtonGroup>
));

CustomToggle.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const Notifications = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { total, data, unread, page, filter, isLoading } = useSelector(notificationsSelectors.selectData);

  const handleLoad = useCallback(() => {
    dispatch(notificationsActions.loadNotifications.base());
  }, []);

  const handleSetFilter = useCallback(f => {
    dispatch(notificationsActions.setNotificationFilter.base(f));
    dispatch(notificationsActions.loadNotifications.base());
  }, []);

  const handleRead = useCallback(id => () => {
    dispatch(notificationsActions.setNotificationRead.base({ id }));
  }, []);

  return (
    <Dropdown align="end" className="notifications" autoClose="outside">
      <Dropdown.Toggle as={CustomToggle} {...props}>
        <Image src={BellIcon} alt="Bell" />
        {unread > 0 && (
          <span className="custom-badge" />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Header className="d-flex justify-content-between align-items-center">
          <span className="fs-normal">{t('NOTIFICATIONS.TITLE')}</span>
          {unread > 0 && (
            <Badge bg="danger">{unread}</Badge>
          )}
        </Dropdown.Header>
        <div className="d-flex justify-content-between align-items-center pe-3 ps-3 pb-3">
          <Tabs activeKey={filter} onSelect={handleSetFilter} className="flex-row">
            {Object.keys(FILTERS).map(tab => (
              <Tab key={tab} eventKey={FILTERS[tab]} title={t(`NOTIFICATIONS.TABS.${tab}`)} />
            ))}
          </Tabs>
          {unread > 0 && (
            <div className="fs-small cursor-pointer" onClick={handleRead('all')}>
              {t('NOTIFICATIONS.READ_ALL')}
            </div>
          )}
        </div>
        <div className="scroll">
          {data.map(item => (
            <NotificationItem key={item._id} data={item} onSetRead={handleRead(item._id)} />
          ))}
          {!isLoading && !data.length && (
            <Dropdown.Item className="text-center cursor-none">
              {t('NOTIFICATIONS.NO_RESULTS')}
            </Dropdown.Item>
          )}
          {isLoading && (
            <>
              <NotificationItemPlaceholder />
              <NotificationItemPlaceholder />
            </>
          )}
          {!isLoading && total > page * LIMIT && (
            <Dropdown.Item className="text-center" onClick={handleLoad}>
              {t('LOAD_MORE')}
            </Dropdown.Item>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
};

Notifications.defaultProps = {
  className: '',
};

export { Notifications };
