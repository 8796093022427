import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Dropdown } from 'react-bootstrap';
import { MdCheckCircle, MdMoreVert } from 'react-icons/md';

import { getDocumentPath } from '../../../api';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const RedemptionItem = ({ onApprove, isAdmin, data: {
  userDetails, status, marketplaceItem, item, createdAt, approvedAt
} }) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <tr>
      {isAdmin && (
        <td>
          <span className="d-block fs-medium lh-1">{userDetails.username}</span>
          <span className="fs-smallest">{userDetails.email}</span>
        </td>
      )}
      <td>
        <span className="d-block fs-medium lh-1">{marketplaceItem.slug[language]}</span>
        <span className="fs-smallest">{t(`MARKETPLACE.RARITY_${marketplaceItem.rarity.toUpperCase()}`)}</span>
      </td>
      <td className="d-flex gap-2">
        <div
          style={{
            backgroundImage: `url("${getDocumentPath(item.image)}")`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: 45,
            width: 30,
            border: '1px solid #f2f2f2'
          }}
        />
        <div style={{ color: item.color }}>
          <span className="d-block fs-medium lh-1">{item.slug[language]}</span>
          {item.meta?.code && (
            <span className="fs-smallest">{`${t('MARKETPLACE.GIFT_CODE')}: ${item.meta.code}`}</span>
          )}
        </div>
      </td>
      <td className={cx('fs-medium', { 'text-success': status === 'approved' })}>
        {t(`REDEMPTIONS.STATUS_${status.toUpperCase()}`)}
      </td>
      <td>
        <span className="d-block fs-medium">
          {`${t('REDEMPTIONS.CREATED_AT')} : ${moment(createdAt).format('LLL')}`}
        </span>
        {approvedAt && (
          <span className="d-block fs-medium">
            {`${t('REDEMPTIONS.APPROVED_AT')} : ${moment(approvedAt).format('LLL')}`}
          </span>
        )}
      </td>
      {isAdmin && (
        <td>
          {status !== 'approved' && (
            <Dropdown align="start" className="pt-1">
              <Dropdown.Toggle
                as={CustomToggle}
                className="d-flex align-items-center justify-content-center cursor-pointer ps-1 pe-1 h-100"
              >
                <MdMoreVert className="text-secondary" />
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark">
                <Dropdown.Item
                  className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
                  onClick={onApprove}
                >
                  <MdCheckCircle />
                  {t('APPROVE')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      )}
    </tr>
  );
};

RedemptionItem.propTypes = {
  data: PropTypes.shape({
    userDetails: PropTypes.shape({
      username: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    status: PropTypes.string.isRequired,
    marketplaceItem: PropTypes.shape({
      slug: PropTypes.object,
      rarity: PropTypes.string,
    }).isRequired,
    item: PropTypes.shape({
      slug: PropTypes.object,
      image: PropTypes.string,
      color: PropTypes.string,
      meta: PropTypes.object,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    approvedAt: PropTypes.string,
  }).isRequired,
  onApprove: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export { RedemptionItem };
