import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { LoadingSpinner } from '../../LoadingSpinner';
import { matchesSelectors, teamsSelectors, tipsActions } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';
import { TipCreationContent } from './TipCreationContent';

const TipCreationModal = ({ onClose, data: { id, start, tournament, participants } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const teamsByRosters = useSelector(teamsSelectors.selectTeamsRosters);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, id));

  const [teamA, teamB] = useMemo(() => participants.map(p => ({
    ...p,
    team: teamsByRosters[p.roster.id]
  })), [participants, teamsByRosters]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (comment.length >= 5 && comment.length <= 500) {
      dispatch(tipsActions.createTip.base({ id, comment, callback: onClose }));
      setIsSubmitted(true);
    }
  };

  return (
    <Modal show onHide={onClose} dialogClassName="max-width-medium">
      {isSubmitted && <LoadingSpinner absolute />}
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('TIPS.MODAL.CREATE_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-3 text-white fs-large">
            {getTournamentTitle(tournament.title)}
          </Col>
          <TipCreationContent
            comment={comment}
            setComment={setComment}
            start={start}
            teamA={teamA}
            teamB={teamB}
            predictions={predictions}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-20 d-flex justify-content-end gap-2">
          <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
          <Button
            type="submit"
            className="text-uppercase btn-midnight selected"
            disabled={comment.length < 5 || comment.length > 500}
            onClick={handleSubmit}
          >
            {t('SUBMIT')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TipCreationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    start: PropTypes.string,
    tournament: PropTypes.shape({
      title: PropTypes.string,
    }),
    participants: PropTypes.arrayOf({
      team: PropTypes.object
    })
  }).isRequired,
};

export { TipCreationModal };
