import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import { LoadingSpinner } from '../../LoadingSpinner';
import { tipsActions, userSelectors } from '../../../redux/slices';
import { ROLES } from '../../../data/constants';

const TipsEditModal = ({ onClose, data: { _id: id, message, uid, username, tournamentTitle } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commentRef = useRef();
  const [comment, setComment] = useState(message);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const userData = useSelector(userSelectors.selectUserDetails);

  const isAdmin = (userData?.roles || []).includes(ROLES.TIPS_EDITOR) && uid !== userData?.uid;

  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.focus();
    }
  }, [commentRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (comment.length >= 5 && comment.length <= 500) {
      dispatch(tipsActions.updateTip.base({ id, comment, callback: onClose }));
      setIsSubmitted(true);
    }
  };

  return (
    <Modal show onHide={onClose} dialogClassName="max-width-medium">
      {isSubmitted && <LoadingSpinner absolute />}
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('TIPS.MODAL.UPDATE_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-3 text-white fs-large">
            {tournamentTitle}
          </Col>
          {isAdmin && (
            <Col xs={12} className="mb-3 text-white">
              {t('TIPS.MODAL.CREATED_BY', { username })}
            </Col>
          )}
          <Form.Group as={Col} xs={12}>
            <Form.Control
              ref={commentRef}
              as="textarea"
              placeholder={t('TIPS.MODAL.INPUT_HINT')}
              value={comment}
              onChange={({ target }) => setComment(target.value)}
              rows={10}
              isInvalid={(comment.length < 5 || comment.length > 500) && comment.length}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-20 d-flex justify-content-end gap-2">
          <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
          <Button
            type="submit"
            className="text-uppercase btn-midnight selected"
            disabled={comment.length < 5 || comment.length > 500}
            onClick={handleSubmit}
          >
            {t('SUBMIT')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TipsEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    tournamentTitle: PropTypes.string,
  }).isRequired
};

export { TipsEditModal };
