import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../styles.scss';
import { FILTERS } from '../../../data/constants';
import { useQueryFilter } from '../../../hooks';

const MatchStatus = ({ options, value, onChange, fullFill }) => {
  const { t } = useTranslation();

  const { changeFilter } = useQueryFilter(FILTERS.STATUS, onChange, options.map(o => o.value));

  const handleChange = useCallback(id => () => {
    onChange(id);
    changeFilter(id);
  }, []);

  return (
    <div className="filters">
      {options.map((item) => (
        <Button
          key={`status-${item.value}`}
          className={cx('btn-midnight', { selected: value === item.value })}
          onClick={handleChange(item.value)}
          style={fullFill ? { flexGrow: 1 } : {}}
        >
          {t(`MATCHES.STATUSES.${item.label}`)}
        </Button>
      ))}
    </div>
  );
};

MatchStatus.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  fullFill: PropTypes.bool,
};

MatchStatus.defaultProps = {
  fullFill: false,
};

export { MatchStatus };
