import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Image, Placeholder } from 'react-bootstrap';

import { teamsSelectors } from '../../../redux/slices';

const PlayerDetails = ({ id }) => {
  const player = useSelector(state => teamsSelectors.selectPlayer(state, id));

  if (!player) {
    return (
      <Placeholder
        animation="glow"
        className="d-flex gap-3 align-items-start pt-2"
        style={{ paddingLeft: 58 }}
      >
        <Placeholder style={{ width: 28 }} />
        <Placeholder style={{ width: '100%' }} />
      </Placeholder>
    );
  }

  return (
    <div className="d-flex gap-3 align-items-start pt-2" style={{ paddingLeft: 58 }}>
      <Image
        src={(player?.region?.country?.images || [])[0]?.url}
        alt={player?.region?.country?.abbreviation}
        style={{ height: 20, width: 28 }}
      />
      <div>{`${player?.nick_name} (${player?.first_name} ${player?.last_name})`}</div>
    </div>
  );
};

PlayerDetails.propTypes = {
  id: PropTypes.number.isRequired,
};

export { PlayerDetails };
