import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Image, Placeholder, Card } from 'react-bootstrap';

import LinkIcon from '../../../assets/images/icons/link.svg';
import TournamentIcon from '../../../assets/images/icons/tournament.svg';

import './styles.scss';

import { matchesSelectors, seriesSelectors, teamsSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';

const MatchWidgetSmall = ({ matchId, className, ...props }) => {
  const { t } = useTranslation();
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const serie = useSelector(state => seriesSelectors.selectSerieById(state, matchId));
  const teamsByRosters = useSelector(teamsSelectors.selectTeamsRosters);
  const { isStarted, isEnded } = useSelector(state => seriesSelectors.selectSerieStatus(state, matchId));

  const seriaObj = useMemo(() => ({
    ...(serie || {}),
    participants: (serie?.participants || []).map(part => ({
      ...part,
      team: part.team || teamsByRosters[part.roster.id]
    }))
  }), [serie, teamsByRosters]);

  const [teamA, teamB] = seriaObj?.participants || [];
  const defaultImage = gamesImagesMap[seriaObj?.game?.id];

  return (
    <Card bg="dark" className={cx('large-card', 'match-widget', className)} {...props}>
      <Placeholder animation="glow">
        <Card.Header className="d-flex justify-content-between">
          {isStarted && !isEnded && (
            <span className="status green">{t('MATCHES.STATUS_LIVE')}</span>
          )}
          {isStarted && isEnded && (
            <span className="status red">{t('MATCHES.STATUS_ENDED')}</span>
          )}
          {!isStarted && !isEnded && (
            <span className="status">{t('MATCHES.STATUS_NOT_STARTED')}</span>
          )}
          <Link
            to={`/matches/${seriaObj?.id}?mode=new#tips`}
            className="d-flex gap-1 align-items-center text-secondary text-decoration-none text-capitalize"
          >
            {t('MATCH_DETAILS.TIP_NOW')}
            <Image src={LinkIcon} alt="" />
          </Link>
        </Card.Header>
        <Card.Body>
          <Link
            to={`/teams/${teamA?.team?.id}`}
            className="text-white text-decoration-none fs-normal justify-content-start mb-3"
          >
            {teamA?.team?.abbreviation || teamB?.team?.name}
          </Link>
          <div className="d-flex justify-content-between">
            <div className="score">{teamA?.score === null ? '-' : (teamA?.score || 0)}</div>
            <Image
              className="img-medium"
              src={(teamA?.team?.images || [])[0]?.url || defaultImage}
            />
          </div>
          <div className="divider mt-3 mb-3" />
          <div className="d-flex justify-content-between">
            <div className="score">{teamB?.score === null ? '-' : (teamB?.score || 0)}</div>
            <Image
              className="img-medium"
              src={(teamB?.team?.images || [])[0]?.url || defaultImage}
            />
          </div>
          <Link
            to={`/teams/${teamB?.team?.id}`}
            className="text-white text-decoration-none fs-normal justify-content-start mt-3"
          >
            {teamB?.team?.abbreviation || teamB?.team?.name}
          </Link>
          <div className="mt-20 fs-small text-secondary d-flex align-items-center gap-2">
            <Image src={TournamentIcon} alt="" />
            {getTournamentTitle(seriaObj?.tournament?.title)}
          </div>
        </Card.Body>
      </Placeholder>
    </Card>
  );
};

MatchWidgetSmall.propTypes = {
  matchId: PropTypes.string.isRequired,
  className: PropTypes.string
};

MatchWidgetSmall.defaultProps = {
  className: undefined
};

export { MatchWidgetSmall };
