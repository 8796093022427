import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiChevronLeft } from 'react-icons/hi';

import { IconButton } from '../components/common';
import { PredictionSlip } from '../components/Match';
import { MissionListWidget } from '../components/Rewards/Missions';
import { LatestTips, TipsterRank } from '../components/Tips';
import { TeamMatches, TeamInfo } from '../components/Team';

import { useGames, useScrollTop } from '../hooks';

const TeamDetailPage = () => {
  const { teamId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useGames();
  useScrollTop();

  const handleGoBack = useCallback(() => navigate(-1), []);

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <Row className="mt-20">
          <Col xs={12} className="text-white d-flex align-items-center gap-3">
            <IconButton
              icon={<HiChevronLeft />}
              onClick={handleGoBack}
            />
            <h3 className="text-nowrap mb-0">{t('TEAMS.TITLE_INFO')}</h3>
          </Col>
          <Col xs={12} md={6}>
            <TeamInfo teamId={teamId} />
          </Col>
          <Col xs={12} md={6}>
            <TeamMatches teamId={teamId} />
          </Col>
        </Row>
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { TeamDetailPage };
