import React, { useCallback, useRef, useState } from 'react';
import { Card, Col, Image, Row, Form, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { userActions, userSelectors } from '../../redux/slices';
import { REGEXPS } from '../../data/constants';
import { LoadingSpinner } from '../LoadingSpinner';
import { routes, pages } from '../../data/menu';

const ProfileEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showChangePassword, setShowChangePasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const avatarRef = useRef();

  const currentUser = useSelector(userSelectors.selectUserDetails);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      email: currentUser.email,
      username: currentUser.username,
      avatar: currentUser.profile.avatar,
    },
    onSubmit: values => {
      setIsUpdating(true);
      dispatch(userActions.updateProfile.base({
        id: currentUser._id,
        data: values,
        callback: () => {
          setIsEditable(false);
          setIsUpdating(false);
          navigate(routes[pages.profileEdit].replace(':username', values.username));
        }
      }));
    },
    validate: value => {
      const error = {};
      if (!REGEXPS.EMAIL.test(value.email)) {
        error.email = true;
      }
      if (!REGEXPS.USERNAME.test(value.username)) {
        error.username = true;
      }
      return error;
    },
  });

  const handleDeleteProfile = useCallback(() => {
    setIsUpdating(true);
    setShowDeleteModal(false);
    dispatch(userActions.deleteProfile.base({
      id: currentUser._id,
      resetToken: true,
      callback: () => {
        setIsUpdating(false);
      }
    }));
  }, [currentUser]);

  const handleResetPassword = useCallback(() => {
    setIsUpdating(true);
    setShowChangePasswordModal(false);
    dispatch(userActions.resetPassword.base({
      id: currentUser._id,
      successMsg: t('PROFILE.CHANGE_PASSWORD_SUCCESS'),
      callback: () => {
        setIsUpdating(false);
      }
    }));
  }, [currentUser]);

  return (
    <Card bg="dark" className="large-card">
      <Card.Body className="d-flex gap-4 position-relative">
        {isUpdating && <LoadingSpinner absolute />}
        <div
          className={isEditable ? 'img-uploader' : undefined}
          onClick={isEditable ? () => avatarRef.current.click() : undefined}
        >
          <Image
            src={typeof values.avatar === 'string' ? values.avatar : URL.createObjectURL(values.avatar)}
            style={{ width: 150, height: 150 }}
          />
          {isEditable && (
            <Form.Control
              ref={avatarRef}
              type="file"
              className="d-none"
              accept="image/*"
              isInvalid={touched.profile?.avatar && errors.profile?.avatar}
              onChange={({ target }) => setFieldValue('avatar', target.files[0])}
            />
          )}
        </div>
        <Row className="w-100">
          <Form.Group as={Col} xs={12} md={6}>
            <Form.Label>{t('PROFILE.EMAIL')}</Form.Label>
            <Form.Control
              value={values.email}
              readOnly={!isEditable}
              isInvalid={touched.email && errors.email}
              onChange={({ target }) => setFieldValue('email', target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6}>
            <Form.Label>{t('PROFILE.USERNAME')}</Form.Label>
            <Form.Control
              value={values.username}
              readOnly={!isEditable}
              isInvalid={touched.username && errors.username}
              onChange={({ target }) => setFieldValue('username', target.value)}
            />
          </Form.Group>
          <Col xs={12} className="d-flex align-items-center justify-content-between">
            {isEditable ? (
              <>
                <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
                  {t('PROFILE.DELETE_ACCOUNT')}
                </Button>
                <div>
                  <Button
                    className="btn-midnight me-2"
                    onClick={() => setIsEditable(false)}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button className="btn-midnight selected" onClick={handleSubmit}>
                    {t('SUBMIT')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Button className="btn-midnight" onClick={() => setShowChangePasswordModal(true)}>
                  {t('PROFILE.CHANGE_PASSWORD')}
                </Button>
                <Button className="btn-midnight" onClick={() => setIsEditable(true)}>
                  {t('EDIT')}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{t('PROFILE.DELETE_ACCOUNT')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-white">
            {t('PROFILE.DELETE_ACCOUNT_HINT')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteProfile}>{t('PROFILE.DELETE_ACCOUNT')}</Button>
          <Button className="btn-midnight" onClick={() => setShowDeleteModal(false)}>{t('CANCEL')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showChangePassword} onHide={() => setShowChangePasswordModal(false)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{t('PROFILE.CHANGE_PASSWORD')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-white">
            {t('PROFILE.CHANGE_PASSWORD_HINT')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-midnight selected" onClick={handleResetPassword}>
            {t('PROFILE.CHANGE_PASSWORD')}
          </Button>
          <Button className="btn-midnight" onClick={() => setShowChangePasswordModal(false)}>
            {t('CANCEL')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export { ProfileEdit };
