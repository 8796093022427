import React from 'react';
import { BsFacebook, BsInstagram, BsTwitter, BsDiscord } from 'react-icons/bs';

export const social = [
  { key: 'FACEBOOK', icon: <BsFacebook />, link: 'https://www.facebook.com/Gamestack.gg' },
  { key: 'TWITTER', icon: <BsTwitter />, link: 'https://twitter.com/gamestackgg' },
  { key: 'DISCORD', icon: <BsDiscord />, link: 'https://discord.gg/7FtyXrxJ' },
  { key: 'INSTAGRAM', icon: <BsInstagram />, link: 'https://www.instagram.com/gamestack.gg' },
];

export const links = {
  en: [
    { key: 'PRIVACY', link: 'https://docs.google.com/document/d/106PZbA7JfZKVKZFugBNYW6DaFaiDn9HHw8kxe-BnOPc' },
    { key: 'TERMS', link: 'https://docs.google.com/document/d/13QmO5XIwnb6iTM3xVFKJHHs9E12yHPucIRqP-QUueHo' },
    { key: 'GUIDE', link: 'https://docs.google.com/document/d/134zM0PtE_MP6-nW_DMNjkFOWqTIm5f2N' },
  ],
  kr: [
    { key: 'PRIVACY', link: 'https://docs.google.com/document/d/1UAf2Grd0ue0MnpMjBuGCDkg1gFkyaTZT4x8H8zE4zS8' },
    { key: 'TERMS', link: 'https://docs.google.com/document/d/143kpprYDdaOcyUAHCTgTXyUnFwISQxGMtDmN7WH2NaM' },
    { key: 'GUIDE', link: 'https://docs.google.com/document/d/1I4CvllgjnkeYZjQKpkRwG-enmDKcnxCQ' },
  ]
};
