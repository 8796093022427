import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'react-bootstrap';
import { MdEdit, MdDelete, MdMoreVert } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { matchesSelectors } from '../../../redux/slices';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const MissionItem = ({ data, lang, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const games = useSelector(matchesSelectors.selectGames);

  return (
    <tr className={cx({ 'text-decoration-line-through': Boolean(data.deletedAt) })}>
      <td>{data.order}</td>
      <td>{t(`MISSIONS.TYPE_${data.type.toUpperCase()}`)}</td>
      <td>{data.title[lang] || '-'}</td>
      <td>{data.description[lang] || '-'}</td>
      <td>{data.price}</td>
      <td>
        {data.conditions.map(cond => (
          <div className="d-flex flex-column mb-2">
            <div>{t(`MISSIONS.CONDITIONS_${cond.type.toUpperCase().replace(/-/g, '_')}`)}</div>
            <div className="fs-small">
              {(cond.missionRef?.title || {})[lang] || `${t('MISSIONS.REPEAT')}: ${cond.amount}`}
              {data.conditions.length > 1 ? ` (${cond.progress}%)` : null}
            </div>
            {cond.game && (
              <div className="fs-small">
                {`${t('MISSIONS.GAME')}: ${games.find(g => g.id === cond.game)?.abbreviation}`}
              </div>
            )}
          </div>
        ))}
      </td>
      <td>
        {`${Boolean(data.visible)}`}
      </td>
      <td>
        {data.deletedAt && t('MISSIONS.STATUS_DELETED')}
        {!data.deletedAt && +new Date(data.endAt) >= +new Date() && t('MISSIONS.STATUS_ENDED')}
        {!data.deletedAt && (!data.startAt || +new Date(data.endAt) < +new Date()) && t('MISSIONS.STATUS_ACTIVE')}
      </td>
      <td>
        {!data.deletedAt && (
          <Dropdown align="start" className="pt-1">
            <Dropdown.Toggle
              as={CustomToggle}
              className="d-flex align-items-center justify-content-center cursor-pointer ps-1 pe-1 h-100"
            >
              <MdMoreVert className="text-secondary" />
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item
                className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
                onClick={onEdit}
              >
                <MdEdit />
                {t('EDIT')}
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
                onClick={onDelete}
              >
                <MdDelete />
                {t('DELETE')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

MissionItem.propTypes = {
  lang: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export { MissionItem };
