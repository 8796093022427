import { all, put, call, select, takeLatest, delay, takeEvery } from 'redux-saga/effects';

import * as api from '../../api';
import { teamsActions, tournamentsActions, tournamentsSelectors } from '../slices';

let loadTournamentsFlowBusy = false;

export function* loadTournamentsFlow({ payload: { ids } }) {
  try {
    if (loadTournamentsFlowBusy) {
      yield delay(1000);
      yield put(tournamentsActions.loadTournaments.base({ ids }));
    } else {
      loadTournamentsFlowBusy = true;
      const existedIds = yield select(tournamentsSelectors.selectIds);
      const newIds = ids.filter(id => !existedIds.includes(id));
      if (newIds.length) {
        const data = yield call(api.getTournamentsByIds, newIds);
        yield put(tournamentsActions.loadTournaments.success({ ids, data }));
      }
      loadTournamentsFlowBusy = false;
    }
  } catch (error) {
    yield put(tournamentsActions.loadTournaments.failed({ error, ids }));
    loadTournamentsFlowBusy = false;
  }
}

export function* loadTournamentsByStatusFlow({ payload: { key } }) {
  try {
    const hasData = yield select(state => tournamentsSelectors.selectTournamentsByStatus(state, key));
    if (!hasData || !hasData?.length) {
      const data = yield call(api.getTournamentsByStatus, key);
      yield put(tournamentsActions.loadTournamentsByStatus.success({ key, data }));
    }
  } catch (error) {
    yield put(tournamentsActions.loadTournamentsByStatus.failed({ error }));
  }
}

export function* loadDetailsFlow({ payload: { id } }) {
  try {
    const data = yield call(api.getTournamentDetails, id);
    yield put(tournamentsActions.loadDetails.success(data));
  } catch (error) {
    yield put(tournamentsActions.loadDetails.failed({ error }));
  }
}

export function* loadStagesFlow({ payload: { id } }) {
  try {
    const data = yield call(api.getTournamentStages, id);
    const { result, rosters } = data.reduce((map, next) => ({
      ...map,
      rosters: map.rosters.concat(next.substages.reduce((map2, next2) => next2.rosters.map(r => r.id), [])),
      result: next.substages.reduce((map2, next2) => ({
        ...map.result,
        [next2.phase]: (map.result[next2.phase] || []).concat(map2[next2.phase] || []).concat([{
          stageTitle: next.title,
          ...next2
        }])
      }), {}),
    }), { rosters: [], result: {} });
    yield put(teamsActions.loadTeamsByRosters.base({ ids: rosters }));
    yield put(tournamentsActions.loadStages.success(result));
  } catch (error) {
    yield put(tournamentsActions.loadStages.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeEvery(tournamentsActions.loadTournaments.types.BASE, loadTournamentsFlow),
    takeLatest(tournamentsActions.loadTournamentsByStatus.types.BASE, loadTournamentsByStatusFlow),
    takeLatest(tournamentsActions.loadDetails.types.BASE, loadDetailsFlow),
    takeLatest(tournamentsActions.loadStages.types.BASE, loadStagesFlow),
  ]);
}
