import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo-gold-white.png';
import { matchesSelectors } from '../../redux/slices';
import { social, links } from '../../data/footer';

const Footer = () => {
  const { t, i18n: { language } } = useTranslation();

  const games = useSelector(matchesSelectors.selectGames);

  return (
    <footer className="bg-dark p-4 pt-5 pb-5">
      <Row>
        <Col
          className="d-flex gap-3 align-items-center mb-20 flex-wrap"
          xs={12}
          md={6}
          xl={5}
          xxl={4}
        >
          <Image src={Logo} style={{ height: 17 }} />
          <div className="text-secondary fs-small">
            {t('FOOTER.TEXT')}
          </div>
        </Col>
        <Col
          className="mb-20"
          xs={12}
          md={{ span: 4, offset: 2 }}
          xl={{ span: 3, offset: 4 }}
          xxl={{ span: 3, offset: 5 }}
        >
          <Row>
            <Col xs={6} className="d-flex flex-column gap-1">
              {games?.map(game => (
                <Link
                  key={`game-${game.id}`}
                  className="text-white fs-small"
                  to={`/matches?games=${game.id}&force=true`}
                >
                  <Image
                    className="me-2"
                    src={(game?.images?.find(i => i.type === 'square') || (game?.images || [])[0])?.url}
                    style={{ width: 10 }}
                  />
                  {game.abbreviation || game.title}
                </Link>
              ))}
            </Col>
            <Col xs={6} className="d-flex gap-3 justify-content-end align-items-center">
              {social.map(item => (
                <a className="text-white" key={item.key} href={item.link} target="_blank" rel="noreferrer">
                  {item.icon}
                </a>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="text-secondary fs-small mt-2">
          {t('COPYRIGHT', { year: new Date().getFullYear() })}
        </div>
        <div className="d-flex gap-3 mt-2">
          {links[language]?.map(item => (
            <a
              className="text-white fs-small"
              key={item.key}
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              {t(`FOOTER.LINKS.${item.key}`)}
            </a>
          ))}
          <div className="text-white fs-small">
            {`${t('FOOTER.BUSINESS')}: `}
            <a
              className="text-white fs-small"
              href={`mailto:${t('FOOTER.BUSINESS_EMAIL')}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('FOOTER.BUSINESS_EMAIL')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
