import React from 'react';
import PropTypes from 'prop-types';

const MatchPregameStatsWidget = ({ matchId, ...props }) => (
  <iframe
    id="abios-live-timeline"
    name="abios-live-timeline"
    src={`https://widget.abiosgaming.com/estack-gg/pregame-stats/${matchId}`}
    width="100%"
    {...props}
  />
);

MatchPregameStatsWidget.propTypes = {
  matchId: PropTypes.string.isRequired
};

export { MatchPregameStatsWidget };
