import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { Image, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { MARKETPLACE_RARITY } from '../../../data/constants';
import { userSelectors } from '../../../redux/slices';

import './styles.scss';

import CaseCommon from '../../../assets/images/marketplace/cases/case-common.png';
import CaseUncommon from '../../../assets/images/marketplace/cases/case-uncommon.png';
import CaseRare from '../../../assets/images/marketplace/cases/case-rare.png';
import CaseEpic from '../../../assets/images/marketplace/cases/case-epic.png';
import CaseLegendary from '../../../assets/images/marketplace/cases/case-legendary.png';
import Diamond from '../../../assets/images/diamonds/diamond.svg';
import WinFail from '../../../assets/images/marketplace/winners/fail.svg';

import { LoadingSpinner } from '../../LoadingSpinner';
import { getDocumentPath } from '../../../api';

const CaseImages = {
  [MARKETPLACE_RARITY.COMMON]: CaseCommon,
  [MARKETPLACE_RARITY.UNCOMMON]: CaseUncommon,
  [MARKETPLACE_RARITY.RARE]: CaseRare,
  [MARKETPLACE_RARITY.EPIC]: CaseEpic,
  [MARKETPLACE_RARITY.LEGENDARY]: CaseLegendary,
};

const MarketplaceModal = ({ winners, isLoading, onClose, onPlayAgain, data: {
  slug, rarity, price, chanceVisible, items
} }) => {
  const { t, i18n: { language } } = useTranslation();
  const userDetails = useSelector(userSelectors.selectUserDetails);

  const [page, setPage] = useState(0);

  const handleChangePage = useCallback((index) => () => {
    setPage(index);
  }, []);

  const handlePlayAgain = useCallback(() => {
    setPage(0);
    onPlayAgain();
  }, [page]);

  const isAvailable = userDetails?.wallet?.diamonds > price;

  return (
    <Modal
      show
      onHide={onClose}
      centered
      dialogClassName="max-width-large"
      backdrop="static"
      keyboard={false}
    >
      <div className={cx('marketplace-item', rarity)} style={{ height: 700 }}>
        {!isLoading && !!winners?.length && (
          <div className={cx('modal-winner', rarity)} />
        )}
        {rarity === MARKETPLACE_RARITY.RARE && (
          <div className={cx('rarity-borders', rarity)}>
            <div className="left-right" />
          </div>
        )}
        {[MARKETPLACE_RARITY.EPIC, MARKETPLACE_RARITY.LEGENDARY].includes(rarity) && (
          <>
            <div className={cx('rarity-borders', rarity)}>
              <div className="left-right" />
            </div>
            <div className={cx('rarity-borders', rarity)}>
              <div className="top-bottom" />
            </div>
          </>
        )}
        <div className="modal-header">
          <HiChevronLeft
            className={cx('btn-icon', { 'opacity-100': page > 0 && winners?.length })}
            onClick={page > 0 && winners?.length ? handleChangePage(page - 1) : undefined}
          />
          <h2>{slug[language]}</h2>
          <HiChevronRight
            className={cx('btn-icon', { 'opacity-100': page < winners?.length && winners?.length })}
            onClick={page < winners?.length && winners?.length ? handleChangePage(page + 1) : undefined}
          />
        </div>
        {isLoading && (
          <LoadingSpinner style={{ height: '75%' }} />
        )}
        {!isLoading && !!winners?.length && (
          <div className="stepper">
            {winners.map((w, ind) => (
              <div className={cx({ selected: ind === page })} key={w.slug[language]} onClick={handleChangePage(ind)} />
            ))}
            <div className={cx({ selected: winners.length === page })} onClick={handleChangePage(winners.length)} />
          </div>
        )}
        {!isLoading && !winners?.length && (
          <div className="d-flex flex-column align-items-center">
            <Image src={WinFail} alt="" style={{ width: 400 }} />
            <div className="mb-20 text-center">
              <h5>{t('REWARDS.MARKETPLACE_FAIL')}</h5>
              <div className="text-secondary">{t('REWARDS.MARKETPLACE_FAIL_HINT')}</div>
            </div>
            <Button
              className="btn-midnight selected text-uppercase mb-20"
              disabled={!isAvailable}
              onClick={handlePlayAgain}
            >
              <span className="me-4">{t('REWARDS.OPEN_ANOTHER')}</span>
              <Image src={Diamond} alt="" className="me-2" />
              {price}
            </Button>
            <Button className="btn-midnight text-uppercase" onClick={onClose}>
              {t('CANCEL')}
            </Button>
          </div>
        )}
        {!isLoading && !!winners?.length && winners[page] && (
          <div className="d-flex flex-column align-items-center position-relative">
            <div className={cx('circle-mask', rarity)} />
            <div className={cx('winner-card', rarity)}>
              <div className="top"><span>{t(`MARKETPLACE.RARITY_${rarity.toUpperCase()}`)}</span></div>
              <div className="img" style={{ backgroundImage: `url("${getDocumentPath(winners[page].image)}")` }} />
              <div className="bottom"><span>{winners[page].slug[language]}</span></div>
            </div>
            {winners[page].meta?.code && (
              <p className="mb-2 text-white">
                {`${t('MARKETPLACE.GIFT_CODE')}: ${winners[page].meta?.code}`}
              </p>
            )}
            {page < winners?.length && (
              <Button
                className="btn-midnight selected text-uppercase mb-20"
                onClick={page < winners?.length && winners?.length ? handleChangePage(page + 1) : undefined}
              >
                {t('NEXT')}
              </Button>
            )}
          </div>
        )}
        {!isLoading && !!winners?.length && !winners[page] && (
          <div className="d-flex flex-column align-items-center">
            <div className={cx('prise-image', 'circle', rarity)}>
              <Image src={CaseImages[rarity]} alt={rarity} style={{ width: 330 }} />
            </div>
            <Button
              className="btn-midnight selected text-uppercase mb-20 mt-20"
              disabled={!isAvailable}
              onClick={handlePlayAgain}
            >
              <span className="me-4">{t('REWARDS.OPEN_ANOTHER')}</span>
              <Image src={Diamond} alt="" className="me-2" />
              {price}
            </Button>
            <Button className="btn-midnight text-uppercase" onClick={onClose}>
              {t('CLOSE')}
            </Button>
          </div>
        )}
        <Modal.Footer>
          {items.map((item) => (
            <div className="item" key={item.slug[language]}>
              {chanceVisible && (
                <div className="chance">
                  {`${item.chance}%`}
                </div>
              )}
              <div className="text-uppercase" style={{ color: item.color }}>
                {item.slug[language]}
              </div>
            </div>
          ))}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

MarketplaceModal.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.object,
    rarity: PropTypes.oneOf(Object.values(MARKETPLACE_RARITY)),
    price: PropTypes.number,
    chanceVisible: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.object,
      color: PropTypes.object,
      image: PropTypes.string,
      chance: PropTypes.number,
    }))
  }).isRequired,
  winners: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.object,
    image: PropTypes.string,
    color: PropTypes.string,
    chance: PropTypes.number,
    meta: PropTypes.shape({
      code: PropTypes.any
    })
  })),
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPlayAgain: PropTypes.func.isRequired,
};

MarketplaceModal.defaultProps = {
  winners: [],
};

export { MarketplaceModal };
