import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import BetwayLogo from '../../../assets/images/partners/betway.svg';

import './styles.scss';

const MatchOdd = ({ odds }) => (
  <Link to="/" className="match-odds">
    <div className="odds">
      <span>@</span>
      <span className="value">{odds}</span>
    </div>
    <div className="partner">
      <Image src={BetwayLogo} alt="" />
    </div>
  </Link>
);

MatchOdd.propTypes = {
  odds: PropTypes.string.isRequired
};

export { MatchOdd };
