import { useEffect } from 'react';

function _abiosHandleIframeHeight(e) {
  // check origin
  if (e.origin === 'https://widget.abiosgaming.com') {
    const _abiosIFrame = document.getElementById(e.data.name);
    _abiosIFrame.height = `${e.data.height}px`;
  }
}

const useAbiosWidgetHeight = () => {
  useEffect(() => {
    window.addEventListener('message', _abiosHandleIframeHeight, false);
    return window.removeEventListener('message', _abiosHandleIframeHeight, false);
  }, []);
};

export { useAbiosWidgetHeight };
