import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Image } from 'react-bootstrap';
import moment from 'moment';

import { matchesSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';

import { TOURNAMENTS } from '../../../data/constants';
import { pages, routes } from '../../../data/menu';

const TournamentRow = ({ type, data: {
  id, title, game, start, end, images, stages, location, string_prize_pool
} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);

  const handleOpenDetails = useCallback(() => {
    navigate(routes[pages.tournamentDetails].replace(':tournamentId', id));
  }, [id]);

  return (
    <Row className="main-row cursor-pointer" onClick={handleOpenDetails}>
      <Col
        xs={{ order: 1, span: 6 }}
        xl={{ order: 1, span: 3 }}
        className="d-flex align-items-center gap-5 fs-small split-right pb-3 pb-xl-0"
      >
        <Image className="img-small" src={gamesImagesMap[game.id]} alt="" />
        <div className="text-secondary d-flex flex-column gap-1">
          <span className="text-nowrap">
            {`${moment(start).format('MM/DD')} - ${end ? moment(end).format('MM/DD') : 'TBD'}`}
          </span>
          <span
            className={cx('status', {
              red: type === TOURNAMENTS.PAST,
              green: [TOURNAMENTS.ONGOING, TOURNAMENTS.UPCOMING].includes(type)
            })}
          >
            {t(`TOURNAMENTS.${type.toUpperCase()}`)}
          </span>
        </div>
      </Col>

      <Col
        xs={{ order: 3, span: 12 }}
        xl={{ order: 2, span: 6 }}
        className="d-flex align-items-center gap-3 pt-3 pt-xl-0"
      >
        <div
          style={{
            height: 48,
            minWidth: 48,
            width: 48,
            backgroundImage: `url("${(images.find(i => i.type === 'square') || images[0])?.url}")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%'
          }}
        />
        <div>{getTournamentTitle(title)}</div>
      </Col>

      <Col
        xs={{ order: 2, span: 4 }}
        xl={{ order: 3, span: 2 }}
        className={cx(
          'd-flex',
          'flex-column',
          'flex-xl-row',
          'gap-2',
          'fs-small',
          'text-secondary',
          'split-right',
          'pb-3',
          'pb-xl-0',
          'align-items-xl-center'
        )}
      >
        <Image
          src={(location?.host?.country?.images || [])[0]?.url}
          alt={location?.host?.country?.abbreviation}
          style={{ height: 14, width: 20 }}
          className="d-none d-xl-flex"
        />
        <div className="d-xl-none">{string_prize_pool?.total || 'TBD'}</div>
        <div className="d-flex align-items-center gap-1 flex-xl-column align-items-xl-start">
          <Image
            src={(location?.host?.country?.images || [])[0]?.url}
            alt={location?.host?.country?.abbreviation}
            style={{ height: 14, width: 20 }}
            className="d-xl-none"
          />
          <div className="d-none d-xl-flex">{string_prize_pool?.total || 'TBD'}</div>
          <span>{location?.host?.country?.name || location?.host?.name}</span>
        </div>
      </Col>

      <Col
        xs={{ order: 2, span: 2 }}
        xl={{ order: 3, span: 1 }}
        className="d-flex align-items-center justify-content-center fs-small split-left pb-3 pb-xl-0"
      >
        <span className="text-secondary text-nowrap">
          {t('TOURNAMENTS.STAGES', { count: stages.length })}
        </span>
      </Col>
    </Row>
  );
};

TournamentRow.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    game: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    images: PropTypes.array.isRequired,
    stages: PropTypes.array.isRequired,
    location: PropTypes.shape({
      host: PropTypes.shape({
        name: PropTypes.string.isRequired,
        country: PropTypes.shape({
          abbreviation: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          images: PropTypes.array.isRequired
        })
      }).isRequired
    }).isRequired,
    string_prize_pool: PropTypes.shape({
      total: PropTypes.string
    }).isRequired
  }).isRequired
};

export { TournamentRow };
