import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Image } from 'react-bootstrap';

import Diamond from '../../../assets/images/diamonds/diamond-grey.svg';
import { pages, routes } from '../../../data/menu';

const TipsterRankItem = ({ ind, noMargin, data: { username, tips, diamonds } }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenProfile = useCallback(() => {
    navigate(routes[pages.profile].replace(':username', username));
  }, [username]);

  return (
    <Card.Body
      className={cx('d-flex', 'p-0', 'cursor-pointer', { 'mb-2': !noMargin })}
      onClick={handleOpenProfile}
    >
      <div className={cx('rank', 'lg', { 'rank-1': ind === 0, 'rank-2': ind === 1, 'rank-3': ind === 2 })}>
        {`#${ind + 1}`}
      </div>
      <div
        className={cx('d-flex', 'flex-grow-1', 'justify-content-between', 'p-3', 'gap-2', {
          'rank-content': ind <= 2, 'rank-1': ind === 0, 'rank-2': ind === 1, 'rank-3': ind === 2
        })}
        style={{ width: 'calc(100% - 64px)' }}
      >
        <div className="">
          <div className="fs-small">
            {username}
          </div>
          <div className="fs-smallest text-secondary">
            {t('TIPS.TIPS_COUNT', { count: tips })}
          </div>
        </div>
        <div className="fs-small text-secondary d-flex align-items-center gap-2" style={{ minWidth: 70 }}>
          <Image src={Diamond} alt="" style={{ height: 16 }} />
          <div>{diamonds}</div>
        </div>
      </div>
    </Card.Body>
  );
};

TipsterRankItem.propTypes = {
  ind: PropTypes.number.isRequired,
  noMargin: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    tips: PropTypes.number,
    diamonds: PropTypes.number,
  }).isRequired
};

export { TipsterRankItem };
