import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/games`;

export const getSeriesByIds = ids => (
  request.post(`${api}/get-series`, { ids }).then(res => res.data?.success?.data)
);

export const getSeriesByDate = (date) => (
  request.get(`${api}/series-by-date`, { params: { date } }).then(res => res.data?.success?.data)
);

export const getSerieDetails = id => (
  request.get(`${api}/series/${id}`).then(res => res.data?.success?.data)
);

export const getGames = () => (
  request.get(`${api}/games`).then(res => res.data?.success?.data)
);

export const getTournamentsByIds = ids => (
  request.post(`${api}/get-tournaments`, { ids }).then(res => res.data?.success?.data)
);

export const getTournamentsByStatus = key => (
  request.get(`${api}/tournaments/${key}`).then(res => res.data?.success?.data)
);

export const getTournamentDetails = id => (
  request.get(`${api}/tournaments/${id}/details`).then(res => res.data?.success?.data)
);

export const getTournamentParticipants = id => (
  request.get(`${api}/tournaments/${id}/participants`).then(res => res.data?.success?.data)
);

export const getTournamentStages = id => (
  request.get(`${api}/tournaments/${id}/stages`).then(res => res.data?.success?.data)
);

export const getTournamentMatches = (id, status, page) => (
  request.get(`${api}/tournaments/${id}/matches/${status}`, { params: { page } })
    .then(res => res.data?.success?.data)
    .catch(() => [])
);

export const getSerieStreams = id => (
  request.get(`${api}/series/${id}/streams`)
    .then(res => res.data?.success?.data)
    .catch(() => null)
);

export const getCountries = () => (
  request.get(`${api}/countries`).then(res => res.data?.success?.data)
);

export const getTeams = (game, country, page) => (
  request.get(`${api}/teams`, { params: { game, country, page } }).then(res => res.data?.success?.data)
);

export const getTeamById = id => (
  request.get(`${api}/teams/${id}`).then(res => res.data?.success?.data)
);

export const getTeamMatches = (id, status, page) => (
  request.get(`${api}/teams/${id}/matches/${status}`, { params: { page } }).then(res => res.data?.success?.data)
);

export const getRosters = ids => (
  request.post(`${api}/get-roster`, ids).then(res => res.data?.success?.data)
);

export const getPlayers = ids => (
  request.post(`${api}/get-players`, ids).then(res => res.data?.success?.data)
);

export const getSerieMatches = id => (
  request.get(`${api}/series/${id}/matches`).then(res => res.data?.success?.data)
);

export const getTeamsByRosters = rosters => (
  request.post(`${api}/teams/by-rosters`, { rosters })
    .then(res => res.data?.success?.data)
);

export const getMaps = ids => (
  request.post(`${api}/get-maps`, { ids }).then(res => res.data?.success?.data)
);
