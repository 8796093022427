import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';

import { marketplaceActions, marketplaceSelectors } from '../../../redux/slices';
import { LocaleFilter } from '../../filters';
import { MarketplaceItem } from './MarketplaceItem';
import { ModifyModal } from './ModifyModal';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Checkbox } from '../../common';

const Marketplace = () => {
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(null);
  const [lang, setLang] = useState(language);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const items = useSelector(marketplaceSelectors.selectData);
  const isLoading = useSelector(marketplaceSelectors.selectIsLoading);
  const isUpdating = useSelector(marketplaceSelectors.selectIsUpdating);

  useEffect(() => {
    dispatch(marketplaceActions.loadData.base({ includeDeleted: includeDeleted || undefined }));
  }, [includeDeleted]);

  useEffect(() => {
    if (!isUpdating) {
      setModal(null);
    }
  }, [isUpdating]);

  const handleOpenCreate = useCallback(() => setModal({}), []);
  const handleOpenEdit = useCallback(data => () => setModal(data), []);
  const handleClose = useCallback(() => setModal(null), []);

  const handleCreateUpdate = useCallback(data => {
    if (data._id) {
      dispatch(marketplaceActions.update.base({ id: data._id, data }));
    } else {
      dispatch(marketplaceActions.create.base(data));
    }
  }, []);

  const handleDelete = useCallback(data => () => dispatch(marketplaceActions.delete.base({ id: data._id })), []);

  return (
    <div className="show-grid mb-20">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-2 text-nowrap">{t('MARKETPLACE.ADMIN_PAGE_TITLE')}</h3>
        <div className="d-flex gap-3">
          <Checkbox
            value={includeDeleted}
            onChange={() => setIncludeDeleted(!includeDeleted)}
            label={t('MARKETPLACE.INCLUDE_DELETED')}
            style={{
              padding: '14px 18px',
              fontSize: '14px'
            }}
          />
          <Button
            className={cx('btn-midnight', { selected: modal?.mode === 'create' })}
            onClick={handleOpenCreate}
          >
            {t('CREATE')}
          </Button>
        </div>
      </div>
      <LocaleFilter className="mt-20" selection={lang} setSelection={setLang} />
      <Table variant="dark" className="mt-20" hover>
        <thead>
          <tr>
            <th>{t('MARKETPLACE.ORDER')}</th>
            <th>{t('MARKETPLACE.SLUG')}</th>
            <th>{t('MARKETPLACE.RARITY')}</th>
            <th>{t('MARKETPLACE.PRICE')}</th>
            <th>{t('MARKETPLACE.QUANTITY')}</th>
            <th>{t('MARKETPLACE.CHANCE_VISIBLE')}</th>
            <th>{t('MARKETPLACE.COUNTRY_SUPPORT')}</th>
            <th>{t('MARKETPLACE.ITEMS')}</th>
            <th>{t('MARKETPLACE.STATUS')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isLoading && !items.length && (
            <tr><td colSpan={9} className="text-center">{t('MARKETPLACE.NO_RESULTS')}</td></tr>
          )}
          {items.map(item => (
            <MarketplaceItem
              key={item._id}
              data={item}
              lang={lang}
              onEdit={handleOpenEdit(item)}
              onDelete={handleDelete(item)}
            />
          ))}
        </tbody>
      </Table>
      {isLoading && (
        <LoadingSpinner />
      )}
      {modal && (
        <ModifyModal
          nextOrder={items.length + 1}
          data={modal}
          onSubmit={handleCreateUpdate}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export { Marketplace };
