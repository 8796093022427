import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { ALL_FILTER, FILTERS } from '../data/constants';
import { useDeepEffect } from './useDeepEffect';

const useQueryFilter = (key, setInitialValue, availableValues) => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();
  const [isInitialize, setIsInitialize] = useState(false);

  useDeepEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (!isInitialize || searchParams.get('force')) {
      let searchValue = searchParams.get(key) || '';
      switch (key) {
        case FILTERS.GAMES:
        case FILTERS.TOURNAMENTS:
          searchValue = searchValue.split(',').filter(i => !!i).map(i => parseInt(i, 10)).sort();
          if (searchValue.length && availableValues.length) {
            searchValue = searchValue.filter(id => availableValues.includes(id));
            searchParams.set(key, searchValue.join(','));
            setInitialValue(searchValue);
            setIsInitialize(true);
          }
          break;
        case FILTERS.LIVE_ONLY:
          if (searchValue === 'true') {
            searchParams.set(key, true);
            setInitialValue(true);
            setIsInitialize(true);
          } else {
            searchParams.delete(key);
          }
          break;
        case FILTERS.DATE:
          if (!Number.isNaN(+new Date(searchValue))) {
            searchParams.set(key, searchValue);
            setInitialValue(moment(searchValue).toDate());
            setIsInitialize(true);
          } else {
            searchParams.delete(key);
          }
          break;
        case FILTERS.COUNTRY:
        case FILTERS.LANG:
        case FILTERS.STATUS:
        case FILTERS.PROFILE_ACTIVITIES:
          if (searchValue && availableValues.length) {
            if (availableValues.includes(searchValue)) {
              searchParams.set(key, searchValue);
              setInitialValue(searchValue);
            }
            setIsInitialize(true);
          }
          break;
        default:
      }
      navigate({ search: `?${searchParams.toString()}`, hash }, { replace: true });
    }
  }, [search, key, availableValues, isInitialize, hash]);

  const changeFilter = value => {
    const searchParams = new URLSearchParams(window.location.search);
    switch (key) {
      case FILTERS.GAMES:
      case FILTERS.TOURNAMENTS:
        if (value.length) {
          searchParams.set(key, value.join(','));
        } else {
          searchParams.delete(key);
        }
        break;
      case FILTERS.LIVE_ONLY:
        if (value === true) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        break;
      case FILTERS.DATE:
      case FILTERS.STATUS:
        searchParams.set(key, value);
        break;
      case FILTERS.COUNTRY:
      case FILTERS.LANG:
      case FILTERS.PROFILE_ACTIVITIES:
        if (value !== ALL_FILTER) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
        break;
      default:
    }
    navigate({ search: `?${searchParams.toString()}`, hash }, { replace: true });
  };

  return { changeFilter };
};

export { useQueryFilter };
