import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HiChevronLeft } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import { PredictionSlip } from '../components/Match';
import { MissionListWidget } from '../components/Rewards/Missions';
import { LatestTips, TipsterRank } from '../components/Tips';
import { ProfileEdit } from '../components/Profile';
import { IconButton } from '../components/common';
import { NoAccess } from '../components/NoAccess';

import { userSelectors } from '../redux/slices';
import { useScrollTop } from '../hooks';

const ProfileEditPage = () => {
  const { t } = useTranslation();
  const { username } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(userSelectors.selectUserDetails);

  const handleGoBack = useCallback(() => navigate(-1), []);

  useScrollTop();

  if (!currentUser) {
    return null;
  }

  if ((username !== currentUser?.username && currentUser)) {
    return <NoAccess />;
  }

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <div className="text-white d-flex align-items-center gap-3 mt-20">
          <IconButton
            icon={<HiChevronLeft />}
            onClick={handleGoBack}
          />
          <h3 className="text-nowrap text-capitalize mb-0">{t('PROFILE.EDIT_PROFILE')}</h3>
        </div>
        <div className="divider mt-20 mb-20" />
        <ProfileEdit username={username} />
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { ProfileEditPage };
