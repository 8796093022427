import axios from 'axios';
import createAuth0Client from '@auth0/auth0-spa-js';

import { TOKEN_STORAGE } from '../../data/constants';

export const handleAxiosErrors = async (error) => {
  if (error?.response?.status === 401) {
    if (localStorage.getItem(TOKEN_STORAGE)) {
      try {
        const auth0 = await createAuth0Client({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        const accessToken = await auth0.getTokenSilently();
        localStorage.setItem(TOKEN_STORAGE, accessToken);
        return axios.request(error.config);
      } catch (err) {
        localStorage.removeItem(TOKEN_STORAGE);
        return window.location.replace('/');
      }
    } else {
      localStorage.removeItem(TOKEN_STORAGE);
      return window.location.replace('/');
    }
  }
  return Promise.reject(error);
};

const getConfig = (config = {}) => {
  const headers = {
    ...config.headers,
    ...(localStorage.getItem(TOKEN_STORAGE) ? { Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE)}` } : {})
  };

  return { headers, ...config };
};

const createRequestInstance = (customConfig = {}) => {
  const instance = axios.create({ ...getConfig(), ...customConfig, });
  const { get, post, put, delete: del } = instance;

  instance.interceptors.response.use(response => response, handleAxiosErrors);

  instance.get = (url, config) => get.call(this, url, getConfig(config));
  instance.post = (url, data, config) => post.call(this, url, data, getConfig(config));
  instance.put = (url, data, config) => put.call(this, url, data, getConfig(config));
  instance.delete = (url, config) => del.call(this, url, getConfig(config));

  return instance;
};

const request = createRequestInstance();
const externalRequest = axios.create({});

export { request, externalRequest };
