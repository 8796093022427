export const pages = {
  home: 'home',
  matches: 'matches',
  matchDetails: 'matchDetails',
  tournaments: 'tournaments',
  tournamentDetails: 'tournamentDetails',
  teams: 'teams',
  teamDetails: 'teamDetails',
  watchlist: 'watchlist',
  leaderboards: 'leaderboards',
  leaderboardDetails: 'LeaderboardDetails',
  rewards: 'rewards',
  profile: 'profile',
  profileEdit: 'profileEdit',
  profileActivities: 'profileActivities',
  tips: 'tips',
  admin: 'admin',
  adminMarketplace: 'adminMarketplace',
  adminMissions: 'adminMissions',
  adminUsers: 'adminUsers',
  adminRedemptions: 'adminRedemptions',
};

export const routes = {
  [pages.home]: '/',
  [pages.matches]: `/${pages.matches}`,
  [pages.matchDetails]: `/${pages.matches}/:matchId`,
  [pages.tournaments]: `/${pages.tournaments}`,
  [pages.tournamentDetails]: `/${pages.tournaments}/:tournamentId`,
  [pages.teams]: `/${pages.teams}`,
  [pages.teamDetails]: `/${pages.teams}/:teamId`,
  [pages.watchlist]: `/${pages.watchlist}`,
  [pages.leaderboards]: `/${pages.leaderboards}`,
  [pages.leaderboardDetails]: `/${pages.leaderboards}/:id`,
  [pages.rewards]: `/${pages.rewards}`,
  [pages.profile]: `/${pages.profile}/:username`,
  [pages.profileEdit]: `/${pages.profile}/:username/edit`,
  [pages.profileActivities]: `/${pages.profile}/:username/activities`,
  [pages.tips]: `/${pages.tips}`,
  [pages.admin]: `/${pages.admin}`,
  [pages.adminMarketplace]: `/${pages.admin}/marketplace`,
  [pages.adminMissions]: `/${pages.admin}/missions`,
  [pages.adminUsers]: `/${pages.admin}/users`,
  [pages.adminRedemptions]: `/${pages.admin}/redemptions`,
};

export const menuPages = [
  {
    routes: [
      routes[pages.home],
      routes[pages.tips],
    ],
    slug: pages.home
  },
  {
    routes: [
      routes[pages.matches],
      routes[pages.matchDetails],
      routes[pages.tips],
    ],
    slug: pages.matches
  },
  {
    routes: [
      routes[pages.tournaments],
      routes[pages.tournamentDetails],
    ],
    slug: pages.tournaments
  },
  {
    routes: [
      routes[pages.teams],
      routes[pages.teamDetails],
    ],
    slug: pages.teams
  },
  // {
  //   routes: [
  //     routes[pages.watchlist],
  //   ],
  //   slug: pages.watchlist
  // },
  {
    routes: [
      routes[pages.leaderboards],
      routes[pages.leaderboardDetails],
      routes[pages.profile],
    ],
    slug: pages.leaderboards
  },
  {
    routes: [
      routes[pages.rewards],
    ],
    slug: pages.rewards
  }
];

export const defaultPages = {
  [pages.profileEdit]: routes[pages.profileEdit],
  [pages.profileActivities]: routes[pages.profileActivities],
};

export const adminPages = {
  [pages.admin]: routes[pages.admin],
  [pages.adminMarketplace]: routes[pages.adminMarketplace],
  [pages.adminMissions]: routes[pages.adminMissions],
  [pages.adminUsers]: routes[pages.adminUsers],
  [pages.adminRedemptions]: routes[pages.adminRedemptions],
};
