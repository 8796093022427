import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { teamsActions, teamsSelectors } from '../slices';

export function* loadCountriesFlow() {
  try {
    const countries = yield call(api.getCountries);
    yield put(teamsActions.loadCountries.success(countries));
  } catch (error) {
    yield put(teamsActions.loadCountries.failed({ error }));
  }
}

export function* loadTeamsFlow({ payload: { game, country, page } }) {
  try {
    const data = yield call(api.getTeams, game, country, page + 1);
    yield put(teamsActions.loadTeams.success({ game, country, page: page + 1, data }));
  } catch (error) {
    yield put(teamsActions.loadTeams.success({ game, country, data: [] }));
  }
}

export function* loadTeamsByRostersFlow({ payload: { ids } }) {
  try {
    const existedIds = yield select(teamsSelectors.selectTeamsRostersIds);
    const newIds = ids.filter(id => !existedIds.includes(id));
    if (newIds.length) {
      const data = yield call(api.getTeamsByRosters, newIds);
      yield put(teamsActions.loadTeamsByRosters.success(data));
    }
  } catch (error) {
    yield put(teamsActions.loadTeamsByRosters.failed({ error }));
  }
}

export function* loadDetailsFlow({ payload: { id } }) {
  try {
    const team = yield call(api.getTeamById, id);
    let roster = null;
    if (team?.standing_roster?.roster?.id) {
      [roster] = yield call(api.getRosters, [team?.standing_roster?.roster?.id]);
    }
    yield put(teamsActions.loadDetails.success({ team, roster }));
  } catch (error) {
    yield put(teamsActions.loadDetails.failed({ error }));
  }
}

export function* loadPlayersFlow({ payload: { ids } }) {
  try {
    const existedIds = yield select(teamsSelectors.selectPlayerIds);
    const newIds = ids.filter(id => !existedIds.includes(id));
    if (newIds.length) {
      const data = yield call(api.getPlayers, ids);
      yield put(teamsActions.loadPlayers.success(data));
    }
  } catch (error) {
    yield put(teamsActions.loadPlayers.failed({ error }));
  }
}

export function* loadTournamentParticipantsFlow({ payload: { id } }) {
  try {
    const data = yield call(api.getTournamentParticipants, id);
    const rosters = Array.from(new Set([
      ...(data.confirmed_participants || []).map(item => item.roster.id),
      ...(data.phase_participants?.final || []).map(item => item.roster.id),
      ...(data.phase_participants?.other || []).map(item => item.roster.id),
      ...(data.phase_participants?.qualifier || []).map(item => item.roster.id),
      ...(data.phase_participants?.regular || []).map(item => item.roster.id),
    ]));
    const rostersData = yield call(api.getRosters, rosters);
    yield all([
      put(teamsActions.loadTeamsByRosters.base({ ids: rosters })),
      put(teamsActions.loadTournamentParticipants.success(rostersData)),
    ]);
  } catch (error) {
    yield put(teamsActions.loadTournamentParticipants.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(teamsActions.loadCountries.types.BASE, loadCountriesFlow),
    takeLatest(teamsActions.loadTeams.types.BASE, loadTeamsFlow),
    takeEvery(teamsActions.loadTeamsByRosters.types.BASE, loadTeamsByRostersFlow),
    takeEvery(teamsActions.loadDetails.types.BASE, loadDetailsFlow),
    takeEvery(teamsActions.loadPlayers.types.BASE, loadPlayersFlow),
    takeEvery(teamsActions.loadTournamentParticipants.types.BASE, loadTournamentParticipantsFlow),
  ]);
}
