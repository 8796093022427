import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { redemptionsActions, redemptionsSelectors, userSelectors } from '../../../redux/slices';
import { RedemptionItem } from './RedemptionItem';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useScroll } from '../../../hooks';
import { ROLES } from '../../../data/constants';

const Redemptions = ({ uid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const data = useSelector(redemptionsSelectors.selectData);
  const total = useSelector(redemptionsSelectors.selectData);
  const isLoading = useSelector(redemptionsSelectors.selectIsLoading);
  const currentUser = useSelector(userSelectors.selectUserDetails);

  const isAdmin = currentUser.uid !== uid && currentUser.roles?.includes(ROLES.REWARDS_ADMIN);

  const handleLoadData = useCallback(() => {
    const newPage = page + 1;
    setPage(newPage);
    dispatch(redemptionsActions.loadData.base({ page: newPage, uid }));
  }, [page, uid]);

  useEffect(() => {
    handleLoadData();
    return () => dispatch(redemptionsActions.clearData.base());
  }, []);

  useScroll(() => {
    if (data.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data.length, total, isLoading]);

  const handleApprove = useCallback(({ _id }) => () => {
    dispatch(redemptionsActions.approve.base({ id: _id }));
  }, []);

  return (
    <div className="show-grid mb-20">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-2 text-nowrap">{t('REDEMPTIONS.ADMIN_PAGE_TITLE')}</h3>
      </div>
      <Table variant="dark" className="mt-20" hover>
        <thead>
          <tr>
            {isAdmin && (
              <th>{t('REDEMPTIONS.USER')}</th>
            )}
            <th>{t('REDEMPTIONS.MARKETPLACE')}</th>
            <th>{t('REDEMPTIONS.ITEM')}</th>
            <th>{t('REDEMPTIONS.STATUS')}</th>
            <th>{t('REDEMPTIONS.DATES')}</th>
            {isAdmin && (
              <th />
            )}
          </tr>
        </thead>
        <tbody>
          {!isLoading && !data.length && (
            <tr><td colSpan={9} className="text-center">{t('REDEMPTIONS.NO_RESULTS')}</td></tr>
          )}
          {data.map(item => (
            <RedemptionItem
              key={item._id}
              data={item}
              isAdmin={isAdmin}
              onApprove={handleApprove(item)}
            />
          ))}
        </tbody>
      </Table>
      {isLoading && (
        <LoadingSpinner />
      )}
      {data?.length < total && !isLoading && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
    </div>
  );
};

Redemptions.propTypes = {
  uid: PropTypes.string.isRequired,
};

export { Redemptions };
