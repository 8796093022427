import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const StatCard = ({ className, icon, title, statistic }) => (
  <div className={cx('profile-stats-card', className)}>
    <div className="icon">{icon}</div>
    <div className="content">
      <div className="title">{title}</div>
      <div className="statistic">{statistic}</div>
    </div>
  </div>
);

StatCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  statistic: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StatCard.defaultProps = {
  className: undefined,
  statistic: 0
};

export { StatCard };
