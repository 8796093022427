import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';
import { MdTimer } from 'react-icons/md';

import { CardWithIcon } from './CardWithIcon';
import MdCup from '../../../../assets/images/icons/cup.svg';
import DiamondIcon from '../../../../assets/images/diamonds/diamond.svg';
import { useDurationFromNow } from '../../../../hooks';

const TimePrize = ({ className, time, diamonds }) => {
  const { t } = useTranslation();
  const duration = useDurationFromNow(time);

  return (
    <Row className={className}>
      <Col xs={12} md={6} className="mb-20">
        <CardWithIcon
          icon={<MdTimer className="text-secondary" />}
          text={duration}
          subtext={t('LEADERBOARDS.EVENT.ENDS_IN')}
        />
      </Col>
      <Col xs={12} md={6} className="mb-20">
        <CardWithIcon
          icon={<Image src={MdCup} alt="" />}
          text={(
            <>
              <Image src={DiamondIcon} alt="" className="me-2" />
              <span>{parseInt(diamonds, 10).toLocaleString()}</span>
            </>
          )}
          subtext={t('LEADERBOARDS.EVENT.PRIZE_POOL')}
        />
      </Col>
    </Row>
  );
};

TimePrize.propTypes = {
  className: PropTypes.string,
  time: PropTypes.string.isRequired,
  diamonds: PropTypes.number.isRequired,
};

TimePrize.defaultProps = {
  className: undefined
};

export { TimePrize };
