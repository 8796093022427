import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Image } from 'react-bootstrap';
import { AiOutlineEllipsis } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import { TournamentFilterModal } from './TournamentFilterModal';

import './styles.scss';
import { matchesSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';

import { useQueryFilter } from '../../../hooks';
import { FILTERS } from '../../../data/constants';

const TournamentFilter = ({ options, selection, setSelection }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { changeFilter } = useQueryFilter(FILTERS.TOURNAMENTS, setSelection, options.map(o => o.id));

  const games = useSelector(matchesSelectors.selectGames);

  const gamesImagesMap = useMemo(() => games.reduce((map, next) => ({
    ...map,
    [next.id]: next.images.find((i) => i.type === 'circle')?.url
  }), {}), [games]);

  const handleOpenModal = useCallback(() => setShow(true), []);
  const handleCloseModal = useCallback(() => setShow(false), []);

  const filtersButtons = useMemo(() => {
    const optArr = [].concat(options);
    const arr = [];
    Object.keys(gamesImagesMap).forEach(gameId => {
      if (arr.length < 3) {
        const index = optArr.findIndex(r => r.game?.id === parseInt(gameId, 10));
        if (index > -1) {
          arr.push(...optArr.splice(index, 1));
        }
      }
    });
    while (arr.length < 3) {
      arr.push(optArr.shift());
    }
    return arr;
  }, [options, gamesImagesMap]);

  const isMoreBtnActive = useMemo(() => {
    const filtersButtonsIds = filtersButtons.filter(item => !!item).map(item => item.id);
    return selection.filter(id => !filtersButtonsIds.includes(id)).length > 0;
  }, [filtersButtons, selection]);

  const handleChangeFilter = ids => {
    setSelection(ids);
    changeFilter(ids);
  };

  const handleChangeTournament = useCallback(({ id }) => () => {
    if (!id) {
      handleChangeFilter([]);
    } else if (selection.includes(id)) {
      handleChangeFilter(selection.filter((s) => s !== id));
    } else {
      handleChangeFilter([...selection, id]);
    }
  }, [selection]);

  return (
    <>
      <div className="filter-tournament flex-wrap flex-xl-nowrap">
        <Button
          className={cx('btn-midnight', 'text-uppercase', { selected: selection.length === 0 })}
          onClick={handleChangeTournament({})}
        >
          {t('TOURNAMENTS.ALL')}
        </Button>
        {filtersButtons.filter(f => !!f).map((item) => (
          <Button
            key={item.id}
            className={cx('btn-midnight', { selected: selection.includes(item.id) })}
            onClick={handleChangeTournament(item)}
            title={getTournamentTitle(item.title)}
          >
            <Image src={gamesImagesMap[item.game?.id]} alt="" />
            <span>{item.short_title}</span>
          </Button>
        ))}
        <Button className={cx('btn-midnight', 'more', { selected: isMoreBtnActive })} onClick={handleOpenModal}>
          <AiOutlineEllipsis />
        </Button>
      </div>
      <TournamentFilterModal
        show={show}
        handleClose={handleCloseModal}
        games={games}
        options={options}
        selection={selection}
        onChange={handleChangeTournament}
      />
    </>
  );
};

TournamentFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    short_title: PropTypes.string.isRequired,
    game: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  })).isRequired,
  selection: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired
};

export { TournamentFilter };
