import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { REWARDS_TABS as TABS, MISSION_TYPES } from '../data/constants';

import { PredictionSlip } from '../components/Match';
import { LatestTips, TipsterRank } from '../components/Tips';
import { MissionListWidget, MissionList } from '../components/Rewards/Missions';
import { MarketplaceList } from '../components/Rewards/Marketplace';
// import { PartnerBanner } from '../components/PartnerBanner';
import { useScrollTop } from '../hooks';

const RewardsPage = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = useState();

  useScrollTop();

  const handleChangePage = useCallback(page => {
    navigate({ hash: page }, { replace: true });
  }, [hash]);

  useEffect(() => {
    const page = hash.replace('#', '');
    if (Object.values(TABS).includes(page)) {
      setPage(page);
    } else {
      navigate({ hash: TABS.MARKETPLACE }, { replace: true });
    }
  }, [hash]);

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        {/* <PartnerBanner className="mb-20" partner={PARTNERS.BETWAYESPORTS} /> */}
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <Tabs fill activeKey={page} onSelect={handleChangePage} className="mb-20 mt-20">
          {Object.keys(TABS).map(tab => (
            <Tab key={tab} eventKey={TABS[tab]} title={t(`REWARDS.TABS.${tab}`)} />
          ))}
        </Tabs>
        {page === TABS.MARKETPLACE && (
          <MarketplaceList />
        )}
        {page === TABS.MISSIONS && (
          <MissionList type={MISSION_TYPES.DAILY} />
        )}
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { RewardsPage };
