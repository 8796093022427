import { createSlice } from '../../helpers/store';
import { PROFILE_FOLLOW_TYPE } from '../../data/constants';

const initialState = {
  userDetails: null,
  emailVerified: null,
  userProfile: {
    isLoading: false,
    data: null,
    follow: {
      isLoading: false,
    },
    predictions: {
      isLoading: false,
      total: 0,
      latestData: [],
      data: []
    },
  },
  leaderboards: {},
  leaderboardEvent: {
    isLoading: false,
    data: [],
    leaders: {
      isLoading: false,
      total: 0,
      data: []
    }
  },
  claimDailyLogin: {},
  admin: {
    isUpdating: false,
    isLoading: false,
    data: [],
    total: 0,
    query: ''
  },
  activities: {
    isLoading: false,
    data: [],
    ids: [],
    total: 0
  }
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'user',
  initialState,

  selectors: {
    selectUserDetails: state => state.userDetails,
    selectLeaderboards: state => state.leaderboards,
    selectLeaderboardEvent: state => state.leaderboardEvent,
    selectLeaderboardEventLeaders: state => state.leaderboardEvent.leaders,
    selectUserProfile: state => state.userProfile,
    selectUserProfileFollowData: state => state.userProfile.follow,
    selectUserProfileFollowLoading: state => state.userProfile.follow.isLoading,
    selectProfilePredictions: state => state.userProfile.predictions,
    selectClaimDailyLogin: state => state.claimDailyLogin,
    selectEmailVerified: state => state.emailVerified,
    selectAdminData: state => state.admin,
    selectActivities: state => state.activities,
  },

  reducers: {
    loadUserDetails: {
      success: (state, { payload }) => {
        state.userDetails = payload;
        state.emailVerified = true;
      }
    },
    setEmailVerified: {
      base: (state, { payload }) => {
        state.emailVerified = payload;
      }
    },
    resendConfirmationLink: {},
    clearUserProfile: {
      base: state => {
        state.userProfile = { ...initialState.userProfile };
      }
    },
    loadUserProfile: {
      base: state => {
        state.userProfile.isLoading = true;
      },
      success: (state, { payload }) => {
        state.userProfile.isLoading = false;
        state.userProfile.data = payload;
      },
      failed: state => {
        state.userProfile.isLoading = false;
      }
    },
    loadLeaderboardsList: {
      base: (state, { payload: { filter, page } }) => {
        if (!state.leaderboards[filter] || page === 0) {
          state.leaderboards[filter] = {};
        }
        state.leaderboards[filter].isLoading = true;
      },
      success: (state, { payload: { filter, itemsCount, results } }) => {
        state.leaderboards[filter].total = itemsCount;
        state.leaderboards[filter].data = (state.leaderboards[filter].data || []).concat(results);
        state.leaderboards[filter].isLoading = false;
      },
      failed: (state, { payload: { filter } }) => {
        state.leaderboards[filter].isLoading = false;
      }
    },
    clearLeaderboardEvent: {
      base: state => {
        state.leaderboardEvent = { ...initialState.leaderboardEvent };
      },
    },
    loadLeaderboardEvent: {
      base: state => {
        state.leaderboardEvent.isLoading = true;
      },
      success: (state, { payload }) => {
        state.leaderboardEvent.data = payload;
        state.leaderboardEvent.isLoading = false;
      },
      failed: state => {
        state.leaderboardEvent.isLoading = false;
      },
    },
    loadLeaderboardEventLeaders: {
      base: state => {
        state.leaderboardEvent.leaders.isLoading = true;
      },
      success: (state, { payload: { itemsCount, results } }) => {
        state.leaderboardEvent.leaders.total = itemsCount;
        state.leaderboardEvent.leaders.data = (state.leaderboardEvent.leaders.data || []).concat(results);
        state.leaderboardEvent.leaders.isLoading = false;
      },
      failed: state => {
        state.leaderboardEvent.leaders.isLoading = false;
      }
    },
    followUser: {
      success: state => {
        state.userProfile.data.isFollower = true;
        state.userProfile.data.followers += 1;
      }
    },
    unfollowUser: {
      success: (state, { payload: { username, isCurrentUser } }) => {
        const type = PROFILE_FOLLOW_TYPE.FOLLOWINGS;
        if (isCurrentUser) {
          state.userProfile.data.followings -= 1;
        } else {
          state.userProfile.data.isFollower = false;
          state.userProfile.data.followers -= 1;
        }
        if (state.userProfile.follow[type]) {
          state.userProfile.follow[type] = state.userProfile.follow[type]
            .filter(item => item.followingUsername !== username);
        }
      }
    },
    loadFollowData: {
      base: state => {
        state.userProfile.follow.isLoading = true;
      },
      success: (state, { payload: { type, data } }) => {
        state.userProfile.follow.isLoading = false;
        state.userProfile.follow[type] = data;
      },
      failed: state => {
        state.userProfile.follow.isLoading = true;
      }
    },
    removeFollower: {
      success: (state, { payload: { username } }) => {
        const type = PROFILE_FOLLOW_TYPE.FOLLOWERS;
        state.userProfile.data.followers -= 1;
        state.userProfile.follow[type] = (state.userProfile.follow[type] || [])
          .filter(item => item.username !== username);
      }
    },
    clearPredictionsForProfile: {
      base: state => {
        state.userProfile.predictions = {
          ...initialState.userProfile.predictions,
          latestData: state.userProfile.predictions.latestData
        };
      }
    },
    loadPredictionsForProfile: {
      base: state => {
        state.userProfile.predictions.isLoading = true;
      },
      success: (state, { payload: { itemCount, results } }) => {
        if (!state.userProfile.predictions.latestData.length) {
          state.userProfile.predictions.latestData = results;
        }
        state.userProfile.predictions.data = (state.userProfile.predictions.data || []).concat(results);
        state.userProfile.predictions.total = itemCount;
        state.userProfile.predictions.isLoading = false;
      },
      failed: state => {
        state.userProfile.predictions.isLoading = false;
      }
    },
    claimDailyLogin: {
      success: (state, { payload }) => {
        state.claimDailyLogin = payload;
      }
    },
    diamondsChanged: {
      success: (state, { payload: { uid, diamonds } }) => {
        if (state.userDetails?.uid === uid) {
          state.userDetails.wallet.diamonds = diamonds;
        }
        if (state.userProfile?.data?.uid === uid) {
          state.userDetails.data.wallet.diamonds = diamonds;
        }
        Object.keys(state.leaderboards).forEach(key => {
          state.leaderboards[key]?.data?.forEach((item, ind) => {
            if (item.uid === uid) {
              state.leaderboards[key].data.splice(ind, 1, { ...item, diamonds });
            }
          });
        });
      }
    },
    updateProfile: {
      base: (state, { payload: { isAdmin } }) => {
        if (isAdmin) {
          state.admin.isUpdating = true;
        }
      },
      success: (state, { payload }) => {
        if (state.userDetails?._id === payload._id) {
          state.userDetails = payload;
        }
        const index = state.admin.data.findIndex(item => item._id === payload._id);
        if (index > -1) {
          state.admin.data.splice(index, 1, payload);
        }
        state.admin.isUpdating = false;
      },
      failed: state => {
        state.admin.isUpdating = false;
      }
    },
    deleteProfile: {
      success: (state, { payload: { id } }) => {
        if (state.userProfile.data?._id === id) {
          state.userProfile = { ...initialState.userProfile };
        }
        const index = state.admin.data.findIndex(item => item._id === id);
        if (index > -1) {
          state.admin.data.splice(index, 1);
          state.admin.total--;
        }
      }
    },
    blockProfile: {
      success: (state, { payload: { id } }) => {
        const index = state.admin.data.findIndex(item => item._id === id);
        if (index > -1) {
          state.admin.data.splice(index, 1, {
            ...state.admin.data[index],
            blocked: true
          });
        }
      }
    },
    unblockProfile: {
      success: (state, { payload: { id } }) => {
        const index = state.admin.data.findIndex(item => item._id === id);
        if (index > -1) {
          state.admin.data.splice(index, 1, {
            ...state.admin.data[index],
            blocked: false
          });
        }
      }
    },
    resetPassword: {},
    loadAdminData: {
      base: (state, { payload: { query } }) => {
        if (state.admin.query !== query) {
          state.admin = { ...initialState.admin };
        }
        state.admin.query = query;
        state.admin.isLoading = true;
      },
      success: (state, { payload: { items, total } }) => {
        state.admin.isLoading = false;
        state.admin.data = state.admin.data.concat(items);
        state.admin.total = total;
      },
      failed: state => {
        state.admin.isLoading = false;
      }
    },
    clearAdminData: {
      base: state => {
        state.admin = { ...initialState.admin };
      }
    },
    loadActivities: {
      base: (state, { payload: { filter } }) => {
        if (state.activities.filter !== filter) {
          state.activities = { ...initialState.activities };
        }
        state.activities.filter = filter;
        state.activities.isLoading = true;
      },
      success: (state, { payload: { items, total } }) => {
        state.activities.isLoading = false;
        items.forEach(item => {
          if (!state.activities.ids.includes(item._id)) {
            state.activities.data.push(item);
            state.activities.ids.push(item._id);
          }
        });
        state.activities.total = total;
      },
      failed: state => {
        state.activities.isLoading = false;
      }
    },
    clearActivities: {
      base: state => {
        state.activities = { ...initialState.activities };
      }
    }
  },
});

export { reducer as user, actions as userActions, selectors as userSelectors };
