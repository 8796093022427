import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { userActions, userSelectors } from '../../../redux/slices';

import { LoadingSpinner } from '../../LoadingSpinner';
import { Hero } from './Hero';
import { TimePrize } from './TimePrize';
import { LeadersRace } from './LeadersRace';
import { WinnersPlaces } from './WinnersPlaces';
import { LeadersWithPrizeList } from './LeadersWithPrizeList';

import './styles.scss';

const LeaderboardEvent = ({ id }) => {
  const dispatch = useDispatch();
  // const { isLoading, data } = useSelector(userSelectors.selectLeaderboardEvent);
  const { isLoading } = useSelector(userSelectors.selectLeaderboardEvent);

  useEffect(() => {
    dispatch(userActions.loadLeaderboardEvent.base({ id }));
    return () => dispatch(userActions.clearLeaderboardEvent.base({ id }));
  }, [id]);

  return (
    <div className="mt-20 leaderboard-event">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Hero
            className="mt-20 mb-20"
            data={{ title: 'Space Race', subtitle: 'Join us today!', diamonds: 100000 }}
          />
          <TimePrize
            className="mt-20 mb-20"
            time={new Date(+new Date() + 1000 * 60 * 60 * 50)}
            diamonds={100000}
          />
          <LeadersRace
            className="mt-20 mb-20"
            maxScore={100000}
            items={[
              { uid: '111', username: 'Knoxville R.', avatar: '', score: 98792 },
              { uid: '112', username: 'Kloe709', avatar: '', score: 96792 },
              { uid: '113', username: 'Hanc144', avatar: '', score: 90792 },
              { uid: '114', username: 'FollieType', avatar: '', score: 66792 },
              { uid: '115', username: 'Scriptor', avatar: '', score: 65792 },
              { uid: '116', username: 'BmTheW', avatar: '', score: 46792 },
              { uid: '117', username: 'Grant Q12', avatar: '', score: 36792 },
              { uid: '118', username: 'Fileor', avatar: '', score: 34792 },
              { uid: '119', username: 'Hax1337', avatar: '', score: 16792 },
              { uid: '110', username: 'Johnny W.', avatar: '', score: 13792 },
            ]}
          />
          <WinnersPlaces
            className="mt-20 mb-20"
            first={{ uid: '111', username: 'Knoxville R.', avatar: '', score: 98792 }}
            second={{ uid: '112', username: 'Kloe709', avatar: '', score: 96792 }}
            third={{ uid: '113', username: 'Hanc144', avatar: '', score: 90792 }}
          />
          <LeadersWithPrizeList
            className="mt-20 mb-20"
          />
        </>
      )}
    </div>
  );
};

LeaderboardEvent.propTypes = {
  id: PropTypes.string.isRequired
};

export { LeaderboardEvent };
