import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';

import * as api from '../../api';
import { missionsActions } from '../slices';

export function* loadDataFlow({ payload }) {
  try {
    const data = yield call(api.getMissions, payload);
    yield put(missionsActions.loadData.success(data));
  } catch (error) {
    yield put(missionsActions.loadData.failed({ error }));
  }
}

export function* createFlow({ payload: { data } }) {
  try {
    const result = yield call(api.createMission, data);
    yield put(missionsActions.create.success(result));
  } catch (error) {
    yield put(missionsActions.create.failed({ error }));
  }
}

export function* updateFlow({ payload: { id, data } }) {
  try {
    const result = yield call(api.updateMission, id, data);
    yield put(missionsActions.update.success(result));
  } catch (error) {
    yield put(missionsActions.update.failed({ error }));
  }
}

export function* deleteFlow({ payload: { id } }) {
  try {
    const result = yield call(api.deleteMission, id);
    yield put(missionsActions.delete.success({ id, data: result }));
  } catch (error) {
    yield put(missionsActions.delete.failed({ error }));
  }
}

export function* claimMissionFlow({ payload: { id } }) {
  try {
    yield call(api.claimMission, id);
    yield put(missionsActions.claimMission.success({ id }));
  } catch (error) {
    yield put(missionsActions.claimMission.failed({ error }));
  }
}

export function* startMissionFlow({ payload: { id } }) {
  try {
    yield call(api.startMission, id);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default function* root() {
  yield all([
    takeEvery(missionsActions.loadData.types.BASE, loadDataFlow),
    takeLatest(missionsActions.create.types.BASE, createFlow),
    takeLatest(missionsActions.update.types.BASE, updateFlow),
    takeLatest(missionsActions.delete.types.BASE, deleteFlow),
    takeLatest(missionsActions.claimMission.types.BASE, claimMissionFlow),
    takeLatest(missionsActions.startMission.types.BASE, startMissionFlow),
  ]);
}
