import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { marketplaceActions } from '../slices';

function* processUploadImages(data) {
  try {
    const imagesDocs = yield all(data.items.map(item => (
      typeof item.image === 'string' ? item.image : call(api.uploadDocument, item.image)
    )));

    return {
      ...data,
      items: data.items.map((item, ind) => ({
        ...item,
        image: imagesDocs[ind] || item.image
      }))
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed upload document', err);
    return data;
  }
}

export function* loadDataFlow({ payload }) {
  try {
    const data = yield call(api.getMarketplaceItems, payload);
    yield put(marketplaceActions.loadData.success(data));
  } catch (error) {
    yield put(marketplaceActions.loadData.failed({ error }));
  }
}

export function* createFlow({ payload }) {
  try {
    payload = yield call(processUploadImages, payload);
    const result = yield call(api.createMarketplaceItem, payload);
    yield put(marketplaceActions.create.success(result));
  } catch (error) {
    yield put(marketplaceActions.create.failed({ error }));
  }
}

export function* updateFlow({ payload: { id, data } }) {
  try {
    data = yield call(processUploadImages, data);
    const result = yield call(api.updateMarketplaceItem, id, data);
    yield put(marketplaceActions.update.success(result));
  } catch (error) {
    yield put(marketplaceActions.update.failed({ error }));
  }
}

export function* deleteFlow({ payload: { id } }) {
  try {
    const data = yield call(api.deleteMarketplaceItem, id);
    yield put(marketplaceActions.delete.success({ id, data }));
  } catch (error) {
    yield put(marketplaceActions.delete.failed({ error }));
  }
}

export function* redeemFlow({ payload: { _id } }) {
  try {
    const data = yield call(api.sendMarketplaceRedeemItem, _id);
    yield put(marketplaceActions.redeem.success(data));
  } catch (error) {
    yield put(marketplaceActions.redeem.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(marketplaceActions.loadData.types.BASE, loadDataFlow),
    takeLatest(marketplaceActions.create.types.BASE, createFlow),
    takeLatest(marketplaceActions.update.types.BASE, updateFlow),
    takeLatest(marketplaceActions.delete.types.BASE, deleteFlow),
    takeLatest(marketplaceActions.redeem.types.BASE, redeemFlow),
  ]);
}
