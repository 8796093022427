import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { matchesSelectors } from '../../../redux/slices';
import { PLATFORMS } from '../../../data/constants';

const MatchChatWidget = ({ ...props }) => {
  const { t } = useTranslation();
  const primaryStream = useSelector(matchesSelectors.selectSelectedStream);

  if (!Object.values(PLATFORMS).includes(primaryStream?.platform?.name)) {
    return null;
  }

  return (
    <Accordion defaultActiveKey="match-chat-widget" flush {...props}>
      <Accordion.Item eventKey="match-chat-widget">
        <Accordion.Header className="d-lg-none">
          {t('MATCH_DETAILS.CHAT')}
        </Accordion.Header>
        <Accordion.Body>
          <Card bg="dark" className="large-card">
            <Card.Header className="d-none d-lg-block">
              {t('MATCH_DETAILS.CHAT')}
            </Card.Header>
            {primaryStream?.platform?.name === PLATFORMS.TWITCH && (
              <iframe
                src={`https://www.twitch.tv/embed/${primaryStream.username}/chat?parent=${window.location.hostname}&darkpopout`}
                width="100%"
                height="415"
              />
            )}
            {primaryStream?.platform?.name === PLATFORMS.YOUTUBE && (
              <iframe
                src={`https://www.youtube.com/live_chat?v=${primaryStream.username}&embed_domain=${window.location.hostname}&dark_theme=1`}
                width="100%"
                height="415"
              />
            )}
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export { MatchChatWidget };
