import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Dropdown, Image } from 'react-bootstrap';
import { MdEdit, MdDelete, MdOutlineChangeCircle, MdOutlineCheckCircle, MdBlock, MdMoreVert } from 'react-icons/md';
import { FiActivity } from 'react-icons/fi';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const UserItem = ({ data, onEdit, onDelete, onBlock, onUnblock, onChangePassword, onShowActivities }) => {
  const { t } = useTranslation();

  return (
    <tr className={cx({ 'text-decoration-line-through': Boolean(data.blocked) })}>
      <td><Image src={data.profile.avatar} style={{ height: 20, width: 20 }} /></td>
      <td>{data.username}</td>
      <td>{data.email}</td>
      <td>{data.wallet.diamonds}</td>
      <td>{data.roles?.map(role => t(`USERS.ROLES_${role.replace(/-/i, '_').toUpperCase()}`)).join(', ')}</td>
      <td>{moment(data.createdAt).format('LLL')}</td>
      <td>{t(`USERS.STATUS_${data.blocked ? 'BLOCKED' : 'ACTIVE'}`)}</td>
      <td>
        <Dropdown align="start" className="pt-1">
          <Dropdown.Toggle
            as={CustomToggle}
            className="d-flex align-items-center justify-content-center cursor-pointer ps-1 pe-1 h-100"
          >
            <MdMoreVert className="text-secondary" />
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            <Dropdown.Item
              className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
              onClick={onEdit}
            >
              <MdEdit />
              {t('EDIT')}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
              onClick={onChangePassword}
            >
              <MdOutlineChangeCircle />
              {t('PROFILE.CHANGE_PASSWORD')}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
              onClick={onShowActivities}
            >
              <FiActivity />
              {t('PROFILE.ACTIVITIES.BUTTON')}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
              onClick={data.blocked ? onUnblock : onBlock}
            >
              {data.blocked ? <MdOutlineCheckCircle /> : <MdBlock />}
              {data.blocked ? t('UNBLOCK') : t('BLOCK')}
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
              onClick={onDelete}
            >
              <MdDelete />
              {t('DELETE')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onUnblock: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onShowActivities: PropTypes.func.isRequired,
};

export { UserItem };
