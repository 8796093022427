import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const LoadingSpinner = ({ absolute, className, ...props }) => (
  <div className={cx('lds-ring', { absolute }, className)} {...props}>
    {['spin1', 'spin2', 'spin3', 'spin4'].map((s) => (
      <div key={s} />
    ))}
  </div>
);

LoadingSpinner.propTypes = {
  absolute: PropTypes.bool,
  className: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  absolute: false,
  className: undefined
};

export { LoadingSpinner };
