import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { matchesActions, matchesSelectors, seriesSelectors, userSelectors } from '../../../redux/slices';

import { MatchTeamRow } from './MatchTeamRow';
import { GAMES_WITH_HIDDEN_MAP_NAME } from '../../../data/constants';

import './styles.scss';

const MatchMap = ({ serie, match: { id, game, lifecycle, map, index } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const userData = useSelector(userSelectors.selectUserDetails);
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const mapsData = useSelector(matchesSelectors.selectMatchMaps);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, id));
  const { isEnded } = useSelector(state => seriesSelectors.selectSerieStatus(state, serie));

  const canVote = !predictions?.myVote && !isEnded && ['upcoming', 'live'].includes(lifecycle);

  const handleVote = useCallback(({ team, roster }) => {
    if (!userData) {
      loginWithRedirect();
    } else {
      dispatch(matchesActions.addPredictionData.base({
        prediction: {
          team,
          roster,
          game: game.id,
          serie: serie.id,
          match: id,
          prediction: 'match',
        },
        participants: serie.participants
      }));
    }
  }, [userData, game, id, serie]);

  return (
    <Accordion defaultActiveKey={`match-map-${map.id}`} className="accordion-match-map">
      <Accordion.Item eventKey={`match-map-${map.id}`}>
        <Accordion.Header>
          <span>
            {`${t('MAP')} ${index}`}
            {GAMES_WITH_HIDDEN_MAP_NAME.includes(game.id) ? '' : ` - ${mapsData[map.id]?.name || ''}` }
          </span>
          <span
            className={cx('status', {
              green: lifecycle === 'live',
              red: !['upcoming', 'live', 'deleted'].includes(lifecycle)
            })}
          >
            {lifecycle}
          </span>
        </Accordion.Header>
        <Accordion.Body>
          {!serie.participants.length && (
            <div className="m-3 text-white text-center">
              {t('MATCH_DETAILS.MAPS.NO_PARTICIPANTS')}
            </div>
          )}
          {serie.participants.map(part => (
            <MatchTeamRow
              key={`${map.id}-${part.roster.id}`}
              roster={part.roster.id}
              participant={part}
              predictions={predictions}
              canVote={canVote}
              onVote={handleVote}
              defaultImage={gamesImagesMap[game?.id]}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

MatchMap.propTypes = {
  serie: PropTypes.shape({
    id: PropTypes.number.isRequired,
    participants: PropTypes.arrayOf(PropTypes.shape({
      roster: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    })),
  }),
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    game: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    lifecycle: PropTypes.string,
    map: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired
  }).isRequired
};

MatchMap.defaultProps = {
  serie: {
    participants: []
  }
};

export { MatchMap };
