import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Dropdown, Image } from 'react-bootstrap';

import { ALL_FILTER, FILTERS } from '../../../data/constants';
import { useQueryFilter } from '../../../hooks';

import FlagIcon from '../../../assets/images/icons/flag.svg';
import DropdownIcon from '../../../assets/images/icons/chevron-down.svg';

import './styles.scss';

const CountriesFilter = ({ options, value, onChange }) => {
  const { t } = useTranslation();
  const { changeFilter } = useQueryFilter(FILTERS.COUNTRY, onChange, options.map(i => i.abbreviation));

  const handleChange = country => {
    onChange(country);
    changeFilter(country);
  };

  return (
    <Dropdown
      as={ButtonGroup}
      className="d-flex align-items-center text-secondary nav nav-tabs filter-countries"
    >
      <Button
        className={cx('nav-link', { active: value === ALL_FILTER })}
        onClick={() => handleChange(ALL_FILTER)}
      >
        {t('ALL')}
      </Button>
      <Dropdown.Toggle className={cx('nav-link', { active: value !== ALL_FILTER })}>
        <Image src={FlagIcon} />
        <Image src={DropdownIcon} alt="&#x25bc;" />
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        {options.map(item => (
          <Dropdown.Item
            key={item.abbreviation}
            onClick={() => handleChange(item.abbreviation)}
            className={cx({ selected: value === item.abbreviation })}
          >
            <Image src={item.images[0]?.url} style={{ height: 16, width: 20 }} />
            <span>{item.name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CountriesFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    abbreviation: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    images: PropTypes.array
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { CountriesFilter };
