// Core
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

// Assets
import './app.scss';

// Components
import {
  HomePage,
  MatchesPage,
  MatchDetailsPage,
  TeamsPage,
  TeamDetailPage,
  TournamentsPage,
  TournamentDetailsPage,
  RewardsPage,
  LeaderboardsPage,
  LeaderboardDetailsPage,
  ProfilePage,
  ProfileEditPage,
  ProfileActivitiesPage,
  AllTipsPage,
  AdminPage,
  AdminMarketplace,
  AdminMissions,
  AdminUsers,
  AdminRedemptions,
  Redirect,
} from './pages';

import { Navigation } from './components/Navigation';
import { LoadingSpinner } from './components/LoadingSpinner';
import { DailyLoginWidget } from './components/DailyLoginWidget';
import { CheckEmailVerifyWidget } from './components/CheckEmailVerifyWidget';
import { TipsModal } from './components/Tips';
import { Footer } from './components/Footer';

import { adminPages, defaultPages, menuPages, pages, routes } from './data/menu';
import { links } from './data/footer';
import { useAbiosWidgetHeight, useCheckAuth } from './hooks';

const allRoutes = Object.values(defaultPages).concat(
  menuPages.reduce((arr, item) => [...arr, ...item.routes.reduce((arr2, next) => [...arr2, next], [])], [])
);

const Component = {
  [routes[pages.home]]: <HomePage />,
  [routes[pages.matches]]: <MatchesPage />,
  [routes[pages.matchDetails]]: <MatchDetailsPage />,
  [routes[pages.tournaments]]: <TournamentsPage />,
  [routes[pages.tournamentDetails]]: <TournamentDetailsPage />,
  [routes[pages.teams]]: <TeamsPage />,
  [routes[pages.teamDetails]]: <TeamDetailPage />,
  [routes[pages.rewards]]: <RewardsPage />,
  [routes[pages.leaderboards]]: <LeaderboardsPage />,
  [routes[pages.leaderboardDetails]]: <LeaderboardDetailsPage />,
  [routes[pages.profile]]: <ProfilePage />,
  [routes[pages.profileEdit]]: <ProfileEditPage />,
  [routes[pages.profileActivities]]: <ProfileActivitiesPage />,
  [routes[pages.tips]]: <AllTipsPage />,
  [routes[pages.admin]]: <AdminPage />,
  [routes[pages.adminMarketplace]]: <AdminMarketplace />,
  [routes[pages.adminMissions]]: <AdminMissions />,
  [routes[pages.adminUsers]]: <AdminUsers />,
  [routes[pages.adminRedemptions]]: <AdminRedemptions />,
};

const App = () => {
  const { isLoading } = useAuth0();
  const { i18n: { language } } = useTranslation();
  useCheckAuth();
  useAbiosWidgetHeight();

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="app">
      <Navigation />

      <Container className="page">
        <Routes>
          {allRoutes.map(route => (
            <Route key={route} path={route} element={Component[route]} />
          ))}
          {Object.values(adminPages).map(route => (
            <Route key={route} path={route} element={Component[route]} />
          ))}
          {links[language].map(item => (
            <Route key={item.key} path={`/${item.key.toLowerCase()}`} element={<Redirect url={item.link} />} />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Container>

      <Footer />

      <DailyLoginWidget />
      <TipsModal />
      <CheckEmailVerifyWidget />

    </div>
  );
};

export default App;
