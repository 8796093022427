import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Image, Placeholder } from 'react-bootstrap';

import EyeIcon from '../../../assets/images/icons/eye-icon.svg';
import ThumbsUpIcon from '../../../assets/images/icons/thumbs-up.svg';
import ThumbsDownIcon from '../../../assets/images/icons/thumbs-down.svg';
import TimerIcon from '../../../assets/images/icons/timer-icon.svg';

const LatestTipsItemPlaceholder = ({ noMargin }) => {
  const { t } = useTranslation();

  return (
    <Card.Body className={cx({ 'mb-2': !noMargin })}>
      <Placeholder className="card-actions mb-2" animation="glow">
        <div className="d-flex text-nowrap">
          <Image src={TimerIcon} alt="" className="me-1" />
          <Placeholder style={{ width: 50 }} />
        </div>
        <div className="d-flex text-nowrap">
          {t('POSTED_BY')}
&nbsp;
          <Placeholder style={{ width: 80 }} />
        </div>
      </Placeholder>
      <Placeholder as={Card.Title} className="mb-2" animation="glow">
        <Placeholder xs={8} />
      </Placeholder>
      <Placeholder as={Card.Text} className="mb-2" animation="glow">
        <Placeholder xs={12} />
        <Placeholder xs={12} />
      </Placeholder>
      <div className="card-buttons">
        <a className="text-secondary">
          <Image src={EyeIcon} alt="" className="me-1" />
          {t('VIEW_MATCH')}
        </a>
        <div className="text-secondary d-flex">
          <div className="btn-like me-1">
            <Image src={ThumbsUpIcon} alt="" />
          </div>
          <div className="btn-like">
            <Image src={ThumbsDownIcon} alt="" />
          </div>
        </div>
      </div>
    </Card.Body>
  );
};

LatestTipsItemPlaceholder.propTypes = {
  noMargin: PropTypes.bool,
};

LatestTipsItemPlaceholder.defaultProps = {
  noMargin: false
};

export { LatestTipsItemPlaceholder };
