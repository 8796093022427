import React, { useCallback, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { userActions, userSelectors } from '../../redux/slices';

import NoEmailImage from '../../assets/images/marketplace/winners/fail.svg';
import SendEmailImage from '../../assets/images/icons/send-mail.svg';

const CheckEmailVerifyWidget = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, logout } = useAuth0();
  const [hasSent, setHasSent] = useState(false);
  const emailVerified = useSelector(userSelectors.selectEmailVerified);

  const handleClose = useCallback(() => {
    dispatch(userActions.setEmailVerified.base(null));
    setHasSent(false);
  }, []);

  const handleLogout = useCallback(() => logout(), []);

  const handleReloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const resendConfirmationLink = useCallback(() => {
    dispatch(userActions.resendConfirmationLink.base());
    setHasSent(true);
  }, []);

  if (emailVerified === null || emailVerified) {
    return null;
  }

  return (
    <Modal
      show
      onHide={handleClose}
      centered
      dialogClassName="max-width-large"
      backdrop="static"
      keyboard={false}
    >
      <div className="d-flex flex-column align-items-center p-5">
        {hasSent ? (
          <>
            <Image src={SendEmailImage} alt="" style={{ height: 300 }} />
            <h3 className="mt-3 mb-0 text-center">{t('CONFIRMATION_EMAIL_SENT', user)}</h3>
          </>
        ) : (
          <>
            <Image src={NoEmailImage} alt="" style={{ height: 300 }} />
            <h3 className="mt-3 mb-0 text-center">{t('EMAIL_NOT_CONFIRMED_TITLE')}</h3>
            <div
              className="mt-3 mb-0 text-center text-white lh-lg"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {t('EMAIL_NOT_CONFIRMED_CONTENT', user)}
            </div>
          </>
        )}
        <div className="d-flex gap-3 align-items-center mt-5">
          {!hasSent && (
            <Button className="btn-midnight text-uppercase" onClick={resendConfirmationLink}>
              {t('RESEND_CONFIRMATION_LINK')}
            </Button>
          )}
          <Button className="btn-midnight text-uppercase" onClick={handleReloadPage}>
            {t('RELOAD')}
          </Button>
          <Button className="btn-midnight text-uppercase" onClick={handleClose}>
            {t('CLOSE')}
          </Button>
          <Button className="btn-midnight text-uppercase" onClick={handleLogout}>
            {t('TOPBAR.PROFILE.LOGOUT')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { CheckEmailVerifyWidget };
