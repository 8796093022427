import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Image, Placeholder, ProgressBar } from 'react-bootstrap';
import { matchesSelectors, seriesSelectors, teamsSelectors } from '../../../redux/slices';

const MyPredictionsItem = ({ data: { serie, prediction, match } }) => {
  const serieData = useSelector((state) => seriesSelectors.selectSerieById(state, serie));
  const serieLoading = useSelector((state) => seriesSelectors.selectIsLoadingMap(state)[serie]);
  const teamsByRosters = useSelector(teamsSelectors.selectTeamsRosters);
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const predictions = useSelector(state => (
    matchesSelectors.selectPredictionById(state, prediction === 'serie' ? serie : match)
  ));

  const seriaObj = useMemo(() => ({
    ...(serieData || {}),
    participants: (serieData?.participants || []).map(part => ({
      ...part,
      team: part.team || teamsByRosters[part.roster.id]
    }))
  }), [serieData, teamsByRosters]);

  const [teamA, teamB] = seriaObj.participants;
  const defaultImage = gamesImagesMap[prediction.game];

  const maxProgress = ((predictions?.teamA?.predictors || 0) + (predictions?.teamB?.predictors || 0)) || 1;
  const teamAProgress = parseInt((((predictions?.teamA?.team === teamA?.team?.id
    ? predictions?.teamA?.predictors
    : predictions?.teamB?.predictors) || 0) / maxProgress) * 100, 10);
  const teamBProgress = parseInt((((predictions?.teamA?.team === teamB?.team?.id
    ? predictions?.teamA?.predictors
    : predictions?.teamB?.predictors) || 0) / maxProgress) * 100, 10);

  if (!serieData && serieLoading) {
    return (
      <Placeholder className="mb-3" />
    );
  }

  return (
    <div className="mb-3">
      <div className="profile-prediction-row">
        <div className="team-info w-50">
          <Image src={(teamA?.team?.images || [])[0]?.url || defaultImage} />
          <div>{teamA?.team?.abbreviation || teamA?.team?.name}</div>
        </div>
        <div className="custom-progress inline flex-grow-1">
          <ProgressBar now={teamAProgress} />
          <span className="fs-medium">{`${teamAProgress}%`}</span>
        </div>
      </div>
      <div className="profile-prediction-row">
        <div className="team-info w-50">
          <Image src={(teamB?.team?.images || [])[0]?.url || defaultImage} />
          <div>{teamB?.team?.abbreviation || teamB?.team?.name}</div>
        </div>
        <div className="custom-progress inline w-50">
          <ProgressBar now={teamBProgress} />
          <span className="fs-medium">{`${teamBProgress}%`}</span>
        </div>
      </div>
    </div>
  );
};

MyPredictionsItem.propTypes = {
  data: PropTypes.object.isRequired
};

export { MyPredictionsItem };
