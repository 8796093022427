import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useScroll } from '../../../hooks';
import { matchesSelectors, tipsActions, tipsSelectors } from '../../../redux/slices';
import { ALL_FILTER, TIPS_FILTERS, TIPS_PAGE_SIZE } from '../../../data/constants';

import { LoadingSpinner } from '../../LoadingSpinner';
import { TipsItem } from '../TipsCards';
import { TipCreation } from '../TipCreation';
import { GameFilter } from '../../filters';

const TipsList = ({ serieId, title, withGamesFilter, withLink, withUsername, withUsernameDate }) => {
  const dispatch = useDispatch();
  const { search, hash } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(search);

  const [filter, setFilter] = useState(TIPS_FILTERS.UPVOTE);
  const [filterGame, setFilterGame] = useState(ALL_FILTER);
  const [isCreating, setIsCreating] = useState(searchParams.get('mode') === 'new');

  const games = useSelector(matchesSelectors.selectGames);
  const { isLoading, data, total, currentPage, hasCreated } = useSelector(tipsSelectors.selectTips);

  const handleLoadData = useCallback(payload => dispatch(tipsActions.loadTips.base(payload)), []);

  const handleLoadTips = useCallback(() => {
    handleLoadData({
      serie: serieId,
      filter,
      game: filterGame === ALL_FILTER ? undefined : filterGame,
      page: currentPage + 1,
      limit: TIPS_PAGE_SIZE
    });
  }, [currentPage, filter, filterGame, serieId]);

  useEffect(() => {
    handleLoadData({
      serie: serieId,
      filter,
      game: filterGame === ALL_FILTER ? undefined : filterGame,
      page: 1,
      limit: TIPS_PAGE_SIZE
    });
    return () => dispatch(tipsActions.clearTips.base());
  }, [filter, filterGame, serieId]);

  useScroll(() => {
    if (!isLoading && currentPage * TIPS_PAGE_SIZE < total) {
      handleLoadTips();
    }
  }, [isLoading, total, currentPage]);

  return (
    <Accordion defaultActiveKey="tips-list" flush>
      <Accordion.Item eventKey="tips-list">
        <Accordion.Header className="d-lg-none">
          {title}
        </Accordion.Header>
        <Accordion.Body>
          <Row className="show-grid mb-20">
            <Col xs={12} className="mb-20">
              <h3 className="mb-0 d-none d-lg-block text-nowrap">{title}</h3>
            </Col>
            <Col className="d-flex justify-content-between">
              {withGamesFilter && (
                <GameFilter
                  options={games}
                  selection={filterGame === ALL_FILTER ? [] : [filterGame]}
                  setSelection={val => setFilterGame(`${val}` === '' ? ALL_FILTER : val[val.length - 1])}
                />
              )}
              <div className="d-flex gap-2">
                {Object.keys(TIPS_FILTERS).map(k => (
                  <Button
                    className={cx('btn-midnight', 'text-capitalize', { selected: filter === TIPS_FILTERS[k] })}
                    onClick={() => {
                      setIsCreating(false);
                      navigate({ search: '', hash }, { replace: true });
                      setFilter(TIPS_FILTERS[k]);
                    }}
                  >
                    {t(`TIPS.FILTERS.${k}`)}
                  </Button>
                ))}
              </div>
              {!hasCreated && serieId && (
                <Button
                  className={cx('btn-midnight', 'text-uppercase', { selected: isCreating })}
                  onClick={() => {
                    setIsCreating(true);
                    navigate({ search: '', hash }, { replace: true });
                  }}
                >
                  {t('TIPS.CREATE_TIP')}
                </Button>
              )}
            </Col>
          </Row>
          {isCreating && serieId ? (
            <TipCreation
              serieId={serieId}
              onClose={() => {
                setIsCreating(false);
                navigate({ search: '', hash }, { replace: true });
              }}
            />
          ) : (
            <Row className="mb-20">
              {isLoading && (
                <LoadingSpinner />
              )}
              {!isLoading && data.length === 0 && (
                <Col xs={12} className="text-white text-center">
                  {t('TIPS.NO_RESULTS')}
                </Col>
              )}
              {data.map(item => (
                <Col xs={12} md={6} key={item._id} className="mb-4">
                  <TipsItem
                    withUsername={withUsername}
                    withUsernameDate={withUsernameDate}
                    withLink={withLink}
                    data={item}
                  />
                </Col>
              ))}
              {data.length < total && (
                <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadTips}>
                  <div className="p-2 cursor-pointer">
                    {t('LOAD_MORE')}
                  </div>
                </div>
              )}
            </Row>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

TipsList.propTypes = {
  title: PropTypes.string.isRequired,
  serieId: PropTypes.string,
  withGamesFilter: PropTypes.bool,
  withLink: PropTypes.bool,
  withUsername: PropTypes.bool,
  withUsernameDate: PropTypes.bool,
};

TipsList.defaultProps = {
  serieId: undefined,
  withGamesFilter: undefined,
  withLink: undefined,
  withUsername: undefined,
  withUsernameDate: undefined,
};

export { TipsList };
