import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '../components/LoadingSpinner';

const Redirect = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return <LoadingSpinner />;
};

Redirect.propTypes = {
  url: PropTypes.string.isRequired
};

export { Redirect };
