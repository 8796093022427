import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useQueryFilter } from '../../../hooks';
import { FILTERS, PROFILE_ACTIVITIES } from '../../../data/constants';

import '../styles.scss';

const ProfileActivities = ({ selection, setSelection, className, ...props }) => {
  const { t } = useTranslation();

  const { changeFilter } = useQueryFilter(FILTERS.PROFILE_ACTIVITIES, setSelection, Object.values(PROFILE_ACTIVITIES));

  const handleChangeFilter = useCallback(val => () => {
    setSelection(val);
    changeFilter(val);
  }, []);

  return (
    <div className={cx('filters', className)} {...props}>
      {Object.keys(PROFILE_ACTIVITIES).map((key) => (
        <Button
          key={key}
          className={cx('btn-midnight', { selected: selection === PROFILE_ACTIVITIES[key] })}
          onClick={handleChangeFilter(PROFILE_ACTIVITIES[key])}
        >
          {t(`PROFILE.ACTIVITIES.FILTERS.${key}`)}
        </Button>
      ))}
    </div>
  );
};

ProfileActivities.propTypes = {
  selection: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ProfileActivities.defaultProps = {
  className: undefined,
};

export { ProfileActivities };
