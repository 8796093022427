import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userActions, userSelectors } from '../../../redux/slices';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MyPredictionsItem } from './MyPredictionsItem';
import { ALL_FILTER } from '../../../data/constants';

const MyPredictionsList = ({ as, username, displayLimit, game, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const Component = as || Fragment;

  const { isLoading, data, latestData, total } = useSelector(userSelectors.selectProfilePredictions);

  const handleLoadData = useCallback(({ clear }) => {
    const newPage = clear ? 1 : page + 1;
    dispatch(userActions.loadPredictionsForProfile.base({
      username,
      page: newPage,
      game: game === ALL_FILTER ? undefined : game
    }));
    setPage(newPage);
  }, [username, page, game]);

  useEffect(() => {
    handleLoadData({ clear: true });
    return () => dispatch(userActions.clearPredictionsForProfile.base());
  }, [game]);

  const filterData = useMemo(() => (
    displayLimit ? [...latestData].splice(0, displayLimit) : data
  ), [displayLimit, data, latestData]);

  return (
    <>
      {!filterData.length && !isLoading && (
        <div className="text-white text-center">
          {t('PREDICTIONS.NO_RESULTS')}
        </div>
      )}
      {filterData.map(item => (
        <Component key={item._id} {...props}>
          <MyPredictionsItem data={item} key={data._id} />
        </Component>
      ))}
      {!displayLimit && total > data.length && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {(displayLimit ? !latestData.length : true) && isLoading && (
        <LoadingSpinner />
      )}
    </>
  );
};

MyPredictionsList.propTypes = {
  as: PropTypes.node,
  username: PropTypes.string.isRequired,
  displayLimit: PropTypes.number,
  game: PropTypes.number,
};

MyPredictionsList.defaultProps = {
  as: undefined,
  displayLimit: undefined,
  game: undefined,
};

export { MyPredictionsList };
