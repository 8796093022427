import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import moment from 'moment';

import EyeIcon from '../../../assets/images/icons/eye-icon.svg';
import ThumbsUpIcon from '../../../assets/images/icons/thumbs-up.svg';
import ThumbsDownIcon from '../../../assets/images/icons/thumbs-down.svg';
import TimerIcon from '../../../assets/images/icons/timer-icon.svg';

import { TipsEditor } from '../TipsEditor';
import { LatestTipsItemPlaceholder } from './LatestTipsItemPlaceholder';

import { seriesSelectors, tipsActions, tournamentsSelectors, userSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';

const LatestTipsItem = ({ noMargin, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { _id: id, uid, username, message, serie, postedAt, createdAt, likes, dislikes, isLiked } = data;

  const userData = useSelector(userSelectors.selectUserDetails);
  const serieData = useSelector((state) => seriesSelectors.selectSerieById(state, serie));
  const tournamentData = useSelector(state => (
    tournamentsSelectors.selectTournamentById(state, serieData?.tournament?.id)
  ));
  const isLoading = useSelector((state) => seriesSelectors.selectIsLoadingMap(state)[serie]);

  const canEdit = userData?.uid === uid;

  const handleLikeDislikeClick = useCallback((key) => () => {
    if (userData) {
      dispatch(tipsActions.likeDislikeTip.base({ id, key }));
    }
  }, [userData]);

  if (!serieData && !isLoading) {
    return null;
  }

  if (!serieData && isLoading) {
    return (
      <LatestTipsItemPlaceholder noMargin={noMargin} />
    );
  }

  return (
    <Card.Body className={cx({ 'mb-2': !noMargin })}>
      <div className="card-actions mb-2 fs-smallest">
        <div className="d-flex align-items-center text-nowrap">
          <Image src={TimerIcon} alt="" className="me-1" style={{ height: 12 }} />
          {moment(createdAt || postedAt).from(moment())}
        </div>
        <div className="d-flex align-items-center text-nowrap">
          {t('POSTED_BY')}
&nbsp;
          <Link to={`/profile/${username}`}>
            {username}
          </Link>
        </div>
      </div>
      <Card.Title className="mb-2">{getTournamentTitle(tournamentData?.title)}</Card.Title>
      <Card.Text className="mb-2">{message}</Card.Text>
      <div className="card-buttons">
        <Link to={`/matches/${serie}`} className="text-secondary">
          <Image src={EyeIcon} alt="" className="me-1" />
          {t('VIEW_MATCH')}
        </Link>
        <div className="d-flex fs-small">
          <div
            className={cx('btn-like', 'me-1', { 'cursor-none': !userData, active: isLiked === true })}
            onClick={handleLikeDislikeClick('likes')}
          >
            <Image src={ThumbsUpIcon} alt="" />
            {likes > 0 ? likes : null}
          </div>
          <div
            className={cx('btn-like', { 'cursor-none': !userData, active: isLiked === false })}
            onClick={handleLikeDislikeClick('dislikes')}
          >
            <Image src={ThumbsDownIcon} alt="" />
            {dislikes > 0 ? dislikes : null}
          </div>
          {canEdit && (
            <TipsEditor
              align="end"
              data={{ ...data, tournamentTitle: getTournamentTitle(serieData?.tournament?.title) }}
            />
          )}
        </div>
      </div>
    </Card.Body>
  );
};

LatestTipsItem.propTypes = {
  noMargin: PropTypes.bool,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    serie: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    likes: PropTypes.number.isRequired,
    dislikes: PropTypes.number.isRequired,
    isLiked: PropTypes.bool,
    createdAt: PropTypes.string,
    postedAt: PropTypes.string,
  }).isRequired
};

LatestTipsItem.defaultProps = {
  noMargin: false
};

export { LatestTipsItem };
