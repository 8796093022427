import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ProfileActivities } from '../../Profile';

const ActivitiesModal = ({ onClose, uid }) => {
  const { t } = useTranslation();
  return (
    <Modal show onHide={onClose} dialogClassName="max-width-large">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('PROFILE.ACTIVITIES.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-height-75">
        <ProfileActivities uid={uid} />
      </Modal.Body>
      <Modal.Footer className="mt-20 d-flex justify-content-end gap-2">
        <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

ActivitiesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};

export { ActivitiesModal };
