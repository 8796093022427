import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Col, Image, Row } from 'react-bootstrap';

import { useScroll } from '../../../../hooks';
import { userActions, userSelectors } from '../../../../redux/slices';
import { LoadingSpinner } from '../../../LoadingSpinner';

import '../../LeadersList/styles.scss';
import { pages, routes } from '../../../../data/menu';

const LeadersWithPrizeList = ({ className }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const { isLoading, total, data } = useSelector(userSelectors.selectLeaderboardEventLeaders);

  const handleLoadData = useCallback(() => {
    dispatch(userActions.loadLeaderboardEventLeaders.base({ page }));
    setPage(page + 1);
  }, [page]);

  const handleOpenDetails = useCallback(({ username }) => () => {
    navigate(routes[pages.profile].replace(':username', username));
  }, []);

  useEffect(() => {
    handleLoadData();
  }, []);

  useScroll(() => {
    if (data?.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data?.length, total, isLoading]);

  return (
    <Accordion defaultActiveKey="leaderboards-with-prize" flush className={className}>
      <Accordion.Item eventKey="leaderboards-with-prize">
        <Accordion.Header className="d-lg-none mt-20">
          {t('LEADERBOARDS.EVENT.LEADERBOARD')}
        </Accordion.Header>
        <Accordion.Body className="show-grid mb-20">
          <h3 className="mb-20 d-none d-lg-block text-nowrap">{t('LEADERBOARDS.EVENT.LEADERBOARD')}</h3>
          {isLoading && (
            <LoadingSpinner />
          )}
          <div className="leaderboards">
            <Row className="header">
              <Col xs={2}>{t('LEADERBOARDS.TABLE.RANK')}</Col>
              <Col xs={4}>{t('LEADERBOARDS.TABLE.USERNAME')}</Col>
              <Col xs={3}>{t('LEADERBOARDS.TABLE.POINTS')}</Col>
              <Col xs={3}>{t('LEADERBOARDS.TABLE.PERCENT_OF_PRIZE')}</Col>
            </Row>
            {data?.map((item, ind) => (
              <Row key={item.uid}>
                <Col xs={2}>
                  <div className={cx('rank', { 'rank-1': ind === 0, 'rank-2': ind === 1, 'rank-3': ind === 2 })}>
                    {`#${ind + 1}`}
                  </div>
                </Col>
                <Col xs={4}>
                  <Image className="me-3" src={item.avatar} alt="" style={{ height: 40, width: 40, borderRadius: 4 }} />
                  {item.username}
                </Col>
                <Col xs={3}>{item.diamonds}</Col>
                <Col xs={3} className="d-flex justify-content-between">
                  {`${item.percent || 0}%`}
                  <HiChevronRight className="more-btn" onClick={handleOpenDetails(item)} />
                </Col>
              </Row>
            ))}
            {data?.length < total && !isLoading && (
              <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
                <div className="p-2 cursor-pointer">
                  {t('LOAD_MORE')}
                </div>
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

LeadersWithPrizeList.propTypes = {
  className: PropTypes.string
};

LeadersWithPrizeList.defaultProps = {
  className: useNavigate
};

export { LeadersWithPrizeList };
