import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tipsActions, tipsSelectors } from '../../../redux/slices';
import { TIPS_MODAL } from '../../../data/constants';

import { TipsEditModal } from './TipsEditModal';
import { TipsDeleteModal } from './TipsDeleteModal';

const TipsModal = () => {
  const dispatch = useDispatch();
  const { mode, data } = useSelector(tipsSelectors.selectTipModal);

  const handleClose = useCallback(() => {
    dispatch(tipsActions.setTipModal.base({ mode: null, data: null }));
  }, []);

  if (mode === TIPS_MODAL.EDIT) {
    return <TipsEditModal data={data} onClose={handleClose} />;
  }

  if (mode === TIPS_MODAL.DELETE) {
    return <TipsDeleteModal data={data} onClose={handleClose} />;
  }

  return null;
};

export { TipsModal };
