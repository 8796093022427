import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row, Tab, Tabs } from 'react-bootstrap';

import { matchesSelectors, userActions, userSelectors } from '../../redux/slices';

import { MainSection } from './MainSection';
import { StatCard, GameStatCard } from './Statisctics';
import { MyTipsList, MyLatestTips } from './MyLatestTips';
import { MyPredictionsList, MyLatestPredictions } from './MyLatestPredictions';
import { Redemptions } from '../admin';
import { GameFilter } from '../filters';

import ChartIcon from '../../assets/images/icons/chart.svg';
import CupFulfillIcon from '../../assets/images/icons/cup-fulfill.svg';
import CupIcon from '../../assets/images/icons/cup.svg';
import MedalIcon from '../../assets/images/icons/medal.svg';
import StarIcon from '../../assets/images/icons/star.svg';
import SmsIcon from '../../assets/images/icons/sms.svg';

import { useGames } from '../../hooks';
import { pages, routes } from '../../data/menu';
import { ALL_FILTER, PROFILE_SECTION_TABS as TABS } from '../../data/constants';

import './styles.scss';

const TabsIcon = {
  [TABS.TIPS_HISTORY]: SmsIcon,
  [TABS.PREDICTION_HISTORY]: ChartIcon,
  [TABS.REDEMPTIONS]: CupIcon
};

const Profile = ({ username }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const tabsListRef = useRef();

  const [page, setPage] = useState();
  const [filterGame, setFilterGame] = useState(ALL_FILTER);

  useGames();

  const currentUser = useSelector(userSelectors.selectUserDetails);
  const profile = useSelector(userSelectors.selectUserProfile);
  const games = useSelector(matchesSelectors.selectGames);
  const gamesImages = useSelector(matchesSelectors.selectGamesImages);

  const isCurrentUser = currentUser?.username === username;

  useEffect(() => {
    dispatch(userActions.loadUserProfile.base({ username }));
    return () => dispatch(userActions.clearUserProfile.base());
  }, [username]);

  const handleChangePage = useCallback(page => {
    navigate({ hash: page }, { replace: true });
  }, [hash]);

  const handleOpenEdit = useCallback(() => {
    navigate(routes[pages.profileEdit].replace(':username', username));
  }, [username]);

  const handleOpenActivities = useCallback(() => {
    navigate(routes[pages.profileActivities].replace(':username', username));
  }, [username]);

  useEffect(() => {
    const page = hash.replace('#', '');
    if (Object.values(TABS)
      .includes(page)) {
      setPage(page);
    } else {
      navigate({ hash: TABS.TIPS_HISTORY }, { replace: true });
    }
  }, [hash]);

  const handleScrollDown = useCallback(tab => () => {
    setPage(tab);
    if (tabsListRef.current) {
      tabsListRef.current.scrollIntoView();
    }
  }, [tabsListRef]);

  const statsData = useMemo(() => {
    if (!profile?.data?.stats?.games) return null;
    const predictions = profile.data.stats.games.reduce((sum, n) => sum + n.predictions, 0);
    const predictionsWon = profile.data.stats.games.reduce((sum, n) => sum + n.predictionsWon, 0);
    const predictionsLost = profile.data.stats.games.reduce((sum, n) => sum + n.predictionsLost, 0);

    return {
      predictions,
      predictionsWon,
      winRate: parseInt((predictionsWon / ((predictionsWon + predictionsLost) || 1)) * 100, 10),
      leaderboardPlace: profile.data.leaderboardPlace || 'NaN',
      byGames: profile.data.stats.games.reduce((map, n) => ({
        ...map,
        [n.game]: {
          predictions: n.predictions || (n.predictionsWon + n.predictionsLost) || 0,
          winRate: parseInt(((n.predictionsWon || 0) / ((n.predictionsWon + n.predictionsLost) || 1)) * 100, 10)
        }
      }), {})
    };
  }, [profile?.data?.stats?.games]);

  return (
    <>
      <MainSection
        className="mt-20 mb-20"
        isCurrentUser={isCurrentUser}
        data={profile?.data}
        onOpenEditProfile={handleOpenEdit}
        onOpenActivities={handleOpenActivities}
      />
      <Row className="mt-20 mb-20" style={{ rowGap: 20 }}>
        <Col xs={6} xxl={3}>
          <StatCard
            icon={<Image src={ChartIcon} alt="" />}
            title={t('PROFILE.STATS.PREDICTIONS_MADE')}
            statistic={statsData?.predictions}
          />
        </Col>
        <Col xs={6} xxl={3}>
          <StatCard
            icon={<Image src={CupFulfillIcon} alt="" />}
            title={t('PROFILE.STATS.PREDICTIONS_WON')}
            statistic={statsData?.predictionsWon}
          />
        </Col>
        <Col xs={6} xxl={3}>
          <StatCard
            icon={<Image src={StarIcon} alt="" />}
            title={t('PROFILE.STATS.WIN_RATE')}
            statistic={`${statsData?.winRate}%`}
          />
        </Col>
        <Col xs={6} xxl={3}>
          <StatCard
            icon={<Image src={MedalIcon} alt="" />}
            title={t('PROFILE.STATS.LEADERBOARD_PLACE')}
            statistic={`#${statsData?.leaderboardPlace}`}
          />
        </Col>

      </Row>
      <Row className="mb-20" style={{ rowGap: 20 }}>
        <Col xs={12} md={6}>
          <MyLatestTips
            username={username}
            onDownScroll={handleScrollDown(TABS.TIPS_HISTORY)}
          />
        </Col>
        <Col xs={12} md={6}>
          <MyLatestPredictions
            username={username}
            onDownScroll={handleScrollDown(TABS.PREDICTION_HISTORY)}
          />
        </Col>
      </Row>
      <Row className="mt-20 mb-20" style={{ rowGap: 20 }}>
        {Object.keys(gamesImages)
          .map(gameId => (
            <Col xs={12} md={6} key={`game-${gameId}`}>
              <GameStatCard
                gameId={gameId}
                image={gamesImages[gameId]}
                stats={(statsData?.byGames || {})[gameId]}
              />
            </Col>
          ))}
      </Row>
      <div ref={tabsListRef} />
      <Tabs className="mb-20 mt-20" fill activeKey={page} onSelect={handleChangePage}>
        {Object.keys(TABS)
          .filter(tab => isCurrentUser || TABS[tab] !== TABS.REDEMPTIONS)
          .map(tab => (
            <Tab
              key={tab}
              eventKey={TABS[tab]}
              title={(
                <>
                  <Image src={TabsIcon[TABS[tab]]} alt="" className="me-2 text-secondary" style={{ width: 20 }} />
                  {t(`PROFILE.TABS.${tab}`)}
                </>
              )}
            />
          ))}
      </Tabs>
      {![TABS.REDEMPTIONS].includes(page) && (
        <div className="mt-20 mb-20">
          <GameFilter
            options={games}
            selection={filterGame === ALL_FILTER ? [] : [filterGame]}
            setSelection={val => setFilterGame(`${val}` === '' ? ALL_FILTER : val[val.length - 1])}
          />
        </div>
      )}
      <Row className="mt-20 mb-20">
        {TABS.TIPS_HISTORY === page && (
          <MyTipsList className="mb-4" as={Col} xs={12} md={6} username={username} game={filterGame} />
        )}
        {TABS.PREDICTION_HISTORY === page && (
          <MyPredictionsList as={Col} xs={12} md={6} username={username} game={filterGame} />
        )}
        {TABS.REDEMPTIONS === page && currentUser && (
          <Col xs={12}>
            <Redemptions uid={currentUser.uid} />
          </Col>
        )}
      </Row>
    </>
  );
};

Profile.propTypes = {
  username: PropTypes.string.isRequired
};

export { Profile };
