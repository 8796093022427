import React from 'react';
import { Placeholder } from 'react-bootstrap';

import './styles.scss';

const MatchTeamRowPlaceholder = () => (
  <Placeholder className="team-row d-flex gap-3 align-items-center" animation="glow">
    <Placeholder className="img" style={{ borderRadius: '50%' }} />
    <div className="d-flex justify-content-between w-100">
      <Placeholder style={{ width: 150 }} />
      <Placeholder style={{ width: 50 }} />
    </div>
  </Placeholder>
);

export { MatchTeamRowPlaceholder };
