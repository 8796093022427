import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Image, Placeholder } from 'react-bootstrap';

import { teamsSelectors } from '../../../redux/slices';

const PlayerInfo = ({ id, hasMargin }) => {
  const player = useSelector(state => teamsSelectors.selectPlayer(state, id));

  if (!player) {
    return (
      <Placeholder
        as={Card.Body}
        animation="glow"
        className={cx('d-flex gap-3 align-items-center p-3', { 'mb-2': hasMargin })}
      >
        <Placeholder style={{ width: 28 }} />
        <Placeholder style={{ width: '100%' }} />
      </Placeholder>
    );
  }

  return (
    <Card.Body className={cx('d-flex gap-3 align-items-center p-3', { 'mb-2': hasMargin })}>
      <Image
        src={(player?.region?.country?.images || [])[0]?.url}
        alt={player?.region?.country?.abbreviation}
        style={{ height: 20, width: 28 }}
      />
      <div>
        {player?.nick_name}
        {player?.first_name && player?.last_name ? ` (${player?.first_name} ${player?.last_name})` : ''}
      </div>
    </Card.Body>
  );
};

PlayerInfo.propTypes = {
  id: PropTypes.number.isRequired,
  hasMargin: PropTypes.bool.isRequired,
};

export { PlayerInfo };
