import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Image, ProgressBar, ListGroup } from 'react-bootstrap';
import { AiOutlineClockCircle } from 'react-icons/ai';
import moment from 'moment';

import { useDurationFromNow } from '../../../../hooks';
import { missionsActions } from '../../../../redux/slices';

import Diamond from '../../../../assets/images/diamonds/diamond.svg';
import DiamondWhite from '../../../../assets/images/diamonds/diamond-white.svg';
import { pages, routes } from '../../../../data/menu';
import { REWARDS_TABS, MISSION_CONDITIONS } from '../../../../data/constants';

const MissionItem = ({ highlight, matches, data: {
  _id, title, description, type, price, endAt, conditions, userCompletion
} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n: { language } } = useTranslation();

  const duration = useDurationFromNow(endAt || moment().endOf('day').toISOString());

  const handleClaimMission = useCallback((id) => () => {
    dispatch(missionsActions.claimMission.base({ id }));
  }, [_id]);

  const progress = conditions.reduce((sum, next) => (
    sum + ((next?.userCompletion?.isClaimed || next?.missionRef?.userCompletion?.isClaimed) ? next.progress : 0)
  ), 0);

  const handleGoToMissions = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`${routes[pages.rewards]}?mission=${_id}#${REWARDS_TABS.MISSIONS}`, { replace: true });
  }, [_id]);

  const handleGoTo = useCallback((id, type, game) => event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(missionsActions.startMission.base({ id }));
    switch (type) {
      case MISSION_CONDITIONS.VOTE_LIVE_MATCH:
        navigate(`${routes[pages.matches]}?games=${game}&live_only=true`);
        break;
      case MISSION_CONDITIONS.VOTE_MATCH:
        navigate(`${routes[pages.matches]}?games=${game}`);
        break;
      case MISSION_CONDITIONS.VOTE_LIVE_MAP: {
        const match = matches.live?.find(m => m.game.id === game);
        if (match) {
          navigate(routes[pages.matchDetails].replace(':matchId', match.id));
        } else {
          navigate(`${routes[pages.matches]}?games=${game}&live_only=true`);
        }
        break;
      }
      case MISSION_CONDITIONS.VOTE_MAP: {
        const match = matches.upcoming?.find(m => m.game.id === game);
        if (match) {
          navigate(routes[pages.matchDetails].replace(':matchId', match.id));
        } else {
          navigate(`${routes[pages.matches]}?games=${game}`);
        }
        break;
      }
      default:
        navigate(routes[pages.matches]);
    }
  }, [_id, matches]);

  return (
    <Card className="large-card h-100">
      <Card.Body
        className={cx({
          focus: highlight,
          active: userCompletion?.readyToClaim,
          disabled: userCompletion?.isClaimed
        })}
        onClick={handleGoToMissions}
      >
        <div className="d-flex gap-3 justify-content-between">
          <div className="flex-grow-1">
            <Card.Text className="mb-2 text-uppercase">{type}</Card.Text>
            <Card.Title className="mb-2">{title[language]}</Card.Title>
            <Card.Text className="mb-2">{description[language]}</Card.Text>
            <Card.Text className="d-flex align-items-center gap-2">
              <AiOutlineClockCircle style={{ height: 15, width: 15 }} />
              <span>{duration}</span>
            </Card.Text>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {userCompletion?.readyToClaim ? (
              <Button
                className="d-flex align-items-center flex-column gap-1"
                variant="outline-primary"
                onClick={handleClaimMission(_id)}
              >
                <span className="text-uppercase fw-bold text-nowrap fs-small">
                  {t('REWARDS.CLAIM')}
                </span>
                <div className="d-flex align-items-center gap-1">
                  <Image src={Diamond} alt="" style={{ height: 13 }} />
                  <span>{price}</span>
                </div>
              </Button>
            ) : (
              <div className="d-flex align-items-end justify-content-center flex-column gap-2">
                <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center">
                  <span>{`${userCompletion?.isClaimed ? t('REWARDS.CLAIMED') : t('REWARDS.TEXT')}: `}</span>
                  <div className="d-flex align-items-center gap-1 text-white">
                    <Image src={DiamondWhite} alt="" style={{ height: 15 }} />
                    <span>{price}</span>
                  </div>
                </div>
                {conditions.length === 1 && (
                  <Button
                    className="btn-midnight p-2 pe-4 ps-4 text-nowrap"
                    size="sm"
                    onClick={handleGoTo(_id, conditions[0].type, conditions[0].game)}
                  >
                    {t('REWARDS.GO_TO')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        {conditions.length > 1 && (
          <>
            <div className="card-divider full" />
            <div className="card-progress inline mb-2">
              <span>{`${progress}/100`}</span>
              <ProgressBar now={parseInt((progress / 100) * 100, 10)} />
            </div>
            <div className="card-divider full" />
            <ListGroup as="ol" numbered style={{ marginBottom: -10 }}>
              {conditions.map(item => (
                <li
                  key={item._id}
                  className={cx('d-flex justify-content-between align-items-start mb-1', {
                    'opacity-25': (item?.userCompletion?.isClaimed || item?.missionRef?.userCompletion?.isClaimed)
                      && !userCompletion?.isClaimed,
                    'pe-none': (item?.userCompletion?.isClaimed || item?.missionRef?.userCompletion?.isClaimed)
                      && !userCompletion?.isClaimed,
                  })}
                >
                  <div className="ms-2 me-auto">
                    <div className="fs-medium">
                      {item?.missionRef?.title?.[language] || item.title?.[language]}
                    </div>
                    <div className="fs-smallest text-secondary">
                      {item?.missionRef?.description?.[language] || item.description?.[language]}
                    </div>
                    <div className="fs-smallest text-secondary">
                      {`${t('MISSIONS.REPEAT')}: ${item?.missionRef?.conditions?.[0]?.amount || item.amount}`}
                    </div>
                  </div>
                  {(
                    item?.userCompletion?.readyToClaim
                    || item?.missionRef?.userCompletion?.readyToClaim
                    || item?.userCompletion?.isClaimed
                    || item?.missionRef?.userCompletion?.isClaimed
                  ) ? (
                    <Button
                      className="d-flex align-items-center gap-1"
                      variant="outline-primary"
                      onClick={handleClaimMission(item?.missionRef?._id || item._id)}
                    >
                      <span className="text-uppercase fw-bold text-nowrap fs-small">
                        {(item?.userCompletion?.isClaimed || item?.missionRef?.userCompletion?.isClaimed) ? (
                          t('REWARDS.CLAIMED')
                        ) : (
                          t('REWARDS.CLAIM')
                        )}
                      </span>
                      <Image src={Diamond} alt="" style={{ height: 13 }} />
                      <span className="fs-small">{item?.missionRef?.price || item.price}</span>
                      <span className="fs-smallest">{`(+${item.progress}%)`}</span>
                    </Button>
                    ) : (
                      <div className="d-flex gap-3 align-items-center">
                        {[MISSION_CONDITIONS.VOTE_MATCH, MISSION_CONDITIONS.VOTE_MAP].includes(
                          item.missionRef?.conditions?.[0]?.type || item.type
                        ) && (
                          <Button
                            className="btn-midnight p-1 pe-3 ps-3 text-nowrap"
                            size="sm"
                            onClick={handleGoTo(
                              item.missionRef?._id || item._id,
                              item.missionRef?.conditions?.[0]?.type || item.type,
                              item.missionRef?.conditions?.[0]?.game || item.game
                            )}
                          >
                            {t('REWARDS.GO_TO')}
                          </Button>
                        )}
                        <div className="d-flex align-items-center gap-1 text-white">
                          <Image src={DiamondWhite} alt="" style={{ height: 10 }} />
                          <span className="fs-small">{item?.missionRef?.price || item.price}</span>
                          <span className="fs-smallest">{`(+${item.progress}%)`}</span>
                        </div>
                      </div>
                    )}
                </li>
              ))}
            </ListGroup>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

MissionItem.propTypes = {
  highlight: PropTypes.bool.isRequired,
  matches: PropTypes.shape({
    live: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      game: PropTypes.shape({ id: PropTypes.number })
    })),
    upcoming: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      game: PropTypes.shape({ id: PropTypes.number })
    })),
  }).isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    endAt: PropTypes.string,
    conditions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      game: PropTypes.number,
      amount: PropTypes.number,
      progress: PropTypes.number,
      missionRef: PropTypes.object,
    })).isRequired,
    userCompletion: PropTypes.shape({
      isClaimed: PropTypes.bool,
      readyToClaim: PropTypes.bool,
    })
  }).isRequired
};

export { MissionItem };
