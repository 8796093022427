import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/users`;

export const getUser = () => request.get(`${api}/user`).then(res => res.data?.success?.data);

export const sendConfirmationEmail = () => request.post(`${api}/user/send-confirmation-email`)
  .then(res => res.data?.success?.data);

export const getProfile = username => (
  request.get(`${api}/profile/${username}`).then(res => res.data?.success?.data)
);

export const updateProfile = (id, data) => (
  request.put(`${api}/user/${id}`, data).then(res => res.data?.success?.data)
);

export const deleteProfile = (id) => (
  request.delete(`${api}/user/${id}`).then(res => res.data?.success?.data)
);

export const resetPassword = (id) => (
  request.post(`${api}/user/${id}/reset-password`).then(res => res.data?.success?.data)
);

export const searchUsers = (params) => (
  request.get(`${api}/search`, { params }).then(res => res.data?.success?.data)
);

export const blockUser = (id) => (
  request.post(`${api}/user/${id}/block`).then(res => res.data?.success?.data)
);

export const unblockUser = (id) => (
  request.post(`${api}/user/${id}/unblock`).then(res => res.data?.success?.data)
);

export const sendFollowUser = username => (
  request.post(`${api}/follow/${username}`).then(res => res.data?.success?.data)
);

export const sendUnFollowUser = username => (
  request.post(`${api}/unfollow/${username}`).then(res => res.data?.success?.data)
);

export const sendRemoveFollower = username => (
  request.delete(`${api}/remove-follower/${username}`).then(res => res.data?.success?.data)
);

export const getFollowers = username => (
  request.get(`${api}/followers/${username}`).then(res => res.data?.success?.data)
);

export const getFollowings = username => (
  request.get(`${api}/followings/${username}`).then(res => res.data?.success?.data)
);
