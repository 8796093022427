import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import AccountEmpty from '../../../../assets/images/icons/account-empty.svg';

import '../styles.scss';

const LeadersRace = ({ className, maxScore, items }) => (
  <div className={cx('leaders-race', className)}>
    {items.map((item, ind) => (
      <div className="race-row" key={item.uid}>
        <div className="user">
          <div className="place">{ind + 1}</div>
          <Image src={item.avatar || AccountEmpty} alt="" />
          <div className="info">
            <div className="text-white text-nowrap">{item.username}</div>
            <div>{parseInt(item.score, 10).toLocaleString()}</div>
          </div>
        </div>
        <div className="progress">
          <div className="progress-bar" style={{ width: `${(item.score / maxScore) * 100}%` }} />
        </div>
      </div>
    ))}
  </div>
);

LeadersRace.propTypes = {
  className: PropTypes.string,
  maxScore: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string,
    avatar: PropTypes.string,
    username: PropTypes.string,
    score: PropTypes.number
  })).isRequired
};

LeadersRace.defaultProps = {
  className: undefined
};

export { LeadersRace };
