import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { BsCalendar } from 'react-icons/bs';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import './styles.scss';

import { useQueryFilter } from '../../../hooks';
import { FILTERS } from '../../../data/constants';

const DateFilter = ({ value, onChange }) => {
  const date = moment(value);
  const { changeFilter } = useQueryFilter(FILTERS.DATE, onChange);

  const handleChangeDate = useCallback((mode) => () => {
    if (mode === 'prev') {
      date.add(-1, 'days');
    } else {
      date.add(1, 'days');
    }
    onChange(date.toDate());
    changeFilter(date.format('YYYY-MM-DD'));
  }, [date]);

  return (
    <div className="filter-date">
      <HiChevronLeft
        className="btn-date"
        onClick={handleChangeDate('prev')}
      />
      <div className="calendar">
        <BsCalendar />
        <span>{date.format('MMM D')}</span>
      </div>
      <HiChevronRight
        className="btn-date"
        onClick={handleChangeDate('next')}
      />
    </div>
  );
};

DateFilter.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { DateFilter };
