import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';

// import InfoIcon from '../../../../assets/images/icons/info-circle.svg';

import { missionsActions, missionsSelectors } from '../../../../redux/slices';

import { MissionWidgetItem } from '../MissionCards';

const MissionListWidget = ({ className, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(missionsSelectors.selectIsLoading);
  const items = useSelector(missionsSelectors.selectData);

  useEffect(() => {
    if (!items.length) {
      dispatch(missionsActions.loadData.base());
    }
  }, [items.length]);

  const visibleItems = useMemo(() => items.filter(item => !item.userCompletion?.isClaimed), [items]);

  if (!visibleItems?.length && !isLoading) {
    return null;
  }

  return (
    <Accordion defaultActiveKey="missions-list" flush className={className} {...props}>
      <Accordion.Item eventKey="missions-list">
        <Accordion.Header className="d-lg-none">
          {t('REWARDS.MISSIONS_LIST')}
        </Accordion.Header>
        <Accordion.Body>
          <Card bg="dark" className="large-card">
            <Card.Header className="d-none d-lg-block">
              {t('REWARDS.MISSIONS_LIST')}
            </Card.Header>
            {visibleItems?.map((item, ind) => (
              <MissionWidgetItem
                key={item._id}
                data={item}
                noMargin={ind === visibleItems.length - 1}
              />
            ))}
            {/* <Card.Footer> */}
            {/*  <Link to="/"> */}
            {/*    {t('REWARDS.MISSIONS_LIST_HINT')} */}
            {/*    <Image src={InfoIcon} className="ps-1" /> */}
            {/*  </Link> */}
            {/* </Card.Footer> */}
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

MissionListWidget.propTypes = {
  className: PropTypes.string
};

MissionListWidget.defaultProps = {
  className: undefined
};

export { MissionListWidget };
