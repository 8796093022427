import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { tipsActions, userSelectors } from '../../../redux/slices';
import { ROLES } from '../../../data/constants';
import { LoadingSpinner } from '../../LoadingSpinner';

const TipsDeleteModal = ({ onClose, data: { _id: id, message, uid, username, tournamentTitle } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const userData = useSelector(userSelectors.selectUserDetails);

  const isAdmin = (userData?.roles || []).includes(ROLES.TIPS_EDITOR) && uid !== userData?.uid;

  const handleSubmit = useCallback(() => {
    dispatch(tipsActions.deleteTip.base({ id, callback: onClose }));
    setIsSubmitted(true);
  }, [id]);

  return (
    <Modal show onHide={onClose} dialogClassName="max-width-medium">
      {isSubmitted && <LoadingSpinner absolute />}
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('TIPS.MODAL.DELETE_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-3 text-white fs-large">
            {tournamentTitle}
          </Col>
          {isAdmin && (
            <Col xs={12} className="mb-3 text-white">
              {t('TIPS.MODAL.CREATED_BY', { username })}
            </Col>
          )}
          <Col xs={12} className="text-white">
            {message}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-20 d-flex justify-content-end gap-2">
          <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
          <Button
            variant="outline-danger border-radius-0"
            className="text-uppercase "
            onClick={handleSubmit}
          >
            {t('DELETE')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TipsDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    tournamentTitle: PropTypes.string,
  }).isRequired
};

export { TipsDeleteModal };
