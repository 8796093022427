import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';

import { IoIosClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import { matchesSelectors, teamsSelectors } from '../../../redux/slices';

const SlipItem = ({ index, participants, prediction, onRemove }) => {
  const { t } = useTranslation();
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const teamA = useSelector(state => teamsSelectors.selectTeamByRoster(state, participants[0]?.roster?.id));
  const teamB = useSelector(state => teamsSelectors.selectTeamByRoster(state, participants[1]?.roster?.id));
  const defaultImage = gamesImagesMap[prediction.game];

  return (
    <Card.Body className="pt-3 pb-3 mb-2">
      <div className="d-flex justify-content-between align-items-center">
        <span className="text-secondary fs-small">{`[ ${index} ]`}</span>
        <IoIosClose size={24} className="text-secondary cursor-pointer" onClick={onRemove} />
      </div>
      <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
        <Image
          className="img-small"
          src={(teamA?.images || [])[0]?.url || defaultImage}
          alt={teamA?.abbreviation || teamA?.name}
        />
        <span className="score">vs</span>
        <Image
          className="img-small"
          src={(teamB?.images || [])[0]?.url}
          alt={teamB?.abbreviation || teamB?.name}
        />
      </div>
      <div className="d-flex align-items-center gap-1 flex-column">
        <div className="fs-smallest text-secondary">
          {`${prediction.match ? t('PREDICTIONS.MATCH_WINNER') : t('PREDICTIONS.SERIE_WINNER')}:`}
        </div>
        <div className="btn-slip-winner">
          {teamA?.id === prediction.team ? teamA?.name : teamB?.name}
        </div>
      </div>
    </Card.Body>
  );
};

SlipItem.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  prediction: PropTypes.shape({
    team: PropTypes.number,
    match: PropTypes.number,
    game: PropTypes.number,
  }).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape({
    roster: PropTypes.shape({
      id: PropTypes.number,
    })
  })).isRequired
};

export { SlipItem };
