import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { MissionItem } from '../MissionCards';
import { LoadingSpinner } from '../../../LoadingSpinner';

import { missionsActions, missionsSelectors, seriesSelectors } from '../../../../redux/slices';

const MissionList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryMission = new URLSearchParams(search).get('mission');

  const isLoading = useSelector(missionsSelectors.selectIsLoading);
  const items = useSelector(missionsSelectors.selectData);

  const matches = useSelector(state => (
    seriesSelectors.selectSeriesByDate(state, moment(new Date()).format('YYYY-MM-DD'))
  ));

  const [liveMatches, upcomingMatches] = useMemo(() => [
    matches.filter(m => m.lifecycle === 'live'),
    matches.filter(m => m.lifecycle === 'upcoming'),
  ], [matches]);

  useEffect(() => {
    dispatch(missionsActions.loadData.base());
  }, [items.length]);

  if (!items?.length && !isLoading) {
    return (
      <h3 className="text-center m-0 p-3">{t('MISSIONS.NO_MISSIONS')}</h3>
    );
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Row>
          {items?.map(item => (
            <Col xs={12} xxl={6} key={item._id} className="mb-3">
              <MissionItem
                data={item}
                highlight={queryMission === item._id}
                matches={{
                  live: liveMatches,
                  upcoming: upcomingMatches
                }}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export { MissionList };
