import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { userActions, userSelectors } from '../../redux/slices';
import { useScroll } from '../../hooks';
import { PROFILE_ACTIVITIES } from '../../data/constants';
import { getActivityMetadata } from '../../helpers/utils';

import { ProfileActivities as Filters } from '../filters';
import { LoadingSpinner } from '../LoadingSpinner';

const ProfileActivities = ({ uid }) => {
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(PROFILE_ACTIVITIES.ALL);
  const [page, setPage] = useState(0);
  const { data, total, isLoading } = useSelector(userSelectors.selectActivities);

  const handleLoadData = useCallback((p) => {
    const newPage = typeof p === 'number' ? p : page + 1;
    setPage(newPage);
    dispatch(userActions.loadActivities.base({ uid, filter, page: newPage }));
  }, [page, filter, uid]);

  useEffect(() => {
    handleLoadData(1);
    return () => dispatch(userActions.clearActivities.base());
  }, [filter, uid]);

  useScroll(() => {
    if (data.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data.length, total, isLoading]);

  return (
    <>
      <Filters selection={filter} setSelection={setFilter} className="mb-20" />
      <Table variant="dark">
        <thead>
          <tr>
            <th>{t('PROFILE.ACTIVITIES.ACTION')}</th>
            <th>{t('PROFILE.ACTIVITIES.NOTE')}</th>
            {filter === PROFILE_ACTIVITIES.ONLY_WITH_DIAMONDS && (
              <th>{t('PROFILE.ACTIVITIES.DIAMONDS')}</th>
            )}
            <th>{t('PROFILE.ACTIVITIES.DATE')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item._id}>
              <td>{t(`ACTIVITIES.ITEMS_TITLE.${item.action}`)}</td>
              <td>
                {t(`ACTIVITIES.ITEMS_DESCRIPTION.${item.action}`, getActivityMetadata(item.metadata, language))}
              </td>
              {filter === PROFILE_ACTIVITIES.ONLY_WITH_DIAMONDS && (
                <th>{item.metadata.diamonds}</th>
              )}
              <td>{moment(item.createdAt).format('LLL')}</td>
            </tr>
          ))}
          {total === 0 && !isLoading && (
            <tr>
              <td colSpan={filter === PROFILE_ACTIVITIES.ONLY_WITH_DIAMONDS ? 4 : 3} className="text-center pt-2">
                {t('PROFILE.ACTIVITIES.NO_RESULTS')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {data.length < total && !isLoading && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {isLoading && (
        <LoadingSpinner />
      )}
    </>
  );
};

ProfileActivities.propTypes = {
  uid: PropTypes.string.isRequired,
};

export { ProfileActivities };
