import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useFormik } from 'formik';

import { LoadingSpinner } from '../../LoadingSpinner';
import { LocaleFilter } from '../../filters';

import { matchesSelectors, missionsSelectors } from '../../../redux/slices';
import { MISSION_TYPES, MISSION_CONDITIONS } from '../../../data/constants';

const requiredGameTypes = [
  MISSION_CONDITIONS.VOTE_MATCH,
  MISSION_CONDITIONS.VOTE_LIVE_MATCH,
  MISSION_CONDITIONS.VOTE_MAP,
  MISSION_CONDITIONS.VOTE_LIVE_MAP,
  MISSION_CONDITIONS.WIN_VOTED_MATCH,
  MISSION_CONDITIONS.WIN_VOTED_MAP,
];

const getDefaultCondition = () => ({
  type: MISSION_CONDITIONS.VOTE_MATCH,
  amount: 1,
  progress: 100,
  game: undefined,
  missionRef: undefined
});

const ModifyModal = ({ nextOrder, onClose, onSubmit, data }) => {
  const { t, i18n: { language, languages } } = useTranslation();
  const [lang, setLang] = useState(language);

  const isUpdating = useSelector(missionsSelectors.selectIsUpdating);
  const missions = useSelector(missionsSelectors.selectData);
  const games = useSelector(matchesSelectors.selectGames);

  const missionRefOptions = useMemo(() => missions
    .filter(m => m._id !== data?._id && m.conditions.length === 1)
    .map(item => ({
      label: item.title[lang],
      value: item._id,
    })), [missions, lang, data]);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      _id: data?._id,
      title: data?.title || languages.reduce((map, l) => ({ ...map, [l]: '' }), {}),
      description: data?.description || languages.reduce((map, l) => ({ ...map, [l]: '' }), {}),
      type: data?.type || MISSION_TYPES.DAILY,
      order: data?.order || nextOrder,
      price: data?.price || 0,
      visible: Boolean(data?.visible),
      startAt: data?.startAt,
      endAt: data?.endAt,
      conditions: data?.conditions || [getDefaultCondition()],
    },
    onSubmit: values => onSubmit(values),
    validate: value => {
      const error = {};
      languages.forEach(l => {
        if (!value.title[l]) {
          error.title = { ...(error.title || {}), [l]: true };
        }
        if (!value.description[l]) {
          error.description = { ...(error.description || {}), [l]: true };
        }
      });
      if (typeof value.order !== 'number' || !value.order) {
        error.order = true;
      }
      if (typeof value.price !== 'number' || !value.price) {
        error.price = true;
      }
      if (typeof value.visible !== 'boolean') {
        error.visible = true;
      }
      if (!Object.values(MISSION_TYPES).includes(value.type)) {
        error.type = true;
      }
      if (value.type === MISSION_TYPES.REGULAR) {
        if (!value.startAt) {
          error.startAt = true;
        }
        if (!value.endAt) {
          error.endAt = true;
        }
      }
      if (!value.conditions.length) {
        error.noItems = true;
      }
      const maxProgress = value.conditions.reduce((sum, it) => sum + it.progress, 0);
      value.conditions.forEach((it, ind) => {
        if (!Object.values(MISSION_CONDITIONS).includes(it.type)) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            type: true,
          };
        }

        if (it.type === MISSION_CONDITIONS.COMPLETE_MISSION && !it.missionRef) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            missionRef: true,
          };
        }

        if (requiredGameTypes.includes(it.type) && !it.game) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            game: true,
          };
        }

        if (typeof it.amount !== 'number' || !it.amount) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            amount: true,
          };
        }

        if (maxProgress !== 100) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            progress: true,
          };
        } else if (typeof it.progress !== 'number' || !it.progress || it.progress < 1 || it.progress > 100) {
          if (!error.conditions) error.conditions = [];
          error.conditions[ind] = {
            ...(error.conditions[ind] || {}),
            progress: true,
          };
        }
      });
      return error;
    }
  });

  const handleAddItem = () => {
    const conditions = [...values.conditions];
    const newData = getDefaultCondition();
    if ((values.conditions[0] || {}).type === MISSION_CONDITIONS.COMPLETE_MISSION) {
      newData.type = MISSION_CONDITIONS.COMPLETE_MISSION;
    }
    conditions.push(newData);
    setFieldValue('conditions', conditions);
  };

  const handleDeleteItem = ind => () => {
    const conditions = [...values.conditions];
    conditions.splice(ind, 1);
    setFieldValue('conditions', conditions);
  };

  return (
    <Modal show onHide={onClose} dialogClassName="max-width-large">
      {isUpdating && <LoadingSpinner absolute />}
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t(`MISSIONS.${data?._id ? 'UPDATE' : 'CREATE'}_TITLE`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-height-none">
        <LocaleFilter className="mb-20" setSelection={setLang} selection={lang} />
        <Row>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('MISSIONS.ORDER')}</Form.Label>
            <Form.Control
              type="number"
              value={values.order}
              isInvalid={touched.order && errors.order}
              onChange={({ target }) => (
                setFieldValue('order', target.value ? parseInt(target.value, 10) : target.value)
              )}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('MISSIONS.TYPE')}</Form.Label>
            <Form.Select
              value={values.type}
              isInvalid={touched.type && errors.type}
              onChange={({ target }) => setFieldValue('type', target.value)}
            >
              {Object.values(MISSION_TYPES).map(type => (
                <option key={type} value={type}>{t(`MISSIONS.TYPE_${type.toUpperCase()}`)}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('MISSIONS.TITLE')}</Form.Label>
            <Form.Control
              value={values.title[lang]}
              isInvalid={(touched.title || {})[lang] && (errors.title || {})[lang]}
              onChange={({ target }) => setFieldValue(`title.${lang}`, target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('MISSIONS.DESCRIPTION')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={values.description[lang]}
              isInvalid={(touched.description || {})[lang] && (errors.description || {})[lang]}
              onChange={({ target }) => setFieldValue(`description.${lang}`, target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('MISSIONS.PRICE')}</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={values.price}
              isInvalid={touched.price && errors.price}
              onChange={({ target }) => (
                setFieldValue('price', target.value ? parseInt(target.value, 10) : target.value)
              )}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2 text-white mt-2">
            <Form.Check
              type="checkbox"
              label={t('MISSIONS.VISIBLE')}
              checked={values.visible}
              isInvalid={touched.visible && errors.visible}
              onChange={() => setFieldValue('visible', !values.visible)}
            />
          </Form.Group>
          {values.type === MISSION_TYPES.REGULAR && (
            <>
              <Form.Group as={Col} xs={12} className="mb-2">
                <Form.Label>{t('MISSIONS.START_AT')}</Form.Label>
                <Form.Control
                  type="date"
                  value={values.startAt}
                  isInvalid={touched.startAt && errors.startAt}
                  onChange={({ target }) => setFieldValue('startAt', target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} className="mb-2">
                <Form.Label>{t('MISSIONS.END_AT')}</Form.Label>
                <Form.Control
                  type="date"
                  value={values.endAt}
                  isInvalid={touched.endAt && errors.endAt}
                  onChange={({ target }) => setFieldValue('endAt', target.value)}
                />
              </Form.Group>
            </>
          )}
          <Col xs={12} className="text-white mt-3 mb-3 d-flex align-items-center justify-content-between">
            <h4>{t('MISSIONS.CONDITIONS')}</h4>
            <div className="cursor-pointer" onClick={handleAddItem}>
              <MdAdd style={{ fontSize: 25 }} />
            </div>
          </Col>
          {errors.noItems && (
            <div className="text-danger mt-20">
              {t('MISSIONS.NO_ITEMS')}
            </div>
          )}
          {values.conditions.map((item, ind) => (
            <Fragment key={ind}>
              <Form.Group as={Col} xs={requiredGameTypes.includes(item.type) ? 3 : 6} className="mb-2">
                <Form.Label>{t('MISSIONS.TYPE')}</Form.Label>
                <Form.Select
                  value={item.type}
                  disabled={ind !== 0 && (values.conditions[0] || {}).type === MISSION_CONDITIONS.COMPLETE_MISSION}
                  isInvalid={
                    ((touched.conditions || [])[ind] || {}).type
                    && ((errors.conditions || [])[ind] || {}).type
                  }
                  onChange={({ target }) => setFieldValue(`conditions.${ind}.type`, target.value)}
                >
                  {Object.keys(MISSION_CONDITIONS).map(key => (
                    <option key={key} value={MISSION_CONDITIONS[key]}>{t(`MISSIONS.CONDITIONS_${key}`)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              {item.type === MISSION_CONDITIONS.COMPLETE_MISSION ? (
                <Form.Group as={Col} xs={3} className="mb-2">
                  <Form.Label>{t('MISSIONS.MISSION_REF')}</Form.Label>
                  <Form.Select
                    value={item.missionRef}
                    isInvalid={
                      ((touched.conditions || [])[ind] || {}).missionRef
                      && ((errors.conditions || [])[ind] || {}).missionRef
                    }
                    onChange={({ target }) => setFieldValue(`conditions.${ind}.missionRef`, target.value)}
                  >
                    <option value="">{t('MISSIONS.CONDITIONS_SELECT')}</option>
                    {missionRefOptions.map(item => (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <Form.Group as={Col} xs={3} className="mb-2">
                  <Form.Label>{t('MISSIONS.REPEAT')}</Form.Label>
                  <Form.Control
                    type="number"
                    value={item.amount}
                    min={1}
                    max={100}
                    isInvalid={
                      ((touched.conditions || [])[ind] || {}).amount
                      && ((errors.conditions || [])[ind] || {}).amount
                    }
                    onChange={({ target }) => setFieldValue(
                      `conditions.${ind}.amount`,
                      target.value ? parseInt(target.value, 10) : target.value
                    )}
                  />
                </Form.Group>
              )}
              {requiredGameTypes.includes(item.type) && (
                <Form.Group as={Col} xs={3} className="mb-2">
                  <Form.Label>{t('MISSIONS.GAME')}</Form.Label>
                  <Form.Select
                    value={item.game}
                    isInvalid={
                      ((touched.conditions || [])[ind] || {}).game
                      && ((errors.conditions || [])[ind] || {}).game
                    }
                    onChange={({ target }) => setFieldValue(`conditions.${ind}.game`, target.value)}
                  >
                    <option value="">{t('MISSIONS.CONDITIONS_SELECT')}</option>
                    {games.map(game => (
                      <option key={game.id} value={game.id}>{game.abbreviation}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              <Col xs={3} className="d-flex justify-content-between gap-2">
                <Form.Group className="flex-grow-1">
                  <Form.Label>{t('MISSIONS.PROGRESS')}</Form.Label>
                  <Form.Control
                    type="number"
                    value={item.progress}
                    min={1}
                    max={100}
                    isInvalid={
                      ((touched.conditions || [])[ind] || {}).progress
                      && ((errors.conditions || [])[ind] || {}).progress
                    }
                    onChange={({ target }) => setFieldValue(
                      `conditions.${ind}.progress`,
                      target.value ? parseInt(target.value, 10) : target.value
                    )}
                  />
                </Form.Group>
                <div
                  className="text-white mb-20 p-1 cursor-pointer d-flex align-items-end"
                  onClick={handleDeleteItem(ind)}
                >
                  <MdDelete />
                </div>
              </Col>
            </Fragment>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {Object.keys(errors).length && (
          <div className="text-danger mt-20">
            {t('MISSIONS.CHECK_ANOTHER_LANG')}
          </div>
        )}
        <div className="mt-20 d-flex justify-content-end gap-2">
          <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
          <Button type="submit" className="btn-midnight selected" onClick={handleSubmit}>
            {t('SUBMIT')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ModifyModal.propTypes = {
  nextOrder: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export { ModifyModal };
