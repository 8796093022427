import { createSlice } from '../../helpers/store';

const initialState = {
  isLoading: false,
  data: [],
  total: 0,
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'redemptions',
  initialState,

  selectors: {
    selectIsLoading: state => state.isLoading,
    selectData: state => state.data,
    selectTotal: state => state.total,
  },

  reducers: {
    loadData: {
      base: (state, { payload: { page } }) => {
        if (page === 1) {
          state.data = [];
          state.total = 0;
        }
        state.isLoading = true;
      },
      success: (state, { payload: { items, total } }) => {
        state.data = state.data.concat(items);
        state.total = total;
        state.isLoading = false;
      },
      failed: state => {
        state.isLoading = false;
      },
    },
    clearData: {
      base: state => {
        state.data = [];
        state.total = 0;
        state.isLoading = false;
      },
    },
    approve: {
      success: (state, { payload: { id } }) => {
        const index = state.data.findIndex(d => d._id === id);
        state.data.splice(index, 1, {
          ...state.data[index],
          status: 'approved',
          approvedAt: new Date().toISOString()
        });
      }
    }
  },
});

export { reducer as redemptions, actions as redemptionsActions, selectors as redemptionsSelectors };
