import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import moment from 'moment';

import EyeIcon from '../../../assets/images/icons/eye-icon.svg';
import ThumbsUpIcon from '../../../assets/images/icons/thumbs-up.svg';
import ThumbsDownIcon from '../../../assets/images/icons/thumbs-down.svg';
import TimerIcon from '../../../assets/images/icons/timer-icon.svg';

import {
  matchesSelectors,
  seriesSelectors, teamsSelectors, tipsActions, tournamentsSelectors, userSelectors
} from '../../../redux/slices';

import { getTournamentTitle } from '../../../helpers/utils';
import { ROLES } from '../../../data/constants';

import { TipsEditor } from '../TipsEditor';
import { TipsItemPlaceholder } from './TipsItemPlaceholder';

const TipsItem = ({ className, withLink, withUsername, withUsernameDate, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { _id: id, username, uid, message, serie, postedAt, createdAt, likes, dislikes, isLiked } = data;

  const userData = useSelector(userSelectors.selectUserDetails);
  const teamsByRosters = useSelector(teamsSelectors.selectTeamsRosters);
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const serieData = useSelector(state => seriesSelectors.selectSerieById(state, serie));
  const tournamentData = useSelector(state => (
    tournamentsSelectors.selectTournamentById(state, serieData?.tournament?.id)
  ));
  const defaultImage = gamesImagesMap[serieData?.game?.id];
  const canEdit = userData?.uid === uid || userData?.roles?.includes(ROLES.TIPS_EDITOR);

  const handleLikeDislikeClick = useCallback((key) => () => {
    if (userData) {
      dispatch(tipsActions.likeDislikeTip.base({ id, key }));
    }
  }, [userData]);

  const seriaObj = useMemo(() => ({
    ...(serieData || {}),
    tournament: tournamentData,
    participants: (serieData?.participants || []).map(part => ({
      ...part,
      team: part.team || teamsByRosters[part.roster.id]
    }))
  }), [serieData, teamsByRosters, tournamentData]);

  if (!serieData) {
    return <TipsItemPlaceholder />;
  }

  const [teamA, teamB] = seriaObj.participants;

  return (
    <Card bg="dark" className={cx('large-card', className)}>
      <Card.Body className="d-flex gap-3">
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-column gap-2">
            <div
              className={cx('btn-like', 'd-flex', 'gap-2', 'flex-column', 'align-items-center', {
                'cursor-none': !userData,
                active: isLiked === true
              })}
              onClick={handleLikeDislikeClick('likes')}
            >
              <Image src={ThumbsUpIcon} alt="" style={{ width: 20, height: 20 }} />
              {likes > 0 ? likes : null}
            </div>
            <div
              className={cx('btn-like', 'd-flex', 'gap-2', 'flex-column', 'align-items-center', {
                'cursor-none': !userData,
                active: isLiked === false
              })}
              onClick={handleLikeDislikeClick('dislikes')}
            >
              <Image src={ThumbsDownIcon} alt="" style={{ width: 20, height: 20 }} />
              {dislikes > 0 ? dislikes : null}
            </div>
          </div>
          {canEdit && (
            <TipsEditor
              align="start"
              data={{ ...data, tournamentTitle: getTournamentTitle(seriaObj?.tournament?.title) }}
            />
          )}
        </div>
        <div className="d-flex justify-content-between flex-column" style={{ width: 'calc(100% - 104px)' }}>
          <div>
            {(withUsername || withUsernameDate) && (
              <div className="card-actions mb-2 justify-content-start">
                <div className="d-flex">
                  {t('POSTED_BY')}
                  &nbsp;
                  <Link to={`/profile/${username}`}>
                    {username}
                  </Link>
                </div>
                {withUsernameDate && (
                  <div className="d-flex text-nowrap ms-2">
                    <Image src={TimerIcon} alt="" className="me-1" />
                    {moment(createdAt || postedAt).startOf('day').from(moment())}
                  </div>
                )}
              </div>
            )}
            <Card.Title className="mb-2">{getTournamentTitle(seriaObj?.tournament?.title)}</Card.Title>
            <Card.Text className="fs-small text-white">{message}</Card.Text>
          </div>
          {withLink && (
            <div>
              <div className="divider mb-3 mt-3" />
              <div className="card-buttons">
                <Link to={`/matches/${serie}`} className="text-secondary">
                  <Image src={EyeIcon} alt="" className="me-1" />
                  {t('VIEW_MATCH')}
                </Link>
                <div className="d-flex text-nowrap ms-2 fs-small text-secondary">
                  <Image src={TimerIcon} alt="" className="me-1" />
                  {moment(createdAt || postedAt).startOf('day').from(moment())}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex gap-2 flex-column">
          <Image
            className="img-medium"
            src={(teamA?.team?.images || [])[0]?.url || defaultImage}
            alt={teamA?.team?.abbreviation}
          />
          <div className="fs-medium score">vs</div>
          <Image
            className="img-medium"
            src={(teamB?.team?.images || [])[0]?.url || defaultImage}
            alt={teamB?.team?.abbreviation}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

TipsItem.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    serie: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    createdAt: PropTypes.string,
    postedAt: PropTypes.string,
    likes: PropTypes.number.isRequired,
    dislikes: PropTypes.number.isRequired,
    isLiked: PropTypes.bool
  }).isRequired,
  withLink: PropTypes.bool,
  withUsername: PropTypes.bool,
  withUsernameDate: PropTypes.bool,
};

TipsItem.defaultProps = {
  className: undefined,
  withLink: false,
  withUsername: false,
  withUsernameDate: false,
};

export { TipsItem };
