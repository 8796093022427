import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { marketplaceActions, marketplaceSelectors } from '../../../redux/slices';

import { LoadingSpinner } from '../../LoadingSpinner';
import { MarketplaceItem } from './MarketplaceItem';
import { MarketplaceModal } from './MarketplaceModal';

const MarketplaceList = () => {
  const dispatch = useDispatch();
  const { t, i18n: { language } } = useTranslation();

  const data = useSelector(marketplaceSelectors.selectData);
  const isLoading = useSelector(marketplaceSelectors.selectIsLoading);
  const redeemData = useSelector(marketplaceSelectors.selectRedeem);

  useEffect(() => {
    dispatch(marketplaceActions.loadData.base({ lang: language }));
  }, [language]);

  const handleOpenItem = useCallback((item) => () => {
    dispatch(marketplaceActions.redeem.base(item));
  }, []);

  const handleCloseModal = useCallback(() => {
    dispatch(marketplaceActions.closeRedeem.base());
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data?.length && !isLoading) {
    return (
      <h3 className="text-center m-0 p-3">{t('MARKETPLACE.NOT_FOUND')}</h3>
    );
  }

  return (
    <div className="mb-20">
      {data.map(item => (
        <MarketplaceItem
          key={item._id}
          onOpenCase={handleOpenItem(item)}
          data={item}
        />
      ))}
      {redeemData && (
        <MarketplaceModal
          data={redeemData.data}
          winners={redeemData.winners}
          isLoading={redeemData.isLoading}
          onClose={handleCloseModal}
          onPlayAgain={handleOpenItem(redeemData.data)}
        />
      )}
    </div>
  );
};

export { MarketplaceList };
