import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Form } from 'react-bootstrap';
import { debounce } from 'lodash';

import { userActions, userSelectors } from '../../../redux/slices';
import { UserItem } from './UserItem';
import { ModifyModal } from './ModifyModal';
import { ActivitiesModal } from './ActivitiesModal';
import { LoadingSpinner } from '../../LoadingSpinner';
import { useScroll } from '../../../hooks';

const Users = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(null);
  const [activitiesModal, setActivitiesModal] = useState(null);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');

  const { isLoading, isUpdating, total, data } = useSelector(userSelectors.selectAdminData);

  const handleLoadData = useCallback(() => {
    const newPage = page + 1;
    setPage(newPage);
    dispatch(userActions.loadAdminData.base({ page: newPage, query }));
  }, [page, query]);

  useEffect(() => {
    handleLoadData();
    return () => dispatch(userActions.clearAdminData.base());
  }, []);

  useEffect(() => {
    if (!isUpdating) {
      setModal(null);
    }
  }, [isUpdating]);

  useScroll(() => {
    if (data.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data.length, total, isLoading]);

  useEffect(() => handleLoadData(), [query]);

  const handleOpenEdit = useCallback(data => () => setModal(data), []);
  const handleShowActivities = useCallback(uid => () => setActivitiesModal(uid), []);
  const handleClose = useCallback(() => {
    setModal(null);
    setActivitiesModal(null);
  }, []);

  const handleUpdate = useCallback(data => dispatch(userActions.updateProfile.base({
    id: data._id,
    data,
    isAdmin: true
  })), []);
  const handleDelete = useCallback(data => () => dispatch(userActions.deleteProfile.base({ id: data._id })), []);
  const handleBlock = useCallback(data => () => dispatch(userActions.blockProfile.base({ id: data._id })), []);
  const handleUnblock = useCallback(data => () => dispatch(userActions.unblockProfile.base({ id: data._id })), []);
  const handleChangePassword = useCallback(data => () => dispatch(userActions.resetPassword.base({
    id: data._id,
    successMsg: t('USERS.ADMIN_CHANGE_PASSWORD_SUCCESS'),
  })), []);

  const changeSearchQuery = (event) => {
    setPage(0);
    setQuery(event.target.value);
  };
  const changeSearchQueryDebounce = useCallback(debounce(changeSearchQuery, 300), []);

  return (
    <div className="show-grid mb-20">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-2 text-nowrap">{t('USERS.ADMIN_PAGE_TITLE')}</h3>
        <div>
          <Form.Control
            placeholder={t('TYPE_FOR_SEARCH')}
            onChange={changeSearchQueryDebounce}
          />
        </div>
      </div>
      <Table variant="dark" className="mt-20" hover>
        <thead>
          <tr>
            <th>{t('USERS.AVATAR')}</th>
            <th>{t('PROFILE.USERNAME')}</th>
            <th>{t('PROFILE.EMAIL')}</th>
            <th>{t('USERS.DIAMONDS')}</th>
            <th>{t('USERS.ROLES')}</th>
            <th>{t('USERS.REGISTERED')}</th>
            <th>{t('USERS.STATUS')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isLoading && total === 0 && (
            <tr><td colSpan={9} className="text-center">{t('USERS.NO_RESULTS')}</td></tr>
          )}
          {data?.map(item => (
            <UserItem
              key={item._id}
              data={item}
              onEdit={handleOpenEdit(item)}
              onDelete={handleDelete(item)}
              onBlock={handleBlock(item)}
              onUnblock={handleUnblock(item)}
              onChangePassword={handleChangePassword(item)}
              onShowActivities={handleShowActivities(item.uid)}
            />
          ))}
        </tbody>
      </Table>
      {isLoading && (
        <LoadingSpinner />
      )}
      {data?.length < total && !isLoading && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {modal && (
        <ModifyModal
          data={modal}
          onSubmit={handleUpdate}
          onClose={handleClose}
        />
      )}
      {activitiesModal && (
        <ActivitiesModal
          uid={activitiesModal}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export { Users };
