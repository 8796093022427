import { EVENTS } from './events';
import {
  marketplaceActions, matchesActions, notificationsActions, seriesActions, teamsActions, tipsActions,
  tournamentsActions, userActions, missionsActions,
} from '../slices';

const initListener = (store, socket) => {
  socket.on(EVENTS.PREDICTIONS, ({ data }) => {
    store.dispatch(matchesActions.updatePredictionsByMessage.base(data));
  });
  socket.on(EVENTS.UPDATE_CURRENT_USER, ({ data }) => {
    store.dispatch(userActions.loadUserDetails.success(data));
  });
  socket.on(EVENTS.NEW_NOTIFICATION, ({ data }) => {
    store.dispatch(notificationsActions.addNewNotification.success(data));
  });
  socket.on(EVENTS.CREATE_TIP, ({ data }) => {
    store.dispatch(tipsActions.addLatestTip.success(data));
    store.dispatch(seriesActions.loadSeries.base({ ids: [data.serie] }));
  });
  socket.on(EVENTS.UPDATE_TIP, ({ data }) => {
    store.dispatch(tipsActions.updateTip.success(data));
  });
  socket.on(EVENTS.DELETE_TIP, ({ filter: { id } }) => {
    store.dispatch(tipsActions.deleteTip.success({ id }));
  });
  socket.on(EVENTS.USER_DIAMONDS_CHANGED, ({ data }) => {
    store.dispatch(userActions.diamondsChanged.success(data));
  });
  socket.on(EVENTS.SERIE_UPDATED, ({ filter: { id, dateChanged, changed }, data }) => {
    store.dispatch(seriesActions.updateFromSocket.base({ id, dateChanged, data }));
    if (changed === '/streamed' && (store.getState().matches?.matchDetails?.matches || [])[0]?.series?.id === id) {
      store.dispatch(matchesActions.loadSerieDetails.base({ serieId: id }));
    }
  });
  socket.on(EVENTS.MATCH_UPDATED, ({ filter: { id }, data }) => {
    store.dispatch(matchesActions.updateFromSocket.base({ id, data }));
  });
  socket.on(EVENTS.TEAM_UPDATED, ({ filter: { id }, data }) => {
    store.dispatch(teamsActions.updateTeamFromSocket.base({ id, data }));
  });
  socket.on(EVENTS.PLAYER_UPDATES, ({ filter: { id }, data }) => {
    store.dispatch(teamsActions.updatePlayerFromSocket.base({ id, data }));
  });
  socket.on(EVENTS.TOURNAMENT_UPDATED, ({ filter: { id }, data }) => {
    store.dispatch(tournamentsActions.updateFromSocket.base({ id, data }));
  });
  socket.on(EVENTS.MARKETPLACE_ITEM, ({ mode, data }) => {
    switch (mode) {
      case 'create':
        store.dispatch(marketplaceActions.create.success(data));
        break;
      case 'update':
        store.dispatch(marketplaceActions.update.success(data));
        break;
      case 'delete':
        store.dispatch(marketplaceActions.delete.success({ id: data._id }));
        break;
      default:
    }
  });
  socket.on(EVENTS.MISSION, ({ mode, data }) => {
    switch (mode) {
      case 'create':
        store.dispatch(missionsActions.create.success(data));
        break;
      case 'update':
        store.dispatch(missionsActions.update.success(data));
        break;
      case 'delete':
        store.dispatch(missionsActions.delete.success({ id: data._id }));
        break;
      default:
    }
  });
};

export { initListener };
