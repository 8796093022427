import { createSlice } from '../../helpers/store';

const initialState = {
  isLoading: {},
  dataMap: {},
  dataByStatus: {},
  tournamentDetails: null,
  tournamentStages: {}
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'tournaments',
  initialState,

  selectors: {
    selectIsLoading: state => state.isLoading,
    selectIsLoadingById: (state, _, id) => state.isLoading[id],
    selectIds: state => Object.keys(state.dataMap).map(k => parseInt(k, 10)),
    selectTournaments: state => state.dataMap,
    selectTournamentById: (state, _, id) => state.dataMap[id],
    selectTournamentsByStatus: (state, _, status) => (state.dataByStatus[status] || [])
      .map(id => state.dataMap[id]).filter(r => !!r),
    selectTournamentDetails: state => state.tournamentDetails,
    selectStages: (state, _, id) => state.tournamentStages[id] || [],
    selectStagesPhases: state => Object.keys(state.tournamentStages),
    selectStagesCount: state => Object.keys(state.tournamentStages)
      .reduce((sum, k) => sum + state.tournamentStages[k].length, 0),
  },

  reducers: {
    loadTournamentsByStatus: {
      base: (state, { payload: { key } }) => {
        if (!state.dataByStatus[key]) {
          state.isLoading[key] = true;
        }
      },
      success: (state, { payload: { key, data } }) => {
        data.forEach(item => {
          state.dataMap[item.id] = item;
        });
        state.dataByStatus[key] = data.map(item => item.id);
        state.isLoading[key] = false;
      },
      failed: (state, { payload: { key } }) => {
        state.isLoading[key] = false;
      },
    },
    loadTournaments: {
      base: (state, { payload: { ids } }) => {
        ids.forEach(id => {
          state.isLoading[id] = true;
        });
      },
      success: (state, { payload: { ids, data } }) => {
        ids.forEach(id => {
          delete state.isLoading[id];
        });
        data.forEach(item => {
          state.dataMap[item.id] = item;
        });
      },
      failed: (state, { payload: { ids } }) => {
        ids.forEach(id => {
          delete state.isLoading[id];
        });
      },
    },
    updateFromSocket: {
      base: (state, { payload: { id, data } }) => {
        if (state.dataMap[id]) {
          state.dataMap[id] = data;
        }
      }
    },
    loadDetails: {
      base: state => {
        state.isLoading.tournamentDetails = true;
      },
      success: (state, { payload }) => {
        state.isLoading.tournamentDetails = false;
        state.tournamentDetails = payload;
      },
      failed: state => {
        state.isLoading.tournamentDetails = false;
      }
    },
    loadStages: {
      base: state => {
        state.tournamentStages = {};
        state.isLoading.tournamentStages = true;
      },
      success: (state, { payload }) => {
        state.tournamentStages = payload;
        state.isLoading.tournamentStages = false;
      },
      failed: state => {
        state.isLoading.tournamentStages = false;
      }
    },
    clearDetails: {
      base: state => {
        state.tournamentDetails = { ...initialState.tournamentDetails };
      }
    }
  },
});

export { reducer as tournaments, actions as tournamentsActions, selectors as tournamentsSelectors };
