import { createSlice } from '../../helpers/store';

const initialState = {
  isLoading: false,
  isUpdating: false,
  data: [],
  redeem: null
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'marketplace',
  initialState,

  selectors: {
    selectIsLoading: state => state.isLoading,
    selectIsUpdating: state => state.isUpdating,
    selectData: state => state.data,
    selectRedeem: state => state.redeem,
  },

  reducers: {
    loadData: {
      base: state => {
        state.isLoading = true;
      },
      success: (state, { payload }) => {
        state.data = payload;
        state.isLoading = false;
      },
      failed: state => {
        state.isLoading = false;
      },
    },
    create: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload }) => {
        state.data.push(payload);
        state.data.sort((a, b) => a.order - b.order);
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = true;
      }
    },
    update: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload }) => {
        const index = state.data.findIndex(d => d._id === payload._id);
        if (index > -1) {
          if (payload.quantity > 0) {
            state.data.splice(index, 1, payload);
          } else {
            state.data.splice(index, 1);
          }
          state.data.sort((a, b) => a.order - b.order);
        }
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = false;
      }
    },
    delete: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload: { id, data } }) => {
        const index = state.data.findIndex(d => d._id === id);
        if (index > -1) {
          if (data) {
            state.data.splice(index, 1, data);
          } else {
            state.data.splice(index, 1);
          }
        }
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = false;
      }
    },
    redeem: {
      base: (state, { payload }) => {
        state.redeem = {
          isLoading: true,
          data: payload
        };
      },
      success: (state, { payload }) => {
        state.redeem.isLoading = false;
        state.redeem.winners = payload;
      },
      failed: state => {
        state.redeem = null;
      }
    },
    closeRedeem: {
      base: state => {
        state.redeem = null;
      }
    }
  },
});

export { reducer as marketplace, actions as marketplaceActions, selectors as marketplaceSelectors };
