import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Accordion } from 'react-bootstrap';
import moment from 'moment';

import { TournamentFilter, GameFilter, LiveOnlyFilter, DateFilter, HideScoresFilter } from '../../filters';
import { LoadingSpinner } from '../../LoadingSpinner';
import { MatchRow } from '../MatchRow';
import { TipCreationModal } from '../../Tips';

import { matchesSelectors, seriesActions, seriesSelectors, tournamentsSelectors } from '../../../redux/slices';
import { TOURNAMENTS } from '../../../data/constants';
import { useGames, useTournaments } from '../../../hooks';

const LiveInPlay = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [filterTournaments, setFilterTournaments] = useState([]);
  const [filterGames, setFilterGames] = useState([]);
  const [filterLiveOnly, setFilterLiveOnly] = useState(false);
  const [filterHideScores, setFilterHideScores] = useState(false);
  const [filterCurrentDate, setFilterCurrentDate] = useState(moment().startOf('day').toDate());
  const [tipCreationData, setTipCreationData] = useState(null);

  const games = useSelector(matchesSelectors.selectGames);
  const tournaments = useSelector(state => tournamentsSelectors.selectTournamentsByStatus(state, TOURNAMENTS.ONGOING));
  const matchesLoading = useSelector(state => seriesSelectors.selectIsLoading(state, 'byDate'));
  const matches = useSelector(state => (
    seriesSelectors.selectSeriesByDate(state, moment(filterCurrentDate).format('YYYY-MM-DD'))
  ));

  const filteredTournaments = useMemo(() => (
    (tournaments || []).filter(t => (filterGames.length > 0 ? filterGames.includes(t.game.id) : true))
  ), [filterGames, tournaments]);

  const filteredMatches = useMemo(() => (
    (matches || []).filter(t => (
      (filterGames.length > 0 ? filterGames.includes(t.game.id) : true)
      && (filterTournaments.length > 0 ? filterTournaments.includes(t.tournament.id) : true)
      && (filterLiveOnly
        ? (t.lifecycle === 'live'
          || (+new Date(t.start) < +new Date() && (t.end ? +new Date(t.end) > +new Date() : true))
        ) : true)
    ))
  ), [matches, filterTournaments, filterGames, filterLiveOnly]);

  useGames();
  useTournaments();

  useEffect(() => {
    dispatch(seriesActions.loadSeriesByDate.base({
      date: moment(filterCurrentDate).format('YYYY-MM-DD')
    }));
  }, [filterCurrentDate]);

  return (
    <Accordion defaultActiveKey="live-in-play" className="mt-20" flush>
      <Accordion.Item eventKey="live-in-play">
        <Accordion.Header className="d-lg-none">
          {t('MATCHES.LIVE_IN_PLAY')}
        </Accordion.Header>
        <Accordion.Body className="show-grid mb-20">
          <div className="show-grid mb-20">
            <Row>
              <Col xs={12} lg={3} xxl={2} className="d-none d-lg-flex align-items-center">
                <h3 className="mb-2 text-nowrap">{t('MATCHES.LIVE_IN_PLAY')}</h3>
              </Col>
              <Col xs={12} lg={9} xxl={10} className="d-flex justify-content-end mt-3 mt-md-0">
                <TournamentFilter
                  options={filteredTournaments}
                  selection={filterTournaments}
                  setSelection={setFilterTournaments}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <GameFilter
                  options={games}
                  selection={filterGames}
                  setSelection={value => {
                    setFilterTournaments([]);
                    setFilterGames(value);
                  }}
                />
              </Col>
              <Col
                xs={12}
                md={8}
                className="d-flex gap-2 justify-content-md-end justify-content-between mt-3 mt-md-0 flex-wrap"
              >
                <HideScoresFilter
                  value={filterHideScores}
                  onChange={setFilterHideScores}
                />
                <LiveOnlyFilter
                  value={filterLiveOnly}
                  onChange={setFilterLiveOnly}
                />
                <DateFilter
                  value={filterCurrentDate}
                  onChange={setFilterCurrentDate}
                />
              </Col>
            </Row>
          </div>
          <div className="mb-20">
            {matchesLoading && (
              <LoadingSpinner />
            )}
            {!matchesLoading && filteredMatches.length === 0 && (
              <div className="text-white text-center">
                {t('MATCHES.NO_RESULTS')}
              </div>
            )}
            {filteredMatches.map(match => (
              <MatchRow
                key={match.id}
                data={match}
                onCreateTip={() => setTipCreationData(match)}
                hideScores={filterHideScores}
              />
            ))}
          </div>
          {tipCreationData?.id && (
            <TipCreationModal
              data={tipCreationData}
              onClose={() => setTipCreationData(null)}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

LiveInPlay.propTypes = {
};

LiveInPlay.defaultProps = {
};

export { LiveInPlay };
