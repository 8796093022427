import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { pages, routes } from '../data/menu';
import { REWARDS_TABS } from '../data/constants';

import { Banner } from '../components/Banner';

const useBanners = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const { i18n: { language } } = useTranslation();

  const HomeBanners = {
    en: [
      {
        onClick: () => loginWithRedirect({ screen_hint: 'signup' }),
        image: '/images/promo/home_banner_en.png'
      }
    ],
    kr: [
      {
        onClick: () => navigate(`${routes[pages.rewards]}#${REWARDS_TABS.MARKETPLACE}\``),
        image: '/images/promo/home_banner_kr.jpg'
      }
    ],
  };

  return {
    HomeBanner: () => <Banner data={HomeBanners[language]} />,
  };
};

export { useBanners };
