import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Image, Placeholder } from 'react-bootstrap';

import { getPredictionPercent } from '../../../helpers/utils';

const Team = ({ className, team, isEnded, canVote, onVote, reverse, defaultImage, predictions, onOpenTeam }) => {
  const { t } = useTranslation();

  const handleVote = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onVote({ team: team.id, roster: team.roster.id });
  }, [team]);

  if (!team) {
    return (
      <Placeholder className={className} animation="glow">
        {reverse && (
          <Placeholder className="img" style={{ borderRadius: '50%' }} />
        )}
        <Placeholder style={{ width: 50 }} />
        {!reverse && (
          <Placeholder className="img" style={{ borderRadius: '50%' }} />
        )}
      </Placeholder>
    );
  }

  return (
    <div className={className}>
      {reverse && (
        <Image
          className={cx('img', { 'opacity-25': isEnded })}
          src={(team.images || [])[0]?.url || defaultImage}
          alt={team.abbreviation}
        />
      )}
      <div className="d-flex flex-column gap-1">
        <div
          onClick={onOpenTeam(team.id)}
          className={cx('text-white', 'text-decoration-none', 'fs-medium', 'text-break', {
            'opacity-25': isEnded
          })}
        >
          {team.abbreviation || team.name}
        </div>
        <div className={cx('d-flex', 'gap-2', { 'ms-auto': reverse, 'ms-xl-0': reverse })}>
          {Object.keys(predictions || {}).length > 0 && (
            <Button
              variant="outline-primary"
              className="small text-uppercase opacity-100"
              disabled
            >
              {`${getPredictionPercent(predictions, team?.roster?.id)}%`}
            </Button>
          )}
          {canVote && (
            <Button variant="outline-primary" className="small text-uppercase" onClick={handleVote}>
              {t('MATCHES.VOTE')}
            </Button>
          )}
        </div>
      </div>
      {!reverse && (
        <Image
          className={cx('img', { 'opacity-25': isEnded })}
          onClick={onOpenTeam(team.id)}
          src={(team.images || [])[0]?.url || defaultImage}
          alt={team.abbreviation}
        />
      )}
    </div>
  );
};

Team.propTypes = {
  className: PropTypes.string.isRequired,
  onOpenTeam: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number,
    roster: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string,
    abbreviation: PropTypes.string,
    images: PropTypes.array,
  }),
  isEnded: PropTypes.bool.isRequired,
  onVote: PropTypes.func.isRequired,
  reverse: PropTypes.bool,
  canVote: PropTypes.bool,
  defaultImage: PropTypes.node,
  predictions: PropTypes.shape({
    teamA: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    teamB: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    myVote: PropTypes.number
  })
};

Team.defaultProps = {
  reverse: false,
  canVote: false,
  team: {},
  predictions: {},
  defaultImage: undefined
};

export { Team };
