import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../../LoadingSpinner';
import { TipsItem } from '../../Tips';

import { tipsActions, tipsSelectors } from '../../../redux/slices';
import { ALL_FILTER } from '../../../data/constants';

const MyTipsList = ({ as, username, displayLimit, game, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const Component = as || Fragment;

  const { isLoading, data, latestData, total } = useSelector(tipsSelectors.selectProfileTips);

  const handleLoadData = useCallback(({ clear }) => {
    const newPage = clear ? 1 : page + 1;
    dispatch(tipsActions.loadTipsForProfile.base({
      username,
      page: newPage,
      game: game === ALL_FILTER ? undefined : game
    }));
    setPage(newPage);
  }, [username, page, game]);

  useEffect(() => {
    handleLoadData({ clear: true });
    return () => dispatch(tipsActions.clearTipsForProfile.base());
  }, [game]);

  const filterData = useMemo(() => (
    displayLimit ? [...latestData].splice(0, displayLimit) : data
  ), [displayLimit, data, latestData]);

  return (
    <>
      {!filterData.length && !isLoading && (
        <div className="text-white text-center">
          {t('TIPS.NO_RESULTS')}
        </div>
      )}
      {filterData.map(item => (
        <Component key={item._id} {...props}>
          <TipsItem
            className="mb-3"
            withLink
            data={item}
          />
        </Component>
      ))}
      {!displayLimit && total > data.length && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {(displayLimit ? !latestData.length : true) && isLoading && (
        <LoadingSpinner />
      )}
    </>
  );
};

MyTipsList.propTypes = {
  as: PropTypes.object,
  username: PropTypes.string.isRequired,
  displayLimit: PropTypes.number,
  game: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MyTipsList.defaultProps = {
  as: undefined,
  displayLimit: undefined,
  game: undefined,
};

export { MyTipsList };
