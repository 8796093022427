import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Image, Placeholder } from 'react-bootstrap';

import { teamsActions, teamsSelectors } from '../../../redux/slices';
import { PlayerInfo } from './PlayerInfo';

const TeamInfo = ({ teamId }) => {
  const dispatch = useDispatch();

  const { team, players } = useSelector(teamsSelectors.selectTeamDetails);

  useEffect(() => {
    dispatch(teamsActions.loadDetails.base({ id: teamId }));
    return () => dispatch(teamsActions.clearDetails.base());
  }, [teamId]);

  useEffect(() => {
    if (players) {
      dispatch(teamsActions.loadPlayers.base({ ids: players }));
    }
  }, [players]);

  return (
    <Card as={Placeholder} bg="dark" className="large-card mt-20" animation="glow">
      <Card.Header className="d-flex align-items-center gap-4">
        {team ? (
          <Image src={(team?.images || [])[0]?.url} style={{ width: 70 }} />
        ) : (
          <Placeholder style={{ width: 70, height: 70 }} />
        )}
        <div className="d-flex flex-column gap-2 text-white">
          {team ? (
            <h3 className="mb-0">{team?.abbreviation || team?.name}</h3>
          ) : (
            <Placeholder style={{ width: 150 }} />
          )}
          <div className="d-flex gap-2 align-items-center">
            {team ? (
              <>
                <Image
                  src={(team?.region?.country?.images || [])[0]?.url}
                  alt={team?.region?.country?.abbreviation}
                  style={{ height: 20, width: 28 }}
                />
                <div>{`${team?.region?.name}, ${team?.region?.country?.name}`}</div>
              </>
            ) : (
              <>
                <Placeholder style={{ width: 28 }} />
                <Placeholder style={{ width: 150 }} />
              </>
            )}
          </div>
        </div>
      </Card.Header>
      <div style={{ maxHeight: 665, overflowY: 'auto' }}>
        {players?.map((id, ind) => (
          <PlayerInfo key={id} id={id} hasMargin={ind !== players.length - 1} />
        ))}
      </div>
    </Card>
  );
};

TeamInfo.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export { TeamInfo };
