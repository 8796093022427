export const languages = [
  {
    key: 'en',
    image: '/images/lang/GB.svg'
  },
  {
    key: 'kr',
    image: '/images/lang/KR.svg'
  }
];
