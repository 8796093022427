import { createSlice } from '../../helpers/store';

const initialState = {
  isLoading: false,
  isUpdating: false,
  data: [],
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'missions',
  initialState,

  selectors: {
    selectIsLoading: state => state.isLoading,
    selectIsUpdating: state => state.isUpdating,
    selectData: state => state.data,
  },

  reducers: {
    loadData: {
      base: state => {
        state.isLoading = true;
      },
      success: (state, { payload }) => {
        state.data = payload;
        state.isLoading = false;
      },
      failed: state => {
        state.isLoading = false;
      },
    },
    create: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload }) => {
        state.data.push(payload);
        state.data.sort((a, b) => a.order - b.order);
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = true;
      }
    },
    update: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload }) => {
        state.data = state.data.map(item => {
          if (item._id === payload._id) {
            item = payload;
          }
          item.conditions = item.conditions?.map(cond => {
            if (cond?.missionRef?._id === payload._id) {
              cond.missionRef = payload;
            }
            return cond;
          });
          return item;
        });
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = false;
      }
    },
    delete: {
      base: state => {
        state.isUpdating = true;
      },
      success: (state, { payload: { id, data } }) => {
        const index = state.data.findIndex(d => d._id === id);
        if (index > -1) {
          if (data) {
            state.data.splice(index, 1, data);
          } else {
            state.data.splice(index, 1);
          }
        }
        state.isUpdating = false;
      },
      failed: state => {
        state.isUpdating = false;
      }
    },
    claimMission: {
      success: (state, { payload: { id } }) => {
        state.data = state.data.map(item => {
          let changed = false;
          if (item._id === id) {
            changed = true;
            item.userCompletion = {
              isClaimed: true,
              readyToClaim: false
            };
          }
          item.conditions = item.conditions.map(cond => {
            if (cond?.missionRef?._id === id) {
              changed = true;
              cond = {
                ...cond,
                missionRef: {
                  ...cond.missionRef,
                  userCompletion: {
                    isClaimed: true,
                    readyToClaim: false
                  }
                }
              };
            }
            return cond;
          });
          return changed ? { ...item } : item;
        });
      }
    },
    startMission: {},
    clearData: {
      base: state => {
        state.data = [];
      }
    }
  },
});

export { reducer as missions, actions as missionsActions, selectors as missionsSelectors };
