import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Image, Placeholder, Row } from 'react-bootstrap';
import { BsCalendar } from 'react-icons/bs';
import { MdDeveloperBoard } from 'react-icons/md';
import moment from 'moment';

import { tournamentsActions, tournamentsSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';
import { TOURNAMENTS } from '../../../data/constants';
import CupIcon from '../../../assets/images/icons/cup-blue.svg';

const TournamentInfo = ({ tournamentId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const details = useSelector(tournamentsSelectors.selectTournamentDetails);

  useEffect(() => {
    dispatch(tournamentsActions.loadDetails.base({ id: tournamentId }));
    return () => dispatch(tournamentsActions.clearDetails.base());
  }, [tournamentId]);

  const type = useMemo(() => {
    if (details) {
      if (
        details.start && +new Date(details.start) <= +new Date()
        && (details.end === null || +new Date(details.end) >= +new Date())
      ) {
        return TOURNAMENTS.ONGOING;
      }
      if (details.end && +new Date(details.end) <= +new Date()) {
        return TOURNAMENTS.PAST;
      }
      if (+new Date(details.start) > +new Date()) {
        return TOURNAMENTS.UPCOMING;
      }
    }
    return null;
  }, [details]);

  if (!details) {
    return (
      <Card as={Placeholder} bg="dark" className="large-card" animation="glow">
        <Card.Body>
          <Row>
            <Col xs={12} md={8} className="d-flex flex-column gap-3 text-white">
              <div className="d-flex gap-3 align-items-center">
                <Placeholder className="img-largest" />
                <div className="d-flex flex-column">
                  <h3><Placeholder style={{ width: '80%' }} /></h3>
                  <div className="d-flex align-items-center gap-2">
                    <Placeholder
                      style={{ height: 14, width: 20 }}
                    />
                    <Placeholder style={{ width: 100 }} />
                  </div>
                </div>
              </div>
              <Row className="d-flex gap-3 m-0">
                <Col
                  className="d-flex flex-column p-3 border fs-medium border-lightest"
                  style={{ maxWidth: 225 }}
                  xs={12}
                  md={6}
                >
                  <Image src={CupIcon} style={{ height: 20, width: 20 }} className="mb-2" />
                  <Placeholder style={{ width: 100 }} />
                </Col>
                <Col
                  className="d-flex flex-column p-3 border fs-medium border-lightest"
                  style={{ maxWidth: 225 }}
                  xs={12}
                  md={6}
                >
                  <BsCalendar style={{ fontSize: 20 }} className="mb-2 text-midnight" />
                  <Placeholder style={{ width: 100 }} />
                </Col>
                <Col
                  className="d-flex flex-column p-3 border fs-medium border-lightest"
                  style={{ maxWidth: 225 }}
                  xs={12}
                  md={6}
                >
                  <MdDeveloperBoard style={{ fontSize: 20 }} className="mb-2 text-midnight" />
                  <Placeholder style={{ width: 100 }} />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} className="text-secondary fs-medium mt-2">
              <Placeholder style={{ width: '100%' }} />
              <Placeholder style={{ width: '100%' }} />
              <Placeholder style={{ width: '100%' }} />
              <Placeholder style={{ width: '100%' }} />
              <Placeholder style={{ width: '100%' }} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card bg="dark" className="large-card">
      <Card.Body>
        <Row>
          <Col xs={12} md={8} className="d-flex flex-column gap-3 text-white">
            <div className="d-flex gap-3 align-items-center">
              <Image
                className="img-largest"
                src={(details?.images?.find(i => i.type === 'square') || (details?.images || [])[0])?.url}
              />
              <div className="d-flex flex-column">
                <h3>{getTournamentTitle(details.title)}</h3>
                <div className="d-flex align-items-center gap-2">
                  <Image
                    src={(details?.location?.host?.country?.images || [])[0]?.url}
                    alt={details?.location?.host?.country?.abbreviation}
                    style={{ height: 14, width: 20 }}
                  />
                  <span>{details?.location?.host?.country?.name || details?.location?.host?.name}</span>
                  {type && (
                    <span
                      className={cx('status', {
                        red: type === TOURNAMENTS.PAST,
                        green: [TOURNAMENTS.ONGOING, TOURNAMENTS.UPCOMING].includes(type)
                      })}
                    >
                      {t(`TOURNAMENTS.${type?.toUpperCase()}`)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Row className="d-flex gap-3 m-0">
              <Col
                className="d-flex flex-column p-3 border fs-medium border-lightest"
                style={{ maxWidth: 225 }}
                xs={12}
                md={6}
              >
                <Image src={CupIcon} style={{ height: 20, width: 20 }} className="mb-2" />
                <div>{details.string_prize_pool?.total || 'TBD'}</div>
              </Col>
              <Col
                className="d-flex flex-column p-3 border fs-medium border-lightest"
                style={{ maxWidth: 225 }}
                xs={12}
                md={6}
              >
                <BsCalendar style={{ fontSize: 20 }} className="mb-2 text-midnight" />
                <div>
                  {`${moment(details.start).format('DD/MM/YYYY')} - `}
                  {`${details.end ? moment(details.end).format('DD/MM/YYYY') : 'TBD'}`}
                </div>
              </Col>
              <Col
                className="d-flex flex-column p-3 border fs-medium border-lightest"
                style={{ maxWidth: 225 }}
                xs={12}
                md={6}
              >
                <MdDeveloperBoard style={{ fontSize: 20 }} className="mb-2 text-midnight" />
                <div>{t('TOURNAMENTS.STAGES', { count: details.stages?.length })}</div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} className="text-secondary fs-medium mt-2">
            {details?.copy?.short_description}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

TournamentInfo.propTypes = {
  tournamentId: PropTypes.string.isRequired
};

export { TournamentInfo };
