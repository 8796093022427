import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Row } from 'react-bootstrap';

import { TipCreationContent } from './TipCreationContent';
import { LoadingSpinner } from '../../LoadingSpinner';
import { matchesSelectors, seriesSelectors, teamsSelectors, tipsActions } from '../../../redux/slices';

const TipCreation = ({ onClose, serieId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const teamsByRosters = useSelector(teamsSelectors.selectTeamsRosters);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, serieId));
  const serieData = useSelector(state => seriesSelectors.selectSerieById(state, serieId));

  const [teamA, teamB] = useMemo(() => (serieData?.participants || []).map(p => ({
    ...p,
    team: teamsByRosters[p.roster.id]
  })), [serieData?.participants, teamsByRosters]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (comment) {
      dispatch(tipsActions.createTip.base({ id: parseInt(serieId, 10), comment, callback: onClose }));
      setIsSubmitted(true);
    }
  };

  return (
    <Card as={Form} bg="dark" className="large-card" onSubmit={handleSubmit}>
      <Card.Body>
        {isSubmitted && <LoadingSpinner absolute />}
        <h5 className="mb-20">{t('TIPS.MODAL.CREATE_TITLE')}</h5>
        <Row>
          <TipCreationContent
            comment={comment}
            setComment={setComment}
            start={serieData?.start}
            teamA={teamA}
            teamB={teamB}
            predictions={predictions}
          />
        </Row>
        <div className="mt-20 d-flex justify-content-end gap-2">
          <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
          <Button
            type="submit"
            className="text-uppercase btn-midnight selected"
            disabled={comment.length < 5 || comment.length > 500}
          >
            {t('SUBMIT')}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

TipCreation.propTypes = {
  serieId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { TipCreation };
