import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

function useDeepCompareMemoize(value) {
  const ref = useRef();
  const signalRef = useRef(0);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return [signalRef.current];
}

/**
 * The hook called only on deep change in dependencies.
 *
 * @param {Function} callback The callback function.
 * @param {*} dependencies The array to deep equality test.
 */
export const useDeepEffect = (callback, dependencies = []) => useEffect(callback, useDeepCompareMemoize(dependencies));
