import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { LoadingSpinner } from '../../LoadingSpinner';

import { userSelectors } from '../../../redux/slices';
import { ROLES } from '../../../data/constants';

const ModifyModal = ({ onClose, onSubmit, data }) => {
  const { t } = useTranslation();

  const { isUpdating } = useSelector(userSelectors.selectAdminData);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      _id: data._id,
      username: data.username,
      email: data.email,
      avatar: data.profile.avatar,
      roles: data.roles,
    },
    onSubmit: values => onSubmit(values),
    validate: value => {
      const error = {};
      if (!value.username) {
        error.username = true;
      }
      if (!value.email) {
        error.email = true;
      }
    }
  });

  return (
    <Modal show onHide={onClose} dialogClassName="max-width-large">
      {isUpdating && <LoadingSpinner absolute />}
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('USERS.ADMIN_PAGE_UPDATE_TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-height-none">
        <Row>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('PROFILE.USERNAME')}</Form.Label>
            <Form.Control
              value={values.username}
              isInvalid={touched.username && errors.username}
              onChange={({ target }) => setFieldValue('username', target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('PROFILE.EMAIL')}</Form.Label>
            <Form.Control
              value={values.email}
              isInvalid={touched.email && errors.email}
              onChange={({ target }) => setFieldValue('email', target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} className="mb-2">
            <Form.Label>{t('USERS.ROLES')}</Form.Label>
            <Form.Select
              multiple
              value={values.roles}
              isInvalid={touched.roles && errors.roles}
              onChange={({ target }) => {
                const roles = [...values.roles];
                const index = values.roles.indexOf(target.value);
                if (index > -1) {
                  roles.splice(index, 1);
                } else {
                  roles.push(target.value);
                }
                setFieldValue('roles', roles);
              }}
            >
              {Object.keys(ROLES).map(key => (
                <option key={key} value={ROLES[key]}>{t(`USERS.ROLES_${key}`)}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer className="mt-20 d-flex justify-content-end gap-2">
        <Button className="btn-midnight" onClick={onClose}>{t('CANCEL')}</Button>
        <Button type="submit" className="btn-midnight selected" onClick={handleSubmit}>
          {t('SUBMIT')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModifyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export { ModifyModal };
