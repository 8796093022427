import React, { useCallback, useEffect } from 'react';
import { Accordion, Button, Card, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SlipItem } from './SlipItem';

import Diamond from '../../../assets/images/diamonds/diamond.svg';
import DiamondRed from '../../../assets/images/diamonds/diamond-red.svg';
import { matchesActions, matchesSelectors, userSelectors } from '../../../redux/slices';
import { TASKS_KEYS } from '../../../data/diamonds';
import { getClaimDiamonds } from '../../../helpers/utils';
import { LoadingSpinner } from '../../LoadingSpinner';

const PredictionSlip = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const predictions = useSelector(matchesSelectors.selectPredictionSlip);
  const isUpdating = useSelector(matchesSelectors.selectPredictionSlipUpdating);
  const userData = useSelector(userSelectors.selectUserDetails);

  const handleSendPrediction = useCallback(() => {
    if (predictions.length) {
      dispatch(matchesActions.sendPredictionData.base(predictions.map(p => p.prediction)));
    }
  }, [predictions]);

  const handleRemovePrediction = useCallback(item => () => {
    dispatch(matchesActions.removePredictionData.base(item));
  }, []);

  const voteEarn = getClaimDiamonds(TASKS_KEYS.VOTE, userData?.claims, predictions.length, true);
  const potentialWin = getClaimDiamonds(TASKS_KEYS.MATCH_WON, userData?.claims, predictions.length);

  const hasDiamonds = userData?.wallet?.diamonds > -voteEarn;

  const handleBeforeUnload = (event) => {
    event.returnValue = t('POPUP_LEAVE_SITE');
  };

  useEffect(() => {
    if (predictions.length > 0) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [predictions.length]);

  return (
    <Accordion defaultActiveKey="prediction-slip" flush {...props}>
      <Accordion.Item eventKey="prediction-slip">
        <Accordion.Header className="d-lg-none">
          {t('PREDICTIONS.PREDICTION_SLIP')}
        </Accordion.Header>
        <Accordion.Body>
          <Card bg="dark" className="large-card prediction-slip">
            <Card.Header className="d-none d-lg-block">
              {t('PREDICTIONS.PREDICTION_SLIP')}
            </Card.Header>
            <div style={{ maxHeight: 400, overflow: 'auto', position: 'relative' }}>
              {isUpdating && <LoadingSpinner absolute />}
              {!predictions.length && (
                <Card.Body className="text-center text-white">
                  {t('PREDICTIONS.NO_PREDICTION_SLIP')}
                </Card.Body>
              )}
              {predictions.map(({ prediction, participants }, index) => (
                <SlipItem
                  index={index + 1}
                  key={`${prediction.serie}-${prediction.match}`}
                  prediction={prediction}
                  participants={participants}
                  onRemove={handleRemovePrediction(prediction)}
                />
              ))}
            </div>
            <div className="card-divider d-lg-none" />
            <Card.Footer>
              <div className="d-flex justify-content-between align-items-center text-danger fs-medium mb-3">
                <div>{t('PREDICTIONS.USE_DIAMONDS')}</div>
                <div>
                  <Image src={DiamondRed} alt="" className="me-1" style={{ height: 15 }} />
                  {voteEarn}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center text-midnight fs-medium mb-3">
                <div>{t('PREDICTIONS.POTENTIAL_EARN')}</div>
                <div>
                  <Image src={Diamond} alt="" className="me-1" style={{ height: 15 }} />
                  {potentialWin}
                </div>
              </div>
              <Button
                className="btn-midnight selected text-uppercase w-100 text-midnight"
                onClick={handleSendPrediction}
                disabled={!predictions.length || isUpdating || !hasDiamonds}
              >
                {t('SUBMIT')}
              </Button>
            </Card.Footer>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export { PredictionSlip };
