import { all, put, select, call, takeEvery, delay } from 'redux-saga/effects';

import * as api from '../../api/games';
import {
  matchesActions, seriesActions, seriesSelectors, teamsActions, tipsActions, tournamentsActions
} from '../slices';

let loadSeriesFlowBusy = false;

export function* loadSeriesFlow({ payload: { ids } }) {
  try {
    if (loadSeriesFlowBusy) {
      yield delay(1000);
      yield put(seriesActions.loadSeries.base({ ids }));
    } else {
      loadSeriesFlowBusy = true;
      const existedIds = yield select(seriesSelectors.selectIds);
      const newIds = ids.filter(id => !existedIds.includes(id));
      if (newIds.length) {
        const data = yield call(api.getSeriesByIds, newIds);
        const { rosters, tournaments } = data.reduce((map, next) => ({
          rosters: Array.from(new Set([...map.rosters, ...next.participants.map(p => p.roster.id)])),
          tournaments: Array.from(new Set([...map.tournaments, next.tournament.id])),
        }), { rosters: [], tournaments: [] });
        yield all([
          put(seriesActions.loadSeries.success({ ids, data })),
          put(tournamentsActions.loadTournaments.base({ ids: tournaments })),
          put(teamsActions.loadTeamsByRosters.base({ ids: rosters })),
          put(tipsActions.checkCreatedTips.base({ ids })),
        ]);
      }
      loadSeriesFlowBusy = false;
    }
  } catch (error) {
    yield put(seriesActions.loadSeries.failed({ error, ids }));
    loadSeriesFlowBusy = false;
  }
}

export function* loadSeriesByDateFlow({ payload: { date } }) {
  try {
    const hasData = yield select(state => seriesSelectors.selectSeriesByDate(state, date));
    if (!hasData || hasData?.length === 0) {
      const data = yield call(api.getSeriesByDate, date);
      const { rosters, tournaments } = data.reduce((map, next) => ({
        rosters: Array.from(new Set([...map.rosters, ...next.participants.map(p => p.roster.id)])),
        tournaments: Array.from(new Set([...map.tournaments, next.tournament.id])),
      }), { rosters: [], tournaments: [] });
      yield all([
        put(seriesActions.loadSeriesByDate.success({ date, data })),
        put(matchesActions.loadPredictions.base({ ids: data.map(i => i.id) })),
        put(tournamentsActions.loadTournaments.base({ ids: tournaments })),
        put(teamsActions.loadTeamsByRosters.base({ ids: rosters })),
        put(tipsActions.checkCreatedTips.base({ ids: data.map(i => i.id) })),
      ]);
    }
  } catch (error) {
    yield put(seriesActions.loadSeriesByDate.failed({ error }));
  }
}

export function* loadTeamMatchesFlow({ payload: { teamId, status, page } }) {
  try {
    const data = yield call(api.getTeamMatches, teamId, status, page);
    const { rosters, tournaments } = data.reduce((map, next) => ({
      rosters: Array.from(new Set([...map.rosters, ...next.participants.map(p => p.roster.id)])),
      tournaments: Array.from(new Set([...map.tournaments, next.tournament.id])),
    }), { rosters: [], tournaments: [] });
    yield all([
      put(seriesActions.loadTeamMatches.success({ data, page })),
      put(matchesActions.loadPredictions.base({ ids: data.map(i => i.id) })),
      put(tournamentsActions.loadTournaments.base({ ids: tournaments })),
      put(teamsActions.loadTeamsByRosters.base({ ids: rosters })),
    ]);
  } catch (error) {
    yield put(seriesActions.loadTeamMatches.failed({ error }));
  }
}

export function* loadTournamentMatchesFlow({ payload: { tournamentId, status, page } }) {
  try {
    const data = yield call(api.getTournamentMatches, tournamentId, status, page);
    const { rosters, tournaments } = data.reduce((map, next) => ({
      rosters: Array.from(new Set([...map.rosters, ...next.participants.map(p => p.roster.id)])),
      tournaments: Array.from(new Set([...map.tournaments, next.tournament.id])),
    }), { rosters: [], tournaments: [] });
    yield all([
      put(seriesActions.loadTournamentMatches.success({ data, page })),
      put(matchesActions.loadPredictions.base({ ids: data.map(i => i.id) })),
      put(tournamentsActions.loadTournaments.base({ ids: tournaments })),
      put(teamsActions.loadTeamsByRosters.base({ ids: rosters })),
    ]);
  } catch (error) {
    yield put(seriesActions.loadTournamentMatches.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeEvery(seriesActions.loadSeries.types.BASE, loadSeriesFlow),
    takeEvery(seriesActions.loadSeriesByDate.types.BASE, loadSeriesByDateFlow),
    takeEvery(seriesActions.loadTeamMatches.types.BASE, loadTeamMatchesFlow),
    takeEvery(seriesActions.loadTournamentMatches.types.BASE, loadTournamentMatchesFlow),
  ]);
}
