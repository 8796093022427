import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const MatchScores = ({ className, scoreA, scoreB, separator }) => (
  <div
    className={cx('score', className)}
  >
    <div>{scoreA === null ? '-' : scoreA}</div>
    <div>{separator}</div>
    <div>{scoreB === null ? '-' : scoreB}</div>
  </div>
);

MatchScores.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  separator: PropTypes.string,
  scoreA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scoreB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MatchScores.defaultProps = {
  className: undefined,
  separator: 'vs',
  scoreA: null,
  scoreB: null,
};

export { MatchScores };
