import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Image, Placeholder } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HiChevronDown } from 'react-icons/hi';

import { PlayerDetails } from './PlayerDetails';
import { matchesSelectors, teamsActions, teamsSelectors } from '../../../redux/slices';

const TeamDetails = ({ data: { id, line_up, game } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const teamData = useSelector(state => teamsSelectors.selectTeamByRoster(state, id));

  const defaultImage = gamesImagesMap[game?.id];

  useEffect(() => {
    if (opened) {
      dispatch(teamsActions.loadPlayers.base({ ids: line_up.players.map(p => p.id) }));
    }
  }, [line_up, opened]);

  if (!teamData) {
    return (
      <Placeholder animation="glow" className="p-3">
        <div className="d-flex justify-content-between align-items-center cursor-pointer">
          <div className="d-flex gap-3">
            <Placeholder className="img" />
            <div className="d-flex flex-column">
              <Placeholder style={{ width: '50%' }} />
              <div className="text-secondary">
                {t('VIEW_LINEUP')}
                <HiChevronDown style={{ transform: `rotate(${opened ? '180deg' : '0deg'})` }} />
              </div>
            </div>
          </div>
          <Placeholder style={{ height: 20, width: 28 }} />
        </div>
      </Placeholder>
    );
  }

  return (
    <div className={cx('p-3', { 'card-body': opened })}>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => setOpened(!opened)}
      >
        <div className="d-flex gap-3">
          <Image className="img" src={(teamData?.images || [])[0]?.url || defaultImage} />
          <div className="d-flex flex-column">
            <div className="text-white">{teamData?.abbreviation || teamData?.name}</div>
            <div className="text-secondary">
              {t('VIEW_LINEUP')}
              <HiChevronDown style={{ transform: `rotate(${opened ? '180deg' : '0deg'})` }} />
            </div>
          </div>
        </div>
        <Image
          src={(teamData?.region?.country?.images || [])[0]?.url}
          alt={teamData?.region?.country?.abbreviation}
          style={{ height: 20, width: 28 }}
        />
      </div>
      {opened && line_up.players.map(p => (
        <PlayerDetails key={p.id} id={p.id} />
      ))}
    </div>
  );
};

TeamDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    game: PropTypes.shape({
      id: PropTypes.number,
    }),
    line_up: PropTypes.shape({
      players: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
      })),
    }),
  }).isRequired
};

export { TeamDetails };
