import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Image, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import TimerIcon from '../../../assets/images/icons/timer-icon.svg';
import EyeIcon from '../../../assets/images/icons/eye-icon.svg';

import { NOTIFICATIONS_ACTION } from '../../../data/constants';
import { getActivityMetadata } from '../../../helpers/utils';
import { pages, routes } from '../../../data/menu';

const NotificationItem = ({ data: { action, metadata, createdAt, readAt }, onSetRead }) => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const handleOpenMatch = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(routes[pages.matchDetails].replace(':matchId', metadata.serie));
  }, [metadata.serie]);

  if (!Object.values(NOTIFICATIONS_ACTION).includes(action)) {
    return null;
  }

  return (
    <Dropdown.Item onClick={readAt ? undefined : onSetRead} className={readAt ? 'cursor-none' : undefined}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex text-nowrap fs-smallest align-items-center" title={moment(createdAt).format('LLL')}>
          <Image src={TimerIcon} alt="" className="me-1" style={{ height: 12, marginTop: -1 }} />
          {moment(createdAt).from(moment())}
        </div>
        {!readAt && (
          <Badge bg="danger" title={t('NOTIFICATIONS.TABS.UNREAD')} />
        )}
      </div>
      <div className="text-white fs-normal mt-1">{t(`ACTIVITIES.ITEMS_TITLE.${action}`)}</div>
      <div className="fs-smallest mt-1">
        {t(`ACTIVITIES.ITEMS_DESCRIPTION.${action}`, getActivityMetadata(metadata, language))}
      </div>
      {[NOTIFICATIONS_ACTION.MATCH_WON, NOTIFICATIONS_ACTION.MATCH_LOST].includes(action) && (
        <div
          className="mt-2 text-secondary cursor-pointer d-flex align-items-center"
          onClick={handleOpenMatch}
        >
          <Image src={EyeIcon} alt="" className="me-1" style={{ height: 12 }} />
          <span className="text-decoration-underline fs-smallest">{t('VIEW_MATCH')}</span>
        </div>
      )}
    </Dropdown.Item>
  );
};

NotificationItem.propTypes = {
  data: PropTypes.shape({
    action: PropTypes.string.isRequired,
    metadata: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    readAt: PropTypes.string
  }).isRequired,
  onSetRead: PropTypes.func.isRequired,
};

export { NotificationItem };
