import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import { redemptionsActions } from '../slices';

export function* loadDataFlow({ payload: { uid, ...params } }) {
  try {
    const data = yield call(api.loadRedemptions, uid, params);
    yield put(redemptionsActions.loadData.success(data));
  } catch (error) {
    yield put(redemptionsActions.loadData.failed({ error }));
  }
}
export function* approveFlow({ payload: { id } }) {
  try {
    yield call(api.approveRedemption, id);
    yield put(redemptionsActions.approve.success({ id }));
  } catch (error) {
    yield put(redemptionsActions.approve.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeEvery(redemptionsActions.loadData.types.BASE, loadDataFlow),
    takeLatest(redemptionsActions.approve.types.BASE, approveFlow),
  ]);
}
