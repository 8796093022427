import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, ProgressBar, Image } from 'react-bootstrap';

import Diamond from '../../../../assets/images/diamonds/diamond.svg';
import { missionsActions } from '../../../../redux/slices';
import { pages, routes } from '../../../../data/menu';
import { REWARDS_TABS } from '../../../../data/constants';

const MissionWidgetItem = ({ noMargin, data: {
  _id, title, description, price, conditions, userCompletion
} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n: { language } } = useTranslation();

  const handleGoToMissions = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`${routes[pages.rewards]}?mission=${_id}#${REWARDS_TABS.MISSIONS}`, {
      replace: location.pathname.includes(pages.rewards)
    });
  }, [_id, location]);

  const handleClaimMission = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(missionsActions.claimMission.base({ id: _id }));
  }, [_id]);

  const progress = conditions.reduce((sum, next) => (
    sum + ((next?.userCompletion?.isClaimed || next?.missionRef?.userCompletion?.isClaimed) ? next.progress : 0)
  ), 0);

  return (
    <Card.Body
      className={cx('cursor-pointer', {
        'mb-2': !noMargin,
        active: userCompletion?.readyToClaim,
        disabled: userCompletion?.isClaimed
      })}
      onClick={handleGoToMissions}
    >
      <Card.Title className="mb-2">{title[language]}</Card.Title>
      <Card.Text className="mb-2">{description[language]}</Card.Text>
      {conditions.length > 1 && (
        <div className="card-progress mb-2">
          <span>{`${progress}/100`}</span>
          <ProgressBar now={parseInt((progress / 100) * 100, 10)} />
        </div>
      )}
      <div className="card-divider" />
      <div className="card-buttons">
        {userCompletion?.readyToClaim ? (
          <Button className="small text-uppercase fw-bold" variant="outline-primary" onClick={handleClaimMission}>
            {t('REWARDS.CLAIM')}
          </Button>
        ) : (
          <div className="fs-small">
            {t('REWARDS.TEXT')}
            :
          </div>
        )}
        <div className="diamonds">
          <Image src={Diamond} alt="" />
          <span>{price}</span>
        </div>
      </div>
    </Card.Body>
  );
};

MissionWidgetItem.propTypes = {
  noMargin: PropTypes.bool,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
    conditions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      amount: PropTypes.number,
      progress: PropTypes.number,
      missionRef: PropTypes.object,
    })).isRequired,
    userCompletion: PropTypes.shape({
      isClaimed: PropTypes.bool,
      readyToClaim: PropTypes.bool,
    })
  }).isRequired
};

MissionWidgetItem.defaultProps = {
  noMargin: false
};

export { MissionWidgetItem };
