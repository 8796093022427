import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'react-bootstrap';

import { IoCheckmarkSharp } from 'react-icons/io5';
import './styles.scss';

const Checkbox = ({ value, onChange, label, ...props }) => (
  <Button
    className={cx('btn-midnight', 'hovered', 'btn-checkbox', { checked: value })}
    onClick={() => onChange(!value)}
    {...props}
  >
    <div className="icon">
      {value && <IoCheckmarkSharp />}
    </div>
    <div className="text">
      {label}
    </div>
  </Button>
);

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export { Checkbox };
