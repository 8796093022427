import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import { seriesActions, seriesSelectors } from '../../../redux/slices';
import { TEAM_MATCHES_TABS as TABS } from '../../../data/constants';
import { useGames, useScroll } from '../../../hooks';

import { MatchStatus } from '../../filters/MatchStatus';
import { TeamMatchDetails } from './TeamMatchDetails';
import { LoadingSpinner } from '../../LoadingSpinner';

const TeamMatches = ({ teamId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(TABS.SCHEDULED);
  const isLoading = useSelector(state => seriesSelectors.selectIsLoading(state, 'teamMatches'));
  const { data, total, page } = useSelector(seriesSelectors.selectTeamMatches);

  const options = useMemo(() => Object.keys(TABS).map(k => ({ label: k, value: TABS[k] })), []);

  const handleLoadData = (payload = {}) => dispatch(seriesActions.loadTeamMatches.base({
    teamId,
    status: tab,
    page: page + 1,
    ...payload
  }));

  useEffect(() => {
    handleLoadData({ page: 1 });
  }, [teamId, tab]);

  useGames();

  useScroll(() => {
    if (data.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data.length, total, isLoading]);

  return (
    <Card bg="dark" className="large-card mt-20">
      <Card.Header>
        <div className="mb-3">
          {t('TEAMS.TITLE_MATCHES')}
        </div>
        <MatchStatus
          options={options}
          value={tab}
          onChange={setTab}
        />
      </Card.Header>
      <div style={{ maxHeight: 650, overflowY: 'auto' }}>
        {!isLoading && data?.map((id, ind) => (
          <TeamMatchDetails
            key={id}
            id={id}
            hasMargin={ind !== data.length - 1}
          />
        ))}
      </div>
      {data?.length === 0 && !isLoading && (
        <div className="text-white text-center pb-4">
          {t('MATCHES.NO_RESULTS')}
        </div>
      )}
      {data?.length < total && !isLoading && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {isLoading && (
        <LoadingSpinner />
      )}
    </Card>
  );
};

TeamMatches.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export { TeamMatches };
