const EVENTS = {
  PREDICTIONS: 'predictions',
  CREATE_TIP: 'create-tip',
  UPDATE_TIP: 'update-tip',
  DELETE_TIP: 'delete-tip',
  UPDATE_CURRENT_USER: 'update-current-user',
  NEW_NOTIFICATION: 'new-notification',
  USER_DIAMONDS_CHANGED: 'user-diamonds-changed',
  SERIE_UPDATED: 'serie-updated',
  MATCH_UPDATED: 'match-updated',
  TEAM_UPDATED: 'team-updated',
  PLAYER_UPDATES: 'player-updated',
  TOURNAMENT_UPDATED: 'tournament-updated',
  MARKETPLACE_ITEM: 'marketplace-item',
  MISSION: 'mission',
};

export { EVENTS };
