import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'react-bootstrap';
import { MdEdit, MdDelete, MdMoreVert } from 'react-icons/md';

import { tipsActions } from '../../../redux/slices';
import { TIPS_MODAL } from '../../../data/constants';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const TipsEditor = ({ align, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    dispatch(tipsActions.setTipModal.base({ mode: TIPS_MODAL.DELETE, data }));
  }, [data]);

  const handleEdit = useCallback(() => {
    dispatch(tipsActions.setTipModal.base({ mode: TIPS_MODAL.EDIT, data }));
  }, [data]);

  return (
    <Dropdown align={align}>
      <Dropdown.Toggle
        as={CustomToggle}
        className="d-flex align-items-center justify-content-center cursor-pointer ps-1 pe-1 h-100"
      >
        <MdMoreVert className="text-secondary" />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item
          className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
          onClick={handleEdit}
        >
          <MdEdit />
          {t('EDIT')}
        </Dropdown.Item>
        <Dropdown.Item
          className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
          onClick={handleDelete}
        >
          <MdDelete />
          {t('DELETE')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

TipsEditor.propTypes = {
  align: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export { TipsEditor };
