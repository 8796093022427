import { all, fork } from 'redux-saga/effects';

import tips from './tips';
import series from './series';
import matches from './matches';
import teams from './teams';
import missions from './missions';
import user from './user';
import notifications from './notifications';
import tournaments from './tournaments';
import marketplace from './marketplace';
import redemptions from './redemptions';
import errorHandler from './errorHandler';

export default function* root() {
  yield all([
    fork(tips),
    fork(series),
    fork(matches),
    fork(teams),
    fork(missions),
    fork(user),
    fork(notifications),
    fork(tournaments),
    fork(marketplace),
    fork(redemptions),
    fork(errorHandler)
  ]);
}
