import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Row, Col, Accordion } from 'react-bootstrap';

import { GameFilter, CountriesFilter } from '../../filters';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TeamRow, TeamRowPlaceholder } from '../TeamRow';

import { matchesSelectors, teamsActions, teamsSelectors } from '../../../redux/slices';
import { ALL_FILTER } from '../../../data/constants';
import { useGames, useScroll } from '../../../hooks';

const TeamsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [filterGame, setFilterGame] = useState(ALL_FILTER);
  const [filterCountry, setFilterCountry] = useState(ALL_FILTER);
  const [filterPage, setFilterPage] = useState(0);

  const games = useSelector(matchesSelectors.selectGames);
  const countries = useSelector(teamsSelectors.selectCountries);
  const teamsLoading = useSelector(teamsSelectors.selectIsLoading);
  const teams = useSelector(state => teamsSelectors.selectByCountry(state, filterGame + filterCountry));

  const totalTeams = useSelector(state => (
    teamsSelectors.selectTotalByCountry(state)[filterGame + filterCountry]
  ));
  const lastPages = useSelector(teamsSelectors.selectLastPage);
  const lastPage = useSelector(state => (
    teamsSelectors.selectLastPage(state)[filterGame + filterCountry]
  ));

  useGames();

  useEffect(() => {
    if (!countries?.length) {
      dispatch(teamsActions.loadCountries.base());
    }
  }, [countries]);

  const handleLoadData = payload => dispatch(teamsActions.loadTeams.base(payload));

  useEffect(() => {
    const page = lastPages[filterGame + filterCountry] || 0;
    if (page === 0) {
      handleLoadData({ page, game: filterGame, country: filterCountry });
    }
    setFilterPage(page);
  }, [filterCountry, filterGame]);

  const handleLoad = () => {
    handleLoadData({ page: filterPage + 1, game: filterGame, country: filterCountry });
    setFilterPage(filterPage + 1);
  };

  useScroll(() => {
    if (!teamsLoading && filterPage === lastPage && !totalTeams) {
      handleLoad();
    }
  }, [teamsLoading, filterPage, lastPage, totalTeams]);

  return (
    <Accordion defaultActiveKey="teams-list" flush>
      <Accordion.Item eventKey="teams-list">
        <Accordion.Header className="d-lg-none">
          {t('TEAMS.TITLE')}
        </Accordion.Header>
        <Accordion.Body>
          <div className="show-grid mb-20">
            <h3 className="mb-3 d-none d-lg-block text-nowrap">{t('TEAMS.TITLE')}</h3>
            <Row>
              <Col xs={8} md={4} className="mb-2">
                <GameFilter
                  options={games}
                  selection={filterGame === ALL_FILTER ? [] : [filterGame]}
                  setSelection={val => setFilterGame(`${val}` === '' ? ALL_FILTER : val[val.length - 1])}
                />
              </Col>
              <Col xs={4} md={8} className="d-flex justify-content-end">
                <CountriesFilter
                  options={countries}
                  value={filterCountry}
                  onChange={setFilterCountry}
                />
              </Col>
            </Row>
          </div>
          <div className="mb-20">
            {!teamsLoading && teams.length === 0 && (
              <div className="text-white text-center">
                {t('TEAMS.NO_RESULTS')}
              </div>
            )}
            {teams.length > 0 && teams.map(item => (
              <TeamRow key={item.id} data={item} />
            ))}
          </div>
          {teamsLoading && !teams.length && (
            <LoadingSpinner />
          )}
          {teamsLoading && teams.length > 0 && (
            <>
              <TeamRowPlaceholder />
              <TeamRowPlaceholder />
              <TeamRowPlaceholder />
            </>
          )}
          {!totalTeams && !teamsLoading && teams.length > 0 && (
            <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoad}>
              <div className="p-2 cursor-pointer">
                {t('LOAD_MORE')}
              </div>
            </div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export { TeamsList };
