import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { userSelectors } from '../../redux/slices';

import Diamond1 from '../../assets/images/diamonds/diamond-1.svg';
import Diamond2 from '../../assets/images/diamonds/diamond-2.svg';
import Diamond3 from '../../assets/images/diamonds/diamond-3.svg';
import Diamond4 from '../../assets/images/diamonds/diamond-4.svg';
import Diamond5 from '../../assets/images/diamonds/diamond-5.svg';
import Diamond6 from '../../assets/images/diamonds/diamond-6.svg';
import Diamond7 from '../../assets/images/diamonds/diamond-7.svg';

import { MARKETPLACE_RARITY } from '../../data/constants';

const DailyLoginWidget = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const dailyLogin = useSelector(userSelectors.selectClaimDailyLogin);

  useEffect(() => {
    setShow((dailyLogin?.diamonds || 0) > 0);
  }, [dailyLogin]);

  const handleClose = () => setShow(false);

  const bonusRarity = useMemo(() => {
    if (dailyLogin.day <= 0) return MARKETPLACE_RARITY.COMMON;
    if (dailyLogin.day <= 2) return MARKETPLACE_RARITY.UNCOMMON;
    if (dailyLogin.day <= 4) return MARKETPLACE_RARITY.RARE;
    if (dailyLogin.day <= 6) return MARKETPLACE_RARITY.EPIC;
    if (dailyLogin.day >= 7) return MARKETPLACE_RARITY.LEGENDARY;
    return MARKETPLACE_RARITY.COMMON;
  }, [dailyLogin.day]);

  const ImageDiamons = useMemo(() => {
    if (dailyLogin.day <= 1) return Diamond1;
    if (dailyLogin.day === 2) return Diamond2;
    if (dailyLogin.day === 3) return Diamond3;
    if (dailyLogin.day === 4) return Diamond4;
    if (dailyLogin.day === 5) return Diamond5;
    if (dailyLogin.day === 6) return Diamond6;
    if (dailyLogin.day >= 7) return Diamond7;
    return Diamond1;
  }, [dailyLogin.day]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="max-width-large"
      backdrop="static"
      keyboard={false}
    >
      <div className={cx('marketplace-item', 'p-5', bonusRarity)}>
        <div className={cx('modal-winner', bonusRarity)} />
        <div className={cx('rarity-borders', bonusRarity)}><div className="left-right" /></div>
        <div className={cx('rarity-borders', bonusRarity)}><div className="top-bottom" /></div>
        <div className="modal-header text-uppercase">
          <h2 className="m-auto">{t('REWARDS.DAILY_BONUS')}</h2>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Image src={ImageDiamons} alt="" style={{ height: 300 }} />
          <h3 className="mt-3 mb-3" style={{ color: 'inherit' }}>
            {t('REWARDS.YOU_WON', { count: dailyLogin.diamonds })}
          </h3>
          {/* <div>{t('REWARDS.YOU_VISIT_TIME', { count: dailyLogin.day })}</div> */}
          <Button className="btn-midnight text-uppercase mt-5" onClick={handleClose}>
            {t('CLOSE')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { DailyLoginWidget };
