import { all, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';

import * as api from '../../api';
import { matchesActions, seriesActions, seriesSelectors } from '../slices';

export function* loadGamesFlow() {
  try {
    const games = yield call(api.getGames);
    yield put(matchesActions.loadGames.success(games));
  } catch (error) {
    yield put(matchesActions.loadGames.failed({ error }));
  }
}

export function* loadPredictionsFlow({ payload: { ids } }) {
  try {
    const predictions = yield call(api.getBulkPredictions, ids);
    yield put(matchesActions.loadPredictions.success(predictions));
  } catch (error) {
    yield put(matchesActions.loadPredictions.failed({ error }));
  }
}

export function* loadSerieDetailsFlow({ payload: { serieId } }) {
  try {
    const existedIds = yield select(seriesSelectors.selectIds);
    if (!existedIds.includes(serieId)) {
      yield put(seriesActions.loadSeries.base({ ids: [serieId] }));
    }
    const [matches, predictions, streams] = yield all([
      call(api.getSerieMatches, serieId),
      call(api.getSeriePredictions, serieId),
      call(api.getSerieStreams, serieId),
    ]);
    const mapsIds = Array.from(new Set(matches.map(m => m.map?.id).filter(f => !!f)));
    const maps = mapsIds.length ? yield call(api.getMaps, mapsIds) : [];
    yield put(matchesActions.loadSerieDetails.success({ matches, predictions, streams, maps }));
  } catch (error) {
    yield put(matchesActions.loadSerieDetails.failed({ error }));
  }
}

export function* sendPredictionDataFlow({ payload }) {
  try {
    const { failedPredictions } = yield call(api.predict, payload);
    yield put(matchesActions.sendPredictionData.success());
    if (failedPredictions.length) {
      throw new Error(failedPredictions[0].message);
    }
  } catch (error) {
    yield put(matchesActions.sendPredictionData.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(matchesActions.loadGames.types.BASE, loadGamesFlow),
    takeEvery(matchesActions.loadPredictions.types.BASE, loadPredictionsFlow),
    takeLatest(matchesActions.loadSerieDetails.types.BASE, loadSerieDetailsFlow),
    takeLatest(matchesActions.sendPredictionData.types.BASE, sendPredictionDataFlow),
  ]);
}
