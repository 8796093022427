import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'react-bootstrap';
import { MdEdit, MdDelete, MdMoreVert } from 'react-icons/md';

import { getDocumentPath } from '../../../api';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const MarketplaceItem = ({ data, lang, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <tr className={cx({ 'text-decoration-line-through': Boolean(data.deletedAt) })}>
      <td>{data.order}</td>
      <td>{data.slug[lang] || '-'}</td>
      <td>{data.rarity.toUpperCase()}</td>
      <td>{data.price}</td>
      <td>{data.quantity}</td>
      <td>{data.chanceVisible ? 'True' : 'False'}</td>
      <td>{data.countries?.join(', ') || '-'}</td>
      <td>
        {data.items.map(it => (
          <div className="d-flex align-items-center gap-2 mb-2">
            <div
              style={{
                backgroundImage: `url("${getDocumentPath(it.image)}")`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: 60,
                width: 40,
                border: '1px solid #f2f2f2'
              }}
            />
            <div className="d-flex flex-column" style={{ color: it.color }}>
              {it.slug[lang]}
              <span className="fs-smallest">{`${t('MARKETPLACE.CHANCE')}: ${it.chance}%`}</span>
              <span className="fs-smallest">{`${t('MARKETPLACE.QUANTITY')}: ${it.quantity || 0}`}</span>
              {it.codes?.length > 0 && (
                <span className="fs-smallest">
                  {`${t('MARKETPLACE.LEFT_CODES')}: ${it.codes.filter(c => !c.used).length}`}
                </span>
              )}
            </div>
          </div>
        ))}
      </td>
      <td>
        {data.deletedAt && t('MARKETPLACE.STATUS_DELETED')}
        {!data.deletedAt && data.quantity === 0 && t('MARKETPLACE.STATUS_ENDED')}
        {!data.deletedAt && data.quantity > 0 && t('MARKETPLACE.STATUS_ACTIVE')}
      </td>
      <td>
        {!data.deletedAt && (
          <Dropdown align="start" className="pt-1">
            <Dropdown.Toggle
              as={CustomToggle}
              className="d-flex align-items-center justify-content-center cursor-pointer ps-1 pe-1 h-100"
            >
              <MdMoreVert className="text-secondary" />
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item
                className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
                onClick={onEdit}
              >
                <MdEdit />
                {t('EDIT')}
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex gap-2 align-items-center justify-content-start text-decoration-none text-white"
                onClick={onDelete}
              >
                <MdDelete />
                {t('DELETE')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

MarketplaceItem.propTypes = {
  lang: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export { MarketplaceItem };
