import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TipsterRank, TipsList } from '../components/Tips';

import { MATCH_DETAILS_TABS as TABS } from '../data/constants';

import {
  MatchDetails,
  MatchChatWidget,
  MatchWidget,
  MatchWidgetSmall,
  MatchLiveStatsWidget,
  MatchLiveTimelineWidget,
  MatchPregameStatsWidget,
  PredictionSlip,
} from '../components/Match';
// import { PartnerBanner } from '../components/PartnerBanner';

import { matchesActions } from '../redux/slices';
import { useGames, useScrollTop } from '../hooks';

const MatchDetailsPage = () => {
  const { matchId } = useParams();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState();

  useGames();
  useScrollTop();

  const handleChangePage = useCallback(page => {
    navigate({ hash: page }, { replace: true });
  }, [hash]);

  useEffect(() => {
    const page = hash.replace('#', '');
    if (Object.values(TABS).includes(page)) {
      setPage(page);
    } else {
      navigate({ hash: TABS.PREDICTIONS }, { replace: true });
    }
  }, [hash]);

  useEffect(() => {
    dispatch(matchesActions.loadSerieDetails.base({ serieId: matchId }));
    return () => dispatch(matchesActions.clearSerieDetails.base());
  }, [matchId]);

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MatchWidgetSmall matchId={matchId} className="mb-20 mt-20" />
        <MatchChatWidget className="mb-20" />
        {/* <PartnerBanner className="mb-20" partner={PARTNERS.BETWAYESPORTS} /> */}
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <MatchWidget matchId={matchId} className="mb-20 mt-20" />
        <Tabs fill activeKey={page} onSelect={handleChangePage} className="mb-20">
          {Object.keys(TABS).map(tab => (
            <Tab key={tab} eventKey={TABS[tab]} title={t(`MATCH_DETAILS.TABS.${tab}`)} />
          ))}
        </Tabs>
        {page === TABS.PREDICTIONS && (
          <MatchDetails matchId={matchId} />
        )}
        {page === TABS.LIVE_STATISTICS && (
          <>
            <MatchLiveTimelineWidget className="mb-20" matchId={matchId} />
            <MatchLiveStatsWidget matchId={matchId} />
          </>
        )}
        {page === TABS.PRE_MATCH_INFO && (
          <MatchPregameStatsWidget matchId={matchId} />
        )}
        {page === TABS.TIPS && (
          <TipsList serieId={matchId} title={t('TIPS.TIPS_BOARD')} withUsernameDate />
        )}
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { MatchDetailsPage };
