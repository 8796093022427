import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TOKEN_STORAGE } from '../data/constants';
import { userActions } from '../redux/slices';
import { useDeepEffect } from './useDeepEffect';

const useCheckAuth = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [checkEmail, setCheckEmail] = useState(0);
  const { user, getAccessTokenSilently, logout } = useAuth0();
  useDeepEffect(() => {
    if (!user || checkEmail === 2) {
      (async () => {
        try {
          const token = await getAccessTokenSilently({ ignoreCache: checkEmail });
          localStorage.setItem(TOKEN_STORAGE, token);
          setCheckEmail(checkEmail + 1);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          localStorage.removeItem(TOKEN_STORAGE);
        }
      })();
    } else if (!user.email_verified) {
      if (checkEmail <= 1) {
        setCheckEmail(2);
      } else if (checkEmail <= 3) {
        setCheckEmail(checkEmail + 1);
        dispatch(userActions.setEmailVerified.base(false));
      }
    } else if (localStorage.getItem(TOKEN_STORAGE)) {
      dispatch(userActions.loadUserDetails.base());
    }
  }, [user, getAccessTokenSilently, checkEmail]);
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.get('error')) {
      logout();
    }
  }, [search, logout]);
};

export { useCheckAuth };
