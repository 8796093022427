import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { PredictionSlip } from '../components/Match';
import { MissionListWidget } from '../components/Rewards/Missions';
import { LatestTips, TipsterRank } from '../components/Tips';
import { TournamentsList } from '../components/Tournament';
import { useScrollTop } from '../hooks';

const TournamentsPage = () => {
  useScrollTop();

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <TournamentsList />
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { TournamentsPage };
