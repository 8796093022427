import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const IconButton = ({ icon, onClick }) => (
  <div className="icon-button" onClick={onClick}>
    {icon}
  </div>
);

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { IconButton };
