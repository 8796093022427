import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { MdOutlineLibraryBooks } from 'react-icons/md';

import DefaultHeroImage from '../../../../assets/images/demo/leaderboard-hero-default.png';

import '../styles.scss';

const Hero = ({ className, data: { title, subtitle, diamonds, image } }) => {
  const { t } = useTranslation();
  return (
    <div className={cx('hero', className)}>
      <div className="hero-info">
        <h1>{title}</h1>
        <div className="divider" />
        <h3>{subtitle}</h3>
        <h5>
          {t('LEADERBOARDS.EVENT.OPPORTUNITY_TO_WIN')}
          <span>{` ${parseInt(diamonds, 10).toLocaleString()} ${t('LEADERBOARDS.EVENT.DIAMONDS')}`}</span>
        </h5>
        <div className="btn-terms">
          <MdOutlineLibraryBooks />
          <div>{t('LEADERBOARDS.EVENT.TERMS')}</div>
        </div>
      </div>
      <div className="hero-image">
        <Image src={image || DefaultHeroImage} alt="" />
      </div>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    diamonds: PropTypes.number.isRequired,
    image: PropTypes.string
  }).isRequired
};

Hero.defaultProps = {
  className: undefined
};

export { Hero };
