import moment from 'moment';
import { createSlice } from '../../helpers/store';
import { getIsEnded, getIsStarted } from '../../helpers/utils';

const initialState = {
  dataMap: {},
  dataByDate: {},
  isLoadingMap: {
    byDate: false,
    teamMatches: false,
    tournamentMatches: false,
  },
  teamMatches: {
    data: [],
    page: 0,
    total: 0 // if sets, then all is loaded
  },
  tournamentMatches: {
    data: [],
    page: 0,
    total: 0 // if sets, then all is loaded
  },
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'series',
  initialState,

  selectors: {
    selectIds: state => Object.keys(state.dataMap).map(i => parseInt(i, 10)),
    selectSerieById: (state, _, id) => state.dataMap[id],
    selectSeriesByDate: (state, _, date) => (state.dataByDate[date] || [])
      .map(id => state.dataMap[id]).filter(r => !!r),
    selectIsLoadingMap: state => state.isLoadingMap,
    selectIsLoading: (state, _, id) => state.isLoadingMap[id],
    selectSerieStatus: (state, _, id) => ({
      isStarted: getIsStarted(state.dataMap[id]),
      isEnded: getIsEnded(state.dataMap[id]),
      start: state.dataMap[id]?.start,
      end: state.dataMap[id]?.end,
    }),
    selectTeamMatches: state => state.teamMatches,
    selectTournamentMatches: state => state.tournamentMatches,
  },

  reducers: {
    loadSeries: {
      base: (state, { payload: { ids } }) => {
        ids.forEach(id => {
          if (!state.dataMap[id]) {
            state.isLoadingMap[id] = true;
          }
        });
      },
      success: (state, { payload: { data, ids } }) => {
        data.forEach(item => {
          state.dataMap[item.id] = item;
          delete state.isLoadingMap[item.id];
        });
        ids.forEach(id => {
          delete state.isLoadingMap[id];
        });
      },
      failed: (state, { payload: { ids } }) => {
        ids.forEach(id => {
          delete state.isLoadingMap[id];
        });
      },
    },
    loadSeriesByDate: {
      base: (state, { payload: { date } }) => {
        if (!state.dataByDate[date]) {
          state.isLoadingMap.byDate = true;
        }
      },
      success: (state, { payload: { date, data } }) => {
        const ids = [];
        data.forEach(item => {
          state.dataMap[item.id] = item;
          ids.push(item.id);
        });
        state.dataByDate[date] = ids;
        state.isLoadingMap.byDate = false;
      },
      failed: state => {
        state.isLoadingMap.byDate = false;
      }
    },
    updateFromSocket: {
      base: (state, { payload: { id, dateChanged, data } }) => {
        if (state.dataMap[id]) {
          if (dateChanged) {
            const oldDate = moment(state.dataMap[id].start).format('YYYY-MM-DD');
            const newDate = moment(data.start).format('YYYY-MM-DD');
            const index = (state.dataByDate[oldDate] || []).findIndex(dId => dId === id);
            if (index > -1) {
              state.dataByDate[oldDate].splice(index, 1);
            }
            if (state.dataByDate[newDate]) {
              state.dataByDate[newDate].push(id);
            }
          }
          state.dataMap[id] = data;
        }
      }
    },
    loadTeamMatches: {
      base: state => {
        state.isLoadingMap.teamMatches = true;
      },
      success: (state, { payload: { data, page } }) => {
        state.isLoadingMap.teamMatches = false;
        state.teamMatches.data = (page === 1 ? [] : state.teamMatches.data).concat(data.map(d => d.id));
        state.teamMatches.page = page;
        if (data.length !== 50) {
          state.teamMatches.total = state.teamMatches.data.length;
        }
        data.forEach(item => {
          state.dataMap[item.id] = item;
        });
      },
      failed: state => {
        state.isLoadingMap.teamMatches = false;
      }
    },
    loadTournamentMatches: {
      base: state => {
        state.isLoadingMap.tournamentMatches = true;
      },
      success: (state, { payload: { data, page } }) => {
        state.isLoadingMap.tournamentMatches = false;
        state.tournamentMatches.data = (page === 1 ? [] : state.tournamentMatches.data).concat(data.map(d => d.id));
        state.tournamentMatches.page = page;
        if (data.length !== 50) {
          state.tournamentMatches.total = state.tournamentMatches.data.length;
        }
        data.forEach(item => {
          state.dataMap[item.id] = item;
        });
      },
      failed: state => {
        state.isLoadingMap.tournamentMatches = false;
      }
    }
  },
});

export { reducer as series, actions as seriesActions, selectors as seriesSelectors };
