import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BsCalendar } from 'react-icons/bs';
import { MdOutlineBarChart } from 'react-icons/md';
import { Col, Form, Placeholder } from 'react-bootstrap';

import { getPredictionPercent } from '../../../helpers/utils';

const TipCreationContent = ({ start, comment, setComment, teamA, teamB, predictions }) => {
  const { t } = useTranslation();
  const commentRef = useRef();

  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.focus();
    }
  }, [commentRef]);

  return (
    <>
      <Col xs={12} sm={4} className="mb-3 mb-sm-0">
        <div className="mb-2 text-uppercase text-secondary fs-small">{t('TIPS.MODAL.TIP_DETAILS')}</div>
        <div className="d-flex flex-wrap gap-3">
          <Placeholder
            className="d-flex flex-column text-secondary p-3 border fs-medium border-lightest"
            animation="glow"
            style={{ width: 180 }}
          >
            <BsCalendar style={{ fontSize: 20 }} className="mb-1" />
            <div>{start ? moment(start).format('dddd') : <Placeholder style={{ width: 50 }} />}</div>
            <div>{start ? moment(start).format('LL') : <Placeholder style={{ width: 70 }} />}</div>
          </Placeholder>
          <Placeholder
            className="d-flex flex-column text-secondary p-3 border fs-medium border-lightest"
            animation="glow"
            style={{ width: 180 }}
          >
            <MdOutlineBarChart style={{ fontSize: 20 }} className="mb-1" />
            <div>
              {teamA?.team
                ? `${teamA.team.abbreviation || teamA.team.name}: `
                  + `${getPredictionPercent(predictions, teamA.roster.id)}%`
                : <Placeholder style={{ width: 50 }} />}
            </div>
            <div>
              {teamB?.team
                ? `${teamB.team.abbreviation || teamB.team.name}: `
                  + `${getPredictionPercent(predictions, teamB.roster.id)}%`
                : <Placeholder style={{ width: 50 }} />}
            </div>
          </Placeholder>
        </div>
      </Col>
      <Form.Group as={Col} xs={12} sm={8}>
        <Form.Control
          ref={commentRef}
          as="textarea"
          placeholder={t('TIPS.MODAL.INPUT_HINT')}
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          rows={10}
          isInvalid={(comment.length < 5 || comment.length > 500) && comment.length}
        />
      </Form.Group>
    </>
  );
};

TipCreationContent.propTypes = {
  start: PropTypes.string.isRequired,
  teamA: PropTypes.object,
  teamB: PropTypes.object,
  predictions: PropTypes.shape({
    teamA: PropTypes.object,
    teamB: PropTypes.object,
  }),
  comment: PropTypes.string,
  setComment: PropTypes.func.isRequired,
};

TipCreationContent.defaultProps = {
  predictions: {},
  comment: '',
  teamA: {},
  teamB: {},
};

export { TipCreationContent };
