import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Image, Button, Row, Col, } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import DropdownIcon from '../../../assets/images/icons/chevron-down-2.svg';
import AccountEmpty from '../../../assets/images/icons/account-empty.svg';
import { DiamondsCount } from '../DiamondsCount';
import { Notifications } from '../Notifications';

import { userSelectors } from '../../../redux/slices';

const UserMenuSmall = ({ onOpenProfile }) => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const user = useSelector(userSelectors.selectUserDetails);

  if (!user) {
    return (
      <Row className="user-menu-small">
        <Col xs={6}>
          <Button className="btn-midnight selected" onClick={() => loginWithRedirect()}>
            {t('TOPBAR.LOGIN')}
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-midnight" onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
            {t('TOPBAR.SIGNUP')}
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="user-menu-small">
      <Col xs={7} className="split cursor-pointer">
        <ButtonGroup className="align-items-center text-secondary user-menu navbar-btn-small w-100">
          <Image src={user?.profile?.avatar || AccountEmpty} alt={user?.username} />
          <div className="ps-2 d-flex justify-content-center flex-column" onClick={onOpenProfile}>
            <div className="name">{user?.username}</div>
            <div className="account-details">
              {t('TOPBAR.PROFILE.ACCOUNT_DETAILS')}
              <Image src={DropdownIcon} />
            </div>
          </div>
        </ButtonGroup>
      </Col>
      <Col xs={3} className="d-flex split cursor-pointer">
        <DiamondsCount className="m-auto" count={user?.wallet?.diamonds} />
      </Col>
      <Col xs={2} className="d-flex cursor-pointer">
        <Notifications className="m-auto" />
      </Col>
    </Row>
  );
};

UserMenuSmall.propTypes = {
  onOpenProfile: PropTypes.func.isRequired
};

export { UserMenuSmall };
