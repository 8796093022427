import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTournamentTitle } from '../../../../helpers/utils';

const TournamentFilterModal = ({ show, handleClose, games, options, selection, onChange }) => {
  const { t } = useTranslation();

  const optionsByGame = useMemo(() => games.reduce((map, game) => ({
    ...map,
    [game.id]: options.filter(item => item.game?.id === game.id)
  }), {}), [games, options]);

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="max-width-medium">
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{t('TOURNAMENTS.SELECT_TOURNAMENTS')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {games.map(game => optionsByGame[game.id]?.length ? (
          <div className="d-flex flex-column gap-3 mb-5" key={game.id}>
            <h3 className="d-flex flex-row align-items-center gap-3 fs-6 fw-bold">
              <Image
                src={game.images.find((i) => i.type === 'circle')?.url}
                alt={game.abbreviation}
                style={{ height: 24 }}
              />
              {game.title}
            </h3>
            <div className="d-flex gap-2 flex-wrap ">
              {optionsByGame[game.id]?.map(item => (
                <Button
                  key={item.id}
                  className={cx('btn-midnight', { selected: selection.includes(item.id) })}
                  onClick={onChange(item)}
                  title={getTournamentTitle(item.title)}
                >
                  {item.short_title}
                </Button>
              ))}
            </div>
          </div>
        ) : null)}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-midnight" onClick={handleClose}>
          {t('SAVE_FILTERS')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TournamentFilterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    abbreviation: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired
  })).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    short_title: PropTypes.string.isRequired,
    game: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  })).isRequired,
  selection: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { TournamentFilterModal };
