import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const NoAccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => navigate(-1), []);

  return (
    <div className="d-flex flex-column p-5 w-50 m-auto gap-5 text-center">
      <h3>{t('YOU_HAVE_NOT_ACCESS')}</h3>
      <Button className="btn-midnight" onClick={handleGoBack}>
        {t('GO_BACK')}
      </Button>
    </div>
  );
};

export { NoAccess };
