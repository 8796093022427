import React from 'react';
import PropTypes from 'prop-types';

import '../styles.scss';

const CardWithIcon = ({ icon, text, subtext }) => (
  <div className="time-prize-card">
    <div className="icon">{icon}</div>
    <div className="content">
      <div>
        <div className="text">{text}</div>
        <div className="subtext">{subtext}</div>
      </div>
    </div>
  </div>
);

CardWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  subtext: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export { CardWithIcon };
