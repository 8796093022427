import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { userActions, userSelectors } from '../../../redux/slices';
import { LoadingSpinner } from '../../LoadingSpinner';
import { PROFILE_FOLLOW_TYPE } from '../../../data/constants';

const FollowDialog = ({ type, username, isCurrentUser, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector(state => userSelectors.selectUserProfileFollowData(state)[type]);
  const isLoading = useSelector(userSelectors.selectUserProfileFollowLoading);

  useEffect(() => {
    dispatch(userActions.loadFollowData.base({ username, type }));
  }, []);

  const handleRemove = useCallback(item => () => {
    if (type === PROFILE_FOLLOW_TYPE.FOLLOWERS) {
      dispatch(userActions.removeFollower.base({ username: item.username }));
    } else {
      dispatch(userActions.unfollowUser.base({ username: item.followingUsername, isCurrentUser }));
    }
  }, [type, isCurrentUser]);

  let title = '';
  if (type === PROFILE_FOLLOW_TYPE.FOLLOWERS) {
    title = isCurrentUser ? t('PROFILE.FOLLOWERS_MY') : t('PROFILE.FOLLOWERS');
  } else {
    title = isCurrentUser ? t('PROFILE.FOLLOWINGS_MY') : t('PROFILE.FOLLOWINGS');
  }

  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{`${title} ${data?.length ? `(${data.length})` : ''}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <LoadingSpinner />
        )}
        {!isLoading && !data?.length && (
          <div className="text-white text-center">
            {t('PROFILE.NO_FOLLOWERS')}
          </div>
        )}
        {(data || []).map(item => (
          <div className="d-flex justify-content-between mb-20" key={item._id} style={{ height: 40 }}>
            <div className="d-flex align-items-center gap-2">
              <Image className="img-smallest" src={item.followingAvatar} alt="" />
              <div className="text-nowrap text-white">
                {type === PROFILE_FOLLOW_TYPE.FOLLOWERS ? item.username : item.followingUsername}
              </div>
            </div>
            {isCurrentUser && (
              <Button className="btn-midnight text-uppercase" onClick={handleRemove(item)}>
                {t('DELETE')}
              </Button>
            )}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-midnight selected text-uppercase" onClick={onClose}>
          {t('CLOSE')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FollowDialog.propTypes = {
  type: PropTypes.oneOf(Object.values(PROFILE_FOLLOW_TYPE)).isRequired,
  username: PropTypes.string.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { FollowDialog };
