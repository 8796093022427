import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Button, Card, Image, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  matchesActions,
  matchesSelectors,
  seriesSelectors,
  teamsSelectors,
  userSelectors
} from '../../../redux/slices';
import { getIsEnded } from '../../../helpers/utils';
import { pages, routes } from '../../../data/menu';

const TeamMatchDetails = ({ id, hasMargin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const data = useSelector(state => seriesSelectors.selectSerieById(state, id));
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const userData = useSelector(userSelectors.selectUserDetails);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, id));
  const [teamA, teamB] = data?.participants || [];
  const teamAData = useSelector(state => (
    teamsSelectors.selectTeamByRoster(state, (data?.participants || [])[0]?.roster?.id)
  ));
  const teamBData = useSelector(state => (
    teamsSelectors.selectTeamByRoster(state, (data?.participants || [])[1]?.roster?.id)
  ));

  const isEnded = getIsEnded(data);
  const defaultImage = gamesImagesMap[data?.game?.id];

  const handleVoteTeam = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    if (!userData) {
      loginWithRedirect();
    } else {
      dispatch(matchesActions.addPredictionData.base({
        prediction: {
          team: teamAData.id === id ? teamAData.id : teamBData.id,
          roster: teamAData.id === id ? teamAData.roster.id : teamBData.roster.id,
          game: data.game.id,
          serie: data.id,
          prediction: 'serie',
        },
        participants: [teamAData, teamBData]
      }));
    }
  }, [userData, teamAData, teamBData, data, id]);

  const handleOpenMatch = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(routes[pages.matchDetails].replace(':matchId', id));
  }, [id]);

  if (!data) {
    return (
      <Placeholder
        as={Card.Body}
        animation="glow"
        className={cx('d-flex justify-content-between align-items-center', { 'mb-2': hasMargin })}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2 align-items-center">
            <Placeholder style={{ width: 32 }} />
            <Placeholder style={{ width: 100 }} />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <Placeholder style={{ width: 32 }} />
            <Placeholder style={{ width: 100 }} />
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center text-secondary">
          <AiOutlineClockCircle style={{ height: 17, width: 17 }} />
          <div className="d-flex flex-column gap-2">
            <Placeholder style={{ width: 30, minHeight: 10 }} />
            <Placeholder style={{ width: 60, minHeight: 10 }} />
          </div>
        </div>
      </Placeholder>
    );
  }

  return (
    <Card.Body className={cx('cursor-pointer', { 'mb-2': hasMargin })} onClick={handleOpenMatch}>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2 align-items-center text-secondary">
          <AiOutlineClockCircle style={{ height: 17, width: 17 }} />
          <div className="fs-small">{moment(data?.start).format('LL')}</div>
          <div className="fs-small">-</div>
          <div className="fs-small">{moment(data?.start).startOf('day').from(moment())}</div>
        </div>
        {!isEnded && !predictions?.myVote && (
          <Button
            variant="outline-primary"
            onClick={handleVoteTeam}
          >
            {t('MATCHES.VOTE_TEAM')}
          </Button>
        )}
      </div>
      <div className="d-flex gap-2 mt-3">
        <div className="d-flex gap-2 align-items-center col-4">
          <Image
            className="img-small"
            src={(teamAData?.images || [])[0]?.url || defaultImage}
            alt=""
          />
          <div>{teamAData?.abbreviation || teamAData?.name}</div>
        </div>
        <div className="score col-3">
          <div>{typeof teamA.score !== 'number' ? '**' : teamA.score}</div>
          <div>vs</div>
          <div>{typeof teamB.score !== 'number' ? '**' : teamB.score}</div>
        </div>
        <div className="d-flex gap-2 align-items-center col-4 justify-content-end">
          <Image
            className="img-small"
            src={(teamBData?.images || [])[0]?.url || defaultImage}
            alt=""
          />
          <div>{teamBData?.abbreviation || teamBData?.name}</div>
        </div>
      </div>
    </Card.Body>
  );
};

TeamMatchDetails.propTypes = {
  id: PropTypes.number.isRequired,
  hasMargin: PropTypes.bool.isRequired,
};

export { TeamMatchDetails };
