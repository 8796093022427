import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import { pages, routes } from '../../../data/menu';

const TeamRow = ({ data: { id, name, images, region } }) => {
  const navigate = useNavigate();

  const handleOpenDetails = useCallback(() => {
    navigate(routes[pages.teamDetails].replace(':teamId', id));
  }, [id]);

  return (
    <Row className="main-row cursor-pointer" onClick={handleOpenDetails}>
      <Col className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3">
          <div
            style={{
              height: 48,
              minWidth: 48,
              width: 48,
              backgroundImage: `url("${images[0]?.url}")`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '50%'
            }}
          />
          <div>{name}</div>
        </div>
        <div>
          <Image
            src={(region?.country?.images || [])[0]?.url}
            alt={region?.country?.abbreviation}
            style={{ height: 20, width: 28 }}
          />
        </div>
      </Col>
    </Row>
  );
};

TeamRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    region: PropTypes.shape({
      name: PropTypes.string.isRequired,
      country: PropTypes.shape({
        abbreviation: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        images: PropTypes.array.isRequired
      })
    }).isRequired,
  }).isRequired,
};

export { TeamRow };
