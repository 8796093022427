import { createSlice } from '../../helpers/store';
import { transformPredictionSlipDataInStore, updatePredictions } from '../../helpers/utils';

const initialState = {
  games: {
    isLoading: false,
    data: []
  },
  predictions: {},
  matchDetails: {
    isLoading: false,
    matches: null,
    streams: null,
    selectedStream: null,
    maps: null,
  },
  predictionSlipUpdating: false,
  predictionSlip: [],
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'matches',
  initialState,

  selectors: {
    selectGames: state => state.games.data,
    selectGamesImages: state => state.games.data?.reduce((map, next) => ({
      ...map,
      [next.id]: next.images.find((i) => i.type === 'circle')?.url || next.images[0]?.url
    }), {}),
    selectGamesLoading: state => state.games.isLoading,
    selectPredictions: state => state.predictions,
    selectPredictionSlip: state => state.predictionSlip,
    selectPredictionSlipUpdating: state => state.predictionSlipUpdating,
    selectPredictionSlipVotes: state => transformPredictionSlipDataInStore(state.predictionSlip, state.predictions),
    selectPredictionById: (state, _, id) => (
      transformPredictionSlipDataInStore(state.predictionSlip, state.predictions)[id]
      || state.predictions[id]
    ),
    selectMatchDetails: state => state.matchDetails,
    selectMatchMapsIndexes: state => (state.matchDetails.matches || []).map(item => item.index),
    selectMatchMaps: state => (state.matchDetails.maps || []).reduce((map, item) => ({ ...map, [item.id]: item }), {}),
    selectSelectedStream: state => state.matchDetails.selectedStream,
  },

  reducers: {
    loadGames: {
      base: (state) => {
        state.games.isLoading = true;
      },
      success: (state, { payload }) => {
        state.games.data = payload;
        state.games.isLoading = false;
      },
      failed: (state) => {
        state.games.isLoading = false;
      },
    },
    loadPredictions: {
      success: (state, { payload }) => {
        state.predictions = {
          ...state.predictions,
          ...payload
        };
      }
    },
    updatePredictionsByMessage: {
      base: (state, { payload }) => updatePredictions(state, payload)
    },
    addPredictionData: {
      base: (state, { payload }) => {
        state.predictionSlip.push(payload);
      }
    },
    removePredictionData: {
      base: (state, { payload: { serie, prediction, match } }) => {
        const index = state.predictionSlip.findIndex(data => (
          data.prediction.serie === serie
          && data.prediction.prediction === prediction
          && data.prediction.match === match
        ));
        if (index > -1) {
          state.predictionSlip.splice(index, 1);
        }
      }
    },
    sendPredictionData: {
      base: state => {
        state.predictionSlipUpdating = true;
      },
      success: state => {
        state.predictionSlip = [];
        state.predictionSlipUpdating = false;
      },
      failed: state => {
        state.predictionSlipUpdating = false;
      }
    },
    loadSerieDetails: {
      base: state => {
        state.matchDetails.isLoading = true;
      },
      success: (state, { payload: { matches, predictions, streams, maps } }) => {
        state.matchDetails.isLoading = false;
        state.matchDetails.matches = matches.map((data, index) => ({ ...data, index: index + 1 }));
        state.matchDetails.streams = streams;
        state.matchDetails.maps = maps;
        updatePredictions(state, predictions);
      },
      failed: state => {
        state.matchDetails.isLoading = false;
      }
    },
    clearSerieDetails: {
      base: state => {
        state.matchDetails = { ...initialState.matchDetails };
      }
    },
    updateFromSocket: {
      base: (state, { payload: { id, data } }) => {
        if (state.matchDetails.matches) {
          state.matchDetails.matches.forEach((item, ind) => {
            if (item.id === id) {
              state.matchDetails.matches[ind] = { ...data, index: ind + 1 };
            }
          });
        }
      },
    },
    setSelectedStream: {
      base: (state, { payload }) => {
        state.matchDetails.selectedStream = payload;
      }
    }
  },
});

export { reducer as matches, actions as matchesActions, selectors as matchesSelectors };
