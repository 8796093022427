import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import App from './app';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './components/auth/auth0-provider-with-history';
import './i18n';
import { store } from './redux/store';
import { LoadingSpinner } from './components/LoadingSpinner';

momentDurationFormatSetup(moment);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner absolute />}>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ReduxProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
