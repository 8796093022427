import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useQueryFilter } from '../../../hooks';
import { FILTERS } from '../../../data/constants';

import '../styles.scss';

const LocaleFilter = ({ selection, setSelection, className, ...props }) => {
  const { i18n: { languages, language } } = useTranslation();
  useMemo(() => languages.sort((a, b) => a.localeCompare(b)), [languages]);

  const { changeFilter } = useQueryFilter(FILTERS.LANG, setSelection, languages);

  const handleChangeFilter = lang => {
    setSelection(lang);
    changeFilter(lang);
  };

  const handleSelect = useCallback((lang) => () => {
    if (!lang) {
      handleChangeFilter(language);
    } else {
      handleChangeFilter(lang);
    }
  }, [selection, language]);

  return (
    <div className={cx('filters', className)} {...props}>
      {languages.map((lang) => (
        <Button
          key={lang}
          className={cx('btn-midnight', { selected: selection === lang })}
          onClick={handleSelect(lang)}
        >
          {lang.toUpperCase()}
        </Button>
      ))}
    </div>
  );
};

LocaleFilter.propTypes = {
  selection: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LocaleFilter.defaultProps = {
  className: undefined,
};

export { LocaleFilter };
