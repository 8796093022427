import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/documents`;

export const uploadDocument = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return request.post(`${api}`, formData).then(res => res.data?.success?.data?._id);
};

export const getDocumentPath = id => id ? `${api}/${id}` : undefined;
