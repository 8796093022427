import { useEffect } from 'react';

const useScroll = (callback, dependencies = []) => {
  const handleScroll = () => {
    if (window.document.body.scrollWidth < 982) {
      return;
    }
    if (window.document.body.scrollHeight - window.outerHeight - 300 < window.pageYOffset) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, dependencies);
};

export { useScroll };
