import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, Image } from 'react-bootstrap';

import { languages } from '../../../data/languages';
import DropdownIcon from '../../../assets/images/icons/chevron-down.svg';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className="d-flex flex-row cursor-pointer">
    {children}
    <Image src={DropdownIcon} alt="&#x25bc;" />
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const Languages = ({ className, ...props }) => {
  const { t, i18n: { language, changeLanguage } } = useTranslation();

  const currentLanguage = useMemo(() => languages.find((l) => l.key === language), [language]);

  const handleChangeLanguage = useCallback((key) => {
    changeLanguage(key);
    window.localStorage.setItem('language', key);
  }, []);

  return (
    <Dropdown
      as={ButtonGroup}
      className={`${className} align-items-center text-secondary`}
      onSelect={handleChangeLanguage}
      {...props}
    >
      <Dropdown.Toggle as={CustomToggle}>
        <Image src={currentLanguage.image} alt={currentLanguage.key.toUpperCase()} />
        <div className="ps-2">{currentLanguage.key.toUpperCase()}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        {languages.map((lang) => (
          <Dropdown.Item key={lang.key} eventKey={lang.key} className="d-flex">
            <Image src={lang.image} alt={lang.key.toUpperCase()} className="pe-3" />
            {t(`TOPBAR.LANG_SWITCHER.${lang.key.toUpperCase()}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Languages.propTypes = {
  className: PropTypes.string,
};

Languages.defaultProps = {
  className: '',
};

export { Languages };
