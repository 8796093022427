import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Image } from 'react-bootstrap';

import Diamond from '../../../assets/images/diamonds/diamond.svg';

const DiamondsCount = ({ className, count, ...props }) => (
  <ButtonGroup className={`${className} text-white cursor-pointer`} {...props}>
    <Image src={Diamond} alt="Diamond" />
    <div className="ps-2">{count}</div>
  </ButtonGroup>
);

DiamondsCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
};

DiamondsCount.defaultProps = {
  className: '',
  count: 0
};

export { DiamondsCount };
