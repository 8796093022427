import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Image, Placeholder } from 'react-bootstrap';

import EyeIcon from '../../../assets/images/icons/eye-icon.svg';
import ThumbsUpIcon from '../../../assets/images/icons/thumbs-up.svg';
import ThumbsDownIcon from '../../../assets/images/icons/thumbs-down.svg';
import TimerIcon from '../../../assets/images/icons/timer-icon.svg';

const TipsItemPlaceholder = ({ className, withLink }) => {
  const { t } = useTranslation();
  return (
    <Card bg="dark" className={cx('large-card', className)}>
      <Placeholder animation="glow">
        <Card.Body className="d-flex gap-3">
          <div className="d-flex flex-column gap-2">
            <div className="btn-like">
              <Image src={ThumbsUpIcon} alt="" style={{ width: 20, height: 20 }} />
            </div>
            <div className="btn-like">
              <Image src={ThumbsDownIcon} alt="" style={{ width: 20, height: 20 }} />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between flex-column">
            <div>
              <div className="card-actions mb-2 justify-content-start">
                <div className="d-flex">
                  {t('POSTED_BY')}
                  &nbsp;
                  <Placeholder style={{ width: 80 }} />
                </div>
                <div className="d-flex text-nowrap ms-2">
                  <Image src={TimerIcon} alt="" className="me-1" />
                  <Placeholder style={{ width: 50 }} />
                </div>
              </div>
              <Card.Title className="mb-2">
                <Placeholder xs={8} />
              </Card.Title>
              <Card.Text className="fs-small text-white">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
              </Card.Text>
            </div>
            {withLink && (
              <div>
                <div className="divider" />
                <div className="card-buttons">
                  <div className="text-secondary text-decoration-underline">
                    <Image src={EyeIcon} alt="" className="me-1" />
                    {t('VIEW_MATCH')}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex gap-2 flex-column">
            <Placeholder className="img-medium" style={{ borderRadius: '50%' }} />
            <div className="fs-medium score">vs</div>
            <Placeholder className="img-medium" style={{ borderRadius: '50%' }} />
          </div>
        </Card.Body>
      </Placeholder>
    </Card>
  );
};

TipsItemPlaceholder.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
};

TipsItemPlaceholder.defaultProps = {
  className: undefined,
  withLink: false
};

export { TipsItemPlaceholder };
