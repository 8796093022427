import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image, Button } from 'react-bootstrap';
import { BsFillCalendarFill } from 'react-icons/bs';
import { FaUserPlus, FaUserMinus } from 'react-icons/fa';
import { FiActivity } from 'react-icons/fi';
import moment from 'moment';

import { FollowDialog } from './FollowDialog';

import AccountEmpty from '../../../assets/images/icons/account-empty.svg';
import { PROFILE_FOLLOW_TYPE } from '../../../data/constants';
import { userActions } from '../../../redux/slices';

const MainSection = ({ className, isCurrentUser, data, onOpenEditProfile, onOpenActivities }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialogType, setDialogType] = useState(null);

  const handleFollow = useCallback(() => {
    if (!data?.isFollower) {
      dispatch(userActions.followUser.base({ username: data.username }));
    } else {
      dispatch(userActions.unfollowUser.base({ username: data.username }));
    }
  }, [data]);

  return (
    <div className={cx('profile-main-section', className)}>
      <div className="top-bottom" />
      <div className="left-section">
        <div className="avatar">
          <Image src={data?.profile?.avatar || AccountEmpty} alt="" />
        </div>
        <div className="info">
          <div className="name">{data?.username}</div>
          <div className="date">
            <BsFillCalendarFill />
            {data?.createdAt ? moment(data.createdAt).format('DD.MM.YYYY') : ''}
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="btn-follow" onClick={() => setDialogType(PROFILE_FOLLOW_TYPE.FOLLOWINGS)}>
          <span>{t('PROFILE.FOLLOWINGS')}</span>
          <span>{data?.followings || 0}</span>
        </div>
        <div className="btn-follow" onClick={() => setDialogType(PROFILE_FOLLOW_TYPE.FOLLOWERS)}>
          <span>{t('PROFILE.FOLLOWERS')}</span>
          <span>{data?.followers || 0}</span>
        </div>
        {isCurrentUser ? (
          <div className="d-flex gap-2">
            <Button
              className="btn-midnight selected text-uppercase text-nowrap text-midnight"
              onClick={onOpenEditProfile}
            >
              {t('PROFILE.EDIT_PROFILE')}
            </Button>
            <Button
              className="btn-midnight text-midnight p-0 d-flex align-items-center justify-content-center"
              onClick={onOpenActivities}
              style={{ width: 42 }}
            >
              <FiActivity />
            </Button>
          </div>
        ) : (
          <Button
            className="btn-midnight selected text-uppercase text-nowrap text-midnight d-flex align-items-center gap-2"
            onClick={handleFollow}
          >
            {data?.isFollower ? t('PROFILE.UNFOLLOW') : t('PROFILE.FOLLOW')}
            {data?.isFollower ? <FaUserMinus /> : <FaUserPlus />}
          </Button>
        )}
      </div>
      {dialogType && (
        <FollowDialog
          type={dialogType}
          isCurrentUser={isCurrentUser}
          username={data?.username}
          onClose={() => setDialogType(null)}
        />
      )}
    </div>
  );
};

MainSection.propTypes = {
  className: PropTypes.string,
  isCurrentUser: PropTypes.bool,
  data: PropTypes.shape({
    username: PropTypes.string,
    createdAt: PropTypes.string,
    followings: PropTypes.number,
    followers: PropTypes.number,
    isFollower: PropTypes.bool,
    profile: PropTypes.shape({
      avatar: PropTypes.string
    }),
  }),
  onOpenEditProfile: PropTypes.func.isRequired,
  onOpenActivities: PropTypes.func.isRequired
};

MainSection.defaultProps = {
  className: undefined,
  isCurrentUser: false,
  data: undefined,
};

export { MainSection };
