import React, { useRef } from 'react';
import { Form, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getDocumentPath } from '../../../api';

const ImageUpload = ({ values, errors, touched, setFieldValue, ind, }) => {
  const ref = useRef();
  return (
    <div className="img-uploader" onClick={() => ref.current?.click()} style={{ width: 40, height: 40 }}>
      <Image
        src={typeof values.items?.[ind]?.image === 'string'
          ? getDocumentPath(values.items?.[ind]?.image)
          : URL.createObjectURL(values.items?.[ind]?.image)}
        style={{ width: 40, height: 40 }}
      />
      <Form.Control
        ref={ref}
        type="file"
        className="d-none"
        accept="image/*"
        isInvalid={touched.items?.[ind]?.image && errors.items?.[ind]?.image}
        onChange={({ target }) => setFieldValue(`items.${ind}.image`, target.files[0])}
      />
    </div>
  );
};

ImageUpload.propTypes = {
  values: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string
    }))
  }).isRequired,
  touched: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.bool
    }))
  }).isRequired,
  errors: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.bool
    }))
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  ind: PropTypes.number.isRequired
};

export { ImageUpload };
