import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tournamentsActions, tournamentsSelectors } from '../redux/slices';
import { TOURNAMENTS } from '../data/constants';

const useTournaments = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(state => tournamentsSelectors.selectTournamentsByStatus(state, TOURNAMENTS.ONGOING));

  useEffect(() => {
    if (!tournaments?.length) {
      dispatch(tournamentsActions.loadTournamentsByStatus.base({ key: TOURNAMENTS.ONGOING }));
    }
  }, [tournaments?.length]);
};

export { useTournaments };
