import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Image, Placeholder } from 'react-bootstrap';
import { AiOutlineLike, /* AiOutlineStar */ } from 'react-icons/ai';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import {
  matchesActions,
  matchesSelectors, teamsSelectors,
  tipsSelectors,
  tournamentsSelectors,
  userSelectors
} from '../../../redux/slices';
import { getTournamentTitle, getIsStarted, getIsEnded, getClaimDiamonds } from '../../../helpers/utils';
import { TASKS_KEYS } from '../../../data/diamonds';

import Diamond from '../../../assets/images/diamonds/diamond-white.svg';
import { MatchScores } from '../MatchScores';
import { Team } from './Team';
import { useDurationFromNow } from '../../../hooks';
import { pages, routes } from '../../../data/menu';
import { VOTING_AFTER_START, SHOW_START_TIMER_BEFORE } from '../../../data/constants';

const MatchRow = ({ onCreateTip, hideScores, data: {
  id, game, start, end, lifecycle, tournament, participants
} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const userData = useSelector(userSelectors.selectUserDetails);
  const selectSerieWithTips = useSelector(tipsSelectors.selectSerieWithTips);
  const tournamentData = useSelector(state => tournamentsSelectors.selectTournamentById(state, tournament.id));
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, id));

  const [teamA, teamB] = participants;
  const teamAData = useSelector(state => teamsSelectors.selectTeamByRoster(state, teamA.roster.id));
  const teamBData = useSelector(state => teamsSelectors.selectTeamByRoster(state, teamB.roster.id));

  const isStarted = getIsStarted({ start, end, lifecycle });
  const isEnded = getIsEnded({ start, end, lifecycle });
  const dailyDiamonds = getClaimDiamonds(TASKS_KEYS.CREATE_TIP, userData?.claims, 1);
  const duration = useDurationFromNow(start, SHOW_START_TIMER_BEFORE);
  const isVotable = useDurationFromNow(start, null, VOTING_AFTER_START);
  const canVote = !!isVotable && !predictions?.myVote && !isEnded;

  const handleVote = useCallback(({ team, roster }) => {
    if (!userData) {
      loginWithRedirect();
    } else {
      dispatch(matchesActions.addPredictionData.base({
        prediction: {
          team,
          roster,
          game: game.id,
          serie: id,
          prediction: 'serie',
        },
        participants: [teamA, teamB]
      }));
    }
  }, [userData, game, id, teamA, teamB]);

  const handleVoteWinner = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    if (!userData) {
      loginWithRedirect();
    } else if (teamA.score > 0 || teamB.score > 0) {
      handleVote(teamA.score > teamB.score
        ? { team: teamAData.id, roster: teamAData.roster.id }
        : { team: teamBData.id, roster: teamBData.roster.id });
    }
  }, [userData, isEnded, teamAData, teamBData]);

  const handleLeaveTip = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    if (!userData) {
      loginWithRedirect();
    } else if (!isEnded && !selectSerieWithTips.includes(id)) {
      onCreateTip(event);
    }
  }, [userData, id, isEnded, selectSerieWithTips.length]);

  const handleNavigateMatch = useCallback(() => {
    navigate(routes[pages.matchDetails].replace(':matchId', id));
  }, [id]);

  const handleNavigateTournament = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(routes[pages.tournamentDetails].replace(':tournamentId', tournament.id));
  }, [tournament.id]);

  const handleNavigateTeam = useCallback(teamId => event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(routes[pages.teamDetails].replace(':teamId', teamId));
  }, []);

  return (
    <Row className="main-row cursor-pointer" onClick={handleNavigateMatch}>
      <Col
        xs={{ order: 1, span: 9 }}
        xl={{ order: 1, span: 4 }}
        xxl={{ order: 1, span: 4 }}
        className="d-flex align-items-center gap-3 fs-small split-right pb-3 pb-xl-0"
      >
        <div>
          <Image className="img-small" src={gamesImagesMap[game.id]} alt="" />
        </div>
        <div className="text-secondary d-flex flex-column gap-1 text-decoration-none">
          <span className={cx('text-nowrap', { 'opacity-25': isEnded })}>
            {moment(start).format('MMM DD, LT')}
          </span>
          {duration && (
            <span>{duration}</span>
          )}
          {isStarted && !isEnded && (
            <span className="status green">{t('MATCHES.STATUS_LIVE')}</span>
          )}
          {isStarted && isEnded && (
            <span className="status red">{t('MATCHES.STATUS_ENDED')}</span>
          )}
          {!isStarted && !isEnded && (
            <span className="status">{t('MATCHES.STATUS_NOT_STARTED')}</span>
          )}
        </div>
        <div
          onClick={handleNavigateTournament}
          className={cx('text-secondary', 'text-decoration-none', { 'opacity-25': isEnded })}
        >
          {tournamentData ? getTournamentTitle(tournamentData.title) : <Placeholder />}
        </div>
      </Col>

      <Col
        xs={{ order: 3, span: 12 }}
        xl={{ order: 2, span: 5 }}
        xxl={{ order: 2, span: 5 }}
        className="d-grid align-items-center gap-2 pt-3 pt-xl-0"
        style={{ gridTemplateColumns: '1fr 1fr 1fr' }}
      >
        <Team
          className="d-flex align-items-center justify-content-between gap-2"
          team={teamAData}
          predictions={predictions}
          defaultImage={gamesImagesMap[game.id]}
          isEnded={isEnded}
          canVote={canVote}
          onVote={handleVote}
          onOpenTeam={handleNavigateTeam}
        />
        <MatchScores
          className={{ 'opacity-25': isEnded }}
          scoreA={hideScores ? '?' : teamA.score}
          scoreB={hideScores ? '?' : teamB.score}
        />
        <Team
          className="d-flex align-items-center justify-content-between justify-content-xl-start gap-2"
          reverse
          team={teamBData}
          predictions={predictions}
          defaultImage={gamesImagesMap[game.id]}
          isEnded={isEnded}
          canVote={canVote}
          onVote={handleVote}
          onOpenTeam={handleNavigateTeam}
        />
      </Col>

      <Col
        xs={{ order: 2, span: 3 }}
        xl={{ order: 3, span: 3 }}
        xxl={{ order: 3, span: 3 }}
        className="d-flex align-items-center justify-content-end gap-2 fs-small split-left pb-3 pb-xl-0"
      >
        {canVote && teamA.score !== teamB.score && (teamA.score > 0 || teamB.score > 0) && (
          <Button
            variant="outline-primary"
            className="d-none d-xl-flex flex-column gap-1 align-items-center small"
            onClick={handleVoteWinner}
          >
            <AiOutlineLike className="fs-6" />
            <span className="fs-smallest text-uppercase text-nowrap">{t('MATCHES.VOTE_WINNER')}</span>
          </Button>
        )}

        <Button
          variant="outline-secondary"
          className={cx('d-flex flex-column gap-1 align-items-center small', {
            'opacity-50': isEnded || selectSerieWithTips.includes(id),
            'pe-none': isEnded || selectSerieWithTips.includes(id),
          })}
          onClick={handleLeaveTip}
          // eslint-disable-next-line no-nested-ternary
          title={isEnded ? t('MATCHES.MATCH_ENDED') : (
            selectSerieWithTips.includes(id) ? t('MATCHES.ALREADY_HAS_TIP') : undefined
          )}
        >
          <div className="d-flex gap-2 align-items-center">
            <Image src={Diamond} alt="" style={{ height: 15 }} />
            <span className="fs-smallest">{`[+${dailyDiamonds}]`}</span>
          </div>
          <span className="fs-smallest text-uppercase text-nowrap">{t('MATCHES.LEAVE_A_TIP')}</span>
        </Button>
        {/* <div className="cursor-pointer"> */}
        {/*  <AiOutlineStar className="fs-5" color="white" /> */}
        {/* </div> */}
      </Col>
    </Row>
  );
};

MatchRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    lifecycle: PropTypes.string,
    game: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    tournament: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    participants: PropTypes.arrayOf(PropTypes.shape({
      roster: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    }))
  }).isRequired,
  onCreateTip: PropTypes.func.isRequired,
  hideScores: PropTypes.bool.isRequired,
};

export { MatchRow };
