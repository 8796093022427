import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../styles.scss';
import { ALL_FILTER } from '../../../data/constants';

const MatchMapFilter = ({ options, value, onChange }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(id => () => {
    onChange(id);
  }, []);

  return (
    <div className="filters flex-wrap flex-xl-nowrap">
      <Button
        className={cx('btn-midnight', 'text-uppercase', { selected: value === ALL_FILTER })}
        onClick={handleChange(ALL_FILTER)}
      >
        {t('ALL')}
      </Button>
      {options.map((id) => (
        <Button
          key={`map-${id}`}
          className={cx('btn-midnight', { selected: value === id })}
          onClick={handleChange(id)}
        >
          {`${t('MAP')} ${id}`}
        </Button>
      ))}
    </div>
  );
};

MatchMapFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired
};

export { MatchMapFilter };
