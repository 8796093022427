import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PLATFORMS } from '../../../data/constants';
import { useDeepEffect } from '../../../hooks';
import { matchesActions, matchesSelectors } from '../../../redux/slices';

const PlayerEmbedded = ({ streams }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedStream = useSelector(matchesSelectors.selectSelectedStream);

  useDeepEffect(() => {
    dispatch(matchesActions.setSelectedStream.base(streams?.find(s => s.primary)?.stream));
  }, [streams]);

  if (selectedStream?.platform?.name === PLATFORMS.TWITCH) {
    return (
      <iframe
        src={`https://player.twitch.tv?channel=${selectedStream.username}&parent=${window.location.hostname}`}
        height="300px"
        width="100%"
        allowFullScreen
      />
    );
  }

  if (selectedStream?.platform?.name === PLATFORMS.YOUTUBE) {
    return (
      <iframe
        src={`https://www.youtube.com/embed/${selectedStream.username}?autoplay=1&origin=${window.location.hostname}`}
        height="300px"
        width="100%"
        frameBorder="0"
        allowFullScreen
      />
    );
  }

  if (!streams?.length) {
    return (
      <div
        className="d-flex align-items-center justify-content-center text-white"
        style={{
          height: 300,
          width: '100%',
          backgroundColor: '#2f333f'
        }}
      >
        {t('MATCHES.NO_STREAM_DATA')}
      </div>
    );
  }

  return null;
};

PlayerEmbedded.propTypes = {
  streams: PropTypes.arrayOf(PropTypes.shape({
    stream: PropTypes.shape({
      username: PropTypes.string,
      platform: PropTypes.shape({
        name: PropTypes.string,
      })
    }).isRequired,
  }))
};

PlayerEmbedded.defaultProps = {
  streams: []
};

export { PlayerEmbedded };
