import { createSlice } from '../../helpers/store';

const initialState = {
  countries: [],
  teamMap: {},
  playersMap: {},
  data: {
    isLoading: false,
    byCountry: {},
    lastPages: {},
    totalByCountry: {},
  },
  teamsByRoster: {},
  teamDetails: {
    team: null,
    players: null,
  },
  tournamentParticipants: {
    isLoading: false,
    rosters: [],
  }
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'teams',
  initialState,

  selectors: {
    selectIsLoading: state => state.data.isLoading,
    selectCountries: state => state.countries,
    selectByCountry: (state, _, filter) => (state.data.byCountry[filter] || [])
      .map(id => state.teamMap[id]).filter(id => !!id),
    selectTotalByCountry: state => state.data.totalByCountry,
    selectLastPage: state => state.data.lastPages,
    selectTeamByRoster: (state, _, roster) => state.teamMap[state.teamsByRoster[roster]],
    selectTeamsRosters: state => Object.keys(state.teamsByRoster).reduce((map, r) => ({
      ...map, [r]: state.teamMap[state.teamsByRoster[r]]
    }), {}),
    selectTeamsRostersIds: state => Object.keys(state.teamsByRoster).map(id => parseInt(id, 10)),
    selectTeamDetails: state => state.teamDetails,
    selectPlayer: (state, _, id) => state.playersMap[id],
    selectPlayerIds: state => Object.keys(state.playersMap).map(id => parseInt(id, 10)),
    selectTournamentParticipants: state => state.tournamentParticipants
  },

  reducers: {
    loadCountries: {
      success: (state, { payload }) => {
        state.countries = payload;
      }
    },
    loadTeams: {
      base: (state, { payload: { game, country, page } }) => {
        if (state.data.lastPages[game + country] !== page) {
          state.data.isLoading = true;
          state.data.lastPages[game + country] = page;
        }
      },
      success: (state, { payload: { game, country, page, data } }) => {
        const ids = [];
        data.forEach(item => {
          state.teamMap[item.id] = item;
          ids.push(item.id);
        });
        state.data.byCountry[game + country] = Array.from(new Set(
          (state.data.byCountry[game + country] || []).concat(ids)
        ));
        if (ids.length !== 50) {
          state.data.totalByCountry[game + country] = state.data.byCountry[game + country].length;
          state.data.lastPages[game + country] = page;
        }
        state.data.isLoading = false;
      },
    },
    loadTeamsByRosters: {
      success: (state, { payload }) => {
        payload.forEach(team => {
          state.teamsByRoster[team.roster.id] = team.id;
          state.teamMap[team.id] = team;
        });
      }
    },
    updateTeamFromSocket: {
      base: (state, { payload: { id, data } }) => {
        if (state.teamMap[id]) {
          state.teamMap[id] = data;
        }
        if (state.teamDetails.team?.id === id) {
          state.teamDetails.team = data;
        }
      },
    },
    updatePlayerFromSocket: {
      base: (state, { payload: { id, data } }) => {
        if (state.playersMap[id]) {
          state.playersMap[id] = data;
        }
      },
    },
    loadDetails: {
      success: (state, { payload: { team, roster } }) => {
        state.teamDetails.isLoading = false;
        state.teamDetails.team = team;
        state.teamDetails.players = (roster?.line_up?.players || []).map(p => p.id);
      },
    },
    clearDetails: {
      base: state => {
        state.teamDetails = { ...initialState.teamDetails };
      },
    },
    loadPlayers: {
      success: (state, { payload }) => {
        payload.forEach(player => {
          state.playersMap[player.id] = player;
        });
      }
    },
    loadTournamentParticipants: {
      base: state => {
        state.tournamentParticipants.isLoading = true;
      },
      success: (state, { payload }) => {
        state.tournamentParticipants.isLoading = false;
        state.tournamentParticipants.rosters = payload;
      },
      failed: state => {
        state.tournamentParticipants.isLoading = false;
      }
    }
  },
});

export { reducer as teams, actions as teamsActions, selectors as teamsSelectors };
