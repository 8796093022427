import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const CustomToggle = React.forwardRef(({ children, onClick, className, ...props }, ref) => (
  <div ref={ref} onClick={onClick} className={cx(className, 'after-content-none')} {...props}>
    {children}
  </div>
));

CustomToggle.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export { CustomToggle };
