import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';

import { MyTipsList } from './MyTipsList';

const MyLatestTips = ({ className, username, onDownScroll }) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultActiveKey="my-latest-tips" flush className={className}>
      <Accordion.Item eventKey="my-latest-tips">
        <Accordion.Header className="d-lg-none">
          {t('TIPS.LATEST_TIPS')}
          <div
            className="fs-smallest text-secondary text-decoration-underline text-lowercase cursor-pointer ms-3"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDownScroll();
            }}
          >
            {t('PROFILE.VIEW_ALL')}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="d-none d-lg-flex align-items-center gap-3 mb-20">
            <h3 className="text-nowrap">{t('TIPS.LATEST_TIPS')}</h3>
            <div
              className="fs-smallest text-secondary text-decoration-underline text-lowercase cursor-pointer"
              onClick={onDownScroll}
            >
              {t('PROFILE.VIEW_ALL')}
            </div>
          </div>
          <MyTipsList
            displayLimit={1}
            username={username}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

MyLatestTips.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string.isRequired,
  onDownScroll: PropTypes.func.isRequired
};

MyLatestTips.defaultProps = {
  className: undefined,
};

export { MyLatestTips };
