import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ButtonGroup, Dropdown, Image, Button,
} from 'react-bootstrap';

import DropdownIcon from '../../../assets/images/icons/chevron-down.svg';
import AccountEmpty from '../../../assets/images/icons/account-empty.svg';
import { DiamondsCount } from '../DiamondsCount';
import { Notifications } from '../Notifications';

import { userSelectors } from '../../../redux/slices';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div ref={ref} onClick={onClick} className="d-flex flex-row cursor-pointer">
    {children}
    <Image src={DropdownIcon} alt="&#x25bc;" />
  </div>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

const UserMenu = ({ onOpenProfile, onOpenAdmin }) => {
  const { t } = useTranslation();
  const { loginWithRedirect, logout } = useAuth0();
  const user = useSelector(userSelectors.selectUserDetails);

  if (!user) {
    return (
      <>
        <Button
          className="d-lg-flex d-none btn-midnight selected p-2 ms-2 text-uppercase"
          onClick={() => loginWithRedirect()}
        >
          {t('TOPBAR.LOGIN')}
        </Button>
        <Button
          className="d-lg-flex d-none btn-midnight p-2 ms-2 text-uppercase"
          onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
        >
          {t('TOPBAR.SIGNUP')}
        </Button>
      </>
    );
  }

  return (
    <>
      <DiamondsCount className="d-xl-flex d-none" count={user?.wallet?.diamonds} />
      <Notifications className="d-xl-flex d-none" />
      <Dropdown as={ButtonGroup} className="d-md-flex d-none align-items-center text-secondary user-menu" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <Image src={user?.profile?.avatar || AccountEmpty} alt={user?.username} />
          <div className="ps-2 d-flex justify-content-center flex-column">
            <div className="name">{user?.username}</div>
            <div className="opacity-50 email">{user?.email}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item onClick={onOpenProfile}>
            {t('TOPBAR.PROFILE.ACCOUNT_DETAILS')}
          </Dropdown.Item>
          {onOpenAdmin && (
            <Dropdown.Item onClick={onOpenAdmin}>
              {t('TOPBAR.PROFILE.ADMIN')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>
            {t('TOPBAR.PROFILE.LOGOUT')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

UserMenu.propTypes = {
  onOpenProfile: PropTypes.func.isRequired,
  onOpenAdmin: PropTypes.func,
};

UserMenu.defaultProps = {
  onOpenAdmin: undefined
};

export { UserMenu };
