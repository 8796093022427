import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Image, Row } from 'react-bootstrap';

import { useScroll } from '../../../hooks';
import { LEADERBOARD_FILTERS } from '../../../data/constants';
import { userActions, userSelectors } from '../../../redux/slices';
import { LoadingSpinner } from '../../LoadingSpinner';

import './styles.scss';
import { pages, routes } from '../../../data/menu';

const LeadersList = ({ filter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const storeData = useSelector(state => userSelectors.selectLeaderboards(state)[filter]);
  const { isLoading, total, data } = storeData || {};

  const handleLoadData = useCallback(() => {
    dispatch(userActions.loadLeaderboardsList.base({ filter, page }));
    setPage(page + 1);
  }, [filter, page]);

  const handleOpenDetails = useCallback(({ username }) => () => {
    navigate(routes[pages.profile].replace(':username', username));
  }, []);

  useEffect(() => {
    handleLoadData();
  }, [filter]);

  useScroll(() => {
    if (data?.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data?.length, total, isLoading]);

  return (
    <div className="mt-20 leaderboards">
      <Row className="header">
        <Col xs={2}>{t('LEADERBOARDS.TABLE.RANK')}</Col>
        <Col xs={4}>{t('LEADERBOARDS.TABLE.USERNAME')}</Col>
        <Col xs={3}>{t('LEADERBOARDS.TABLE.DIAMONDS')}</Col>
        <Col xs={3} className="text-break">{t('LEADERBOARDS.TABLE.PREDICTIONS_WON')}</Col>
      </Row>
      {data?.map((item, ind) => (
        <Row key={item.uid} onClick={handleOpenDetails(item)}>
          <Col xs={2}>
            <div className={cx('rank', { 'rank-1': ind === 0, 'rank-2': ind === 1, 'rank-3': ind === 2 })}>
              {`#${ind + 1}`}
            </div>
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            <Image className="me-3" src={item.avatar} alt="" style={{ height: 40, width: 40, borderRadius: 4 }} />
            {item.username}
          </Col>
          <Col xs={3}>{item.diamonds}</Col>
          <Col xs={3} className="d-flex justify-content-between align-items-center">
            {item.predictionsWon}
            <HiChevronRight className="more-btn" />
          </Col>
        </Row>
      ))}
      {data?.length < total && !isLoading && (
        <div className="d-flex justify-content-center text-white mb-4" onClick={handleLoadData}>
          <div className="p-2 cursor-pointer">
            {t('LOAD_MORE')}
          </div>
        </div>
      )}
      {isLoading && (
        <LoadingSpinner />
      )}
    </div>
  );
};

LeadersList.propTypes = {
  filter: PropTypes.oneOf(Object.values(LEADERBOARD_FILTERS)).isRequired
};

export { LeadersList };
