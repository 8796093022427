import { useEffect, useState } from 'react';
import moment from 'moment';

const useDurationFromNow = (time, showBefore, showAfter) => {
  const [dur, setDuration] = useState(moment.duration(moment(time).diff(moment())));
  useEffect(() => {
    const timer = setInterval(() => {
      const dur = moment.duration(moment(time).diff(moment()));
      setDuration(dur);
    }, 1000);
    return () => clearInterval(timer);
  }, [time]);
  if (showBefore) {
    return dur._milliseconds > 0 && dur._milliseconds < showBefore ? dur.format('d[d] h[h] m[m] s[s]') : null;
  }
  if (showAfter) {
    return dur._milliseconds > -showAfter ? dur.format('d[d] h[h] m[m] s[s]') : null;
  }
  return dur.format('d[d] h[h] m[m] s[s]');
};

export { useDurationFromNow };
