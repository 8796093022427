import { socket } from '../../api';
import { EVENTS } from './events';
import { initListener } from './listener';

const initSockets = store => initListener(store, socket);

const sendMessage = (key, data) => socket.emit(key, data);

export {
  sendMessage,
  EVENTS,
  initSockets
};
