import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

const Banner = ({ data }) => data.map(({ onClick, image }) => (
  <Image
    className="mt-20 cursor-pointer m-auto d-flex"
    onClick={onClick}
    src={image}
    style={{
      height: 200,
      maxWidth: '100%'
    }}
  />
));

Banner.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func,
    image: PropTypes.string.isRequired
  }))
};

export { Banner };
