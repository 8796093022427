import React from 'react';

import cx from 'classnames';
import { Card } from 'react-bootstrap';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { adminPages } from '../../../data/menu';

const Navigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <Card bg="dark" className="large-card">
      {Object.keys(adminPages).map(key => (
        <Card.Body
          as={Link}
          key={key}
          to={adminPages[key]}
          className={cx('p-3 text-decoration-none justify-content-start border-start border-2', {
            'text-midnight': matchPath(adminPages[key], pathname),
            'border-midnight': matchPath(adminPages[key], pathname),
            'border-transparent': !matchPath(adminPages[key], pathname),
          })}
        >
          {t(`ADMIN.NAVIGATION.${key.toUpperCase()}`)}
        </Card.Body>
      ))}
    </Card>
  );
};

export { Navigation };
