export const TOKEN_STORAGE = 'estackgg-token';

export const ALL_FILTER = 'all';

export const FILTERS = {
  GAMES: 'games',
  TOURNAMENTS: 'tournaments',
  LIVE_ONLY: 'live_only',
  DATE: 'date',
  COUNTRY: 'country',
  LANG: 'lang',
  STATUS: 'status',
  HIDE_SCORES: 'hide_scores',
  PROFILE_ACTIVITIES: 'profile_activities',
};

export const TOURNAMENTS = {
  ONGOING: 'ongoing',
  PAST: 'past',
  UPCOMING: 'upcoming',
};

export const MATCH_DETAILS_TABS = {
  PREDICTIONS: 'predictions',
  LIVE_STATISTICS: 'live-statistics',
  PRE_MATCH_INFO: 'pre-match-info',
  TIPS: 'tips'
};

export const TEAM_MATCHES_TABS = {
  PAST: 'past',
  SCHEDULED: 'scheduled',
};

export const TOURNAMENT_MATCHES_TABS = {
  OVER: 'over',
  LIVE: 'live',
  UPCOMING: 'upcoming',
};

export const TOURNAMENT_STAGES_PHASE = {
  QUALIFIER: 'qualifier',
  REGULAR: 'regular',
  FINAL: 'final',
  OTHER: 'other',
};

export const PLATFORMS = {
  TWITCH: 'Twitch',
  YOUTUBE: 'YouTube'
};

export const PARTNERS = {
  BETWAYESPORTS: 'Betway Esports'
};

export const PARTNERS_SITES = {
  [PARTNERS.BETWAYESPORTS]: 'https://sports.betway.de/de/sports'
};

export const TIPS_PAGE_SIZE = 10;

export const TIPS_FILTERS = {
  UPVOTE: 'upvote',
  RECENT: 'recent'
};

export const TIPS_MODAL = {
  EDIT: 'edit',
  DELETE: 'delete'
};

export const REWARDS_TABS = {
  MARKETPLACE: 'marketplace',
  MISSIONS: 'missions',
};

export const MARKETPLACE_RARITY = {
  COMMON: 'common',
  UNCOMMON: 'uncommon',
  RARE: 'rare',
  EPIC: 'epic',
  LEGENDARY: 'legendary',
};

export const LEADERBOARDS_TABS = {
  MOST_PREDICTIONS: 'most-predictions',
  MOST_DIAMONDS: 'most-diamonds',
};

export const LEADERBOARD_FILTERS = {
  [LEADERBOARDS_TABS.MOST_PREDICTIONS]: 'predictionsWon',
  [LEADERBOARDS_TABS.MOST_DIAMONDS]: 'diamonds'
};

export const PROFILE_FOLLOW_TYPE = {
  FOLLOWERS: 'followers',
  FOLLOWINGS: 'followings'
};

export const PROFILE_SECTION_TABS = {
  TIPS_HISTORY: 'tips-history',
  PREDICTION_HISTORY: 'production-history',
  REDEMPTIONS: 'redemptions',
};

export const GAMES_WITH_HIDDEN_MAP_NAME = [1, 2];

export const NOTIFICATIONS_PAGE_SIZE = 10;

export const NOTIFICATIONS_FILTERS = {
  ALL: 'all',
  UNREAD: 'unread',
};

export const NOTIFICATIONS_ACTION = {
  REDEEM_ITEM: 'REDEEM_ITEM',
  DAILY_LOGIN: 'DAILY_LOGIN',
  NEW_FOLLOWER: 'NEW_FOLLOWER',
  MATCH_WON: 'MATCH_WON',
  MATCH_LOST: 'MATCH_LOST',
};

export const ROLES = {
  NORMAL: 'normal',
  TIPS_EDITOR: 'tips-editor',
  USERS_ADMIN: 'users-admin',
  REWARDS_ADMIN: 'rewards-admin',
};

export const NAVIGATION_ADMIN = [ROLES.USERs_ADMIN, ROLES.REWARDS_ADMIN];

export const MISSION_TYPES = {
  DAILY: 'daily',
  REGULAR: 'regular',
};

export const MISSION_CONDITIONS = {
  VOTE_MATCH: 'vote-match',
  VOTE_LIVE_MATCH: 'vote-live-match',
  VOTE_MAP: 'vote-map',
  VOTE_LIVE_MAP: 'vote-live-map',
  WIN_VOTED_MATCH: 'win-voted-match',
  WIN_VOTED_MAP: 'win-voted-map',
  CREATE_TIP: 'create-tip',
  LIKE_TIP: 'like-tip',
  COMPLETE_MISSION: 'complete-mission',
};

export const REGEXPS = {
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  USERNAME: /[\w\W]{2,100}/,
};

export const PROFILE_ACTIVITIES = {
  ALL: 'all',
  ONLY_WITH_DIAMONDS: 'onlyWithDiamonds'
};

export const SHOW_START_TIMER_BEFORE = 2 * 60 * 60 * 1000;
export const VOTING_AFTER_START = 15 * 60 * 1000;
