import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/predictions`;

export const getBulkPredictions = ids => (
  request.post(`${api}/bulk-predictions`, ids).then(res => res.data?.success?.data)
);

export const getSeriePredictions = id => (
  request.get(`${api}/serie/${id}/predictions`)
    .then(res => res.data?.success?.data)
    .catch(() => null)
);

export const predict = predictions => (
  request.post(`${api}`, { predictions }).then(res => res.data?.success?.data)
);

export const getPredictionsLeaderboards = (filter, page) => (
  request.get(`${api}/leaderboards/${filter}`, { params: { page } }).then(res => res.data?.success?.data)
);

export const getPredictionsByUser = (username, params) => (
  request.get(`${api}/user/${username}`, { params }).then(res => res.data?.success?.data)
);
