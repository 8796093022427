import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/tips`;

export const getLatestTips = (params = {}) => (
  request.get(`${api}/latest`, { params }).then(res => res.data?.success?.data)
);

export const getTips = params => (
  request.get(`${api}`, { params }).then(res => res.data?.success?.data)
);

export const sendLikeTip = id => request.post(`${api}/${id}/like`).then(res => res.data?.success?.message);

export const sendDislikeTip = id => request.post(`${api}/${id}/dislike`).then(res => res.data?.success?.message);

export const createTip = data => request.post(`${api}`, data).then(res => res.data?.success?.data);

export const updateTip = (id, data) => request.put(`${api}/${id}`, data).then(res => res.data?.success?.data);

export const deleteTip = id => request.delete(`${api}/${id}`).then(res => res.data?.success?.data);

export const checkCreatedTips = data => request.post(`${api}/check-created`, data).then(res => res.data?.success?.data);
