import { createSlice } from '../../helpers/store';
import { TIPS_FILTERS, TIPS_PAGE_SIZE } from '../../data/constants';
import { processLikeDislike } from '../../helpers/utils';

const initialState = {
  latestTips: {
    isLoading: false,
    total: 0,
    data: []
  },
  tips: {
    filter: TIPS_FILTERS.UPVOTE,
    isLoading: false,
    total: 0,
    currentPage: 1,
    hasCreated: false,
    data: []
  },
  seriesWithTips: [],
  tipModal: {
    mode: null,
    data: null
  },
  userProfileTips: {
    isLoading: false,
    total: 0,
    latestData: [],
    data: []
  }
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'tips',
  initialState,

  selectors: {
    selectLatestTipsLoading: state => state.latestTips.isLoading,
    selectLatestTipsData: state => state.latestTips.data,
    selectTips: state => state.tips,
    selectSerieWithTips: state => state.seriesWithTips,
    selectTipModal: state => state.tipModal,
    selectProfileTips: state => state.userProfileTips,
  },

  reducers: {
    loadLatestTips: {
      base: state => {
        state.latestTips.isLoading = true;
      },
      success: (state, { payload: { itemCount, results } }) => {
        state.latestTips.total = itemCount;
        state.latestTips.data = results;
        state.latestTips.isLoading = false;
      },
      failed: state => {
        state.latestTips.isLoading = false;
      },
    },
    addLatestTip: {
      success: (state, { payload }) => {
        state.latestTips.total += 1;
        if (state.latestTips.data.length >= 5) {
          state.latestTips.data.pop();
        }
        state.latestTips.data.unshift(payload);
        if (state.userProfileTips.latestData[0]?.uid === payload.uid) {
          state.userProfileTips.latestData.unshift(payload);
        }
        if (state.userProfileTips.data[0]?.uid === payload.uid) {
          state.userProfileTips.data.unshift(payload);
          state.userProfileTips.total++;
        }
      },
    },
    likeDislikeTip: {
      success: (state, { payload: { id, key, status } }) => {
        state.latestTips.data = processLikeDislike(state.latestTips.data, { id, key, status });
        state.tips.data = processLikeDislike(state.tips.data, { id, key, status });
      },
    },
    clearTips: {
      base: state => {
        state.tips = { ...initialState.tips };
      }
    },
    loadTips: {
      base: state => {
        state.tips.isLoading = true;
      },
      success: (state, { payload: { currentPage, itemCount, results, hasCreated } }) => {
        state.tips.isLoading = false;
        if (currentPage === 1) {
          state.tips.data = results;
        } else {
          state.tips.data = state.tips.data.concat(results);
        }
        state.tips.currentPage = currentPage;
        state.tips.total = itemCount;
        state.tips.hasCreated = hasCreated;
      },
      failed: state => {
        state.tips.isLoading = false;
      }
    },
    createTip: {
      success: (state, { payload }) => {
        if (state.tips.currentPage * TIPS_PAGE_SIZE >= state.tips.total) {
          state.tips.data.push(payload);
        }
        state.tips.total += 1;
        state.tips.hasCreated = true;
        state.seriesWithTips.push(payload.serie);
      }
    },
    updateTip: {
      success: (state, { payload }) => {
        const index = state.tips.data.findIndex(d => d._id === payload._id);
        const index2 = state.latestTips.data.findIndex(d => d._id === payload._id);
        const index3 = state.userProfileTips.data.findIndex(d => d._id === payload._id);
        const index4 = state.userProfileTips.latestData.findIndex(d => d._id === payload._id);
        if (index > -1) state.tips.data[index] = payload;
        if (index2 > -1) state.latestTips.data[index2] = payload;
        if (index3 > -1) state.userProfileTips.data[index3] = payload;
        if (index4 > -1) state.userProfileTips.latestData[index4] = payload;
      }
    },
    deleteTip: {
      success: (state, { payload: { id } }) => {
        const index = state.tips.data.findIndex(d => d._id === id);
        const index2 = state.latestTips.data.findIndex(d => d._id === id);
        const index3 = state.userProfileTips.data.findIndex(d => d._id === id);
        const index4 = state.userProfileTips.latestData.findIndex(d => d._id === id);
        if (index > -1) {
          state.tips.data.splice(index, 1);
          state.tips.total--;
        }
        if (index2 > -1) {
          state.latestTips.data.splice(index2, 1);
          state.latestTips.total--;
        }
        if (index3 > -1) {
          state.userProfileTips.data.splice(index3, 1);
          state.userProfileTips.total--;
        }
        if (index4 > -1) {
          state.userProfileTips.latestData.splice(index4, 1);
        }
      }
    },
    checkCreatedTips: {
      success: (state, { payload }) => {
        state.seriesWithTips = state.seriesWithTips.concat(payload);
      }
    },
    setTipModal: {
      base: (state, { payload }) => {
        state.tipModal = payload;
      }
    },
    clearTipsForProfile: {
      base: state => {
        state.userProfileTips = {
          ...initialState.userProfileTips,
          latestData: state.userProfileTips.latestData
        };
      },
    },
    loadTipsForProfile: {
      base: state => {
        state.userProfileTips.isLoading = true;
      },
      success: (state, { payload: { itemCount, results } }) => {
        if (!state.userProfileTips.latestData.length) {
          state.userProfileTips.latestData = results;
        }
        state.userProfileTips.data = (state.userProfileTips.data || []).concat(results);
        state.userProfileTips.total = itemCount;
        state.userProfileTips.isLoading = false;
      },
      failed: state => {
        state.userProfileTips.isLoading = false;
      }
    },
  },
});

export { reducer as tips, actions as tipsActions, selectors as tipsSelectors };
