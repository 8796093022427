import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { tournamentsActions, tournamentsSelectors } from '../../../redux/slices';
import { StageDetails } from './StageDetails';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TOURNAMENT_STAGES_PHASE } from '../../../data/constants';

const TournamentStages = ({ tournamentId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [phase, setPhase] = useState('');
  const [stage, setStage] = useState(0);

  const phases = useSelector(tournamentsSelectors.selectStagesPhases);
  const count = useSelector(tournamentsSelectors.selectStagesCount);
  const isLoading = useSelector(state => tournamentsSelectors.selectIsLoadingById(state, 'tournamentStages'));
  const stages = useSelector(state => tournamentsSelectors.selectStages(state, phase));

  useEffect(() => {
    dispatch(tournamentsActions.loadStages.base({ id: tournamentId }));
  }, [tournamentId]);

  useEffect(() => {
    setPhase(phases[0]);
  }, [phases]);

  useEffect(() => {
    setStage(0);
  }, [phase]);

  return (
    <Card bg="dark" className="large-card always">
      <Card.Header className="d-flex justify-content-between align-items-center gap-3">
        {t('TOURNAMENTS.TITLE_STAGES', { count })}
        {stages.length && (
          <Form.Group>
            <Form.Select
              value={phase}
              onChange={({ target }) => setPhase(target.value)}
            >
              {Object.values(TOURNAMENT_STAGES_PHASE).map(phase => phases.includes(phase) ? (
                <option key={phase} value={phase}>{t(`TOURNAMENTS.TITLE_STAGES_PHASE.${phase.toUpperCase()}`)}</option>
              ) : null)}
            </Form.Select>
          </Form.Group>
        )}
      </Card.Header>
      {!stages && isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="d-flex align-items-center gap-2 pe-3 ps-3">
            {stages.map((item, ind) => (
              <Button
                key={`${item.id}-${ind}`}
                onClick={() => setStage(ind)}
                className={cx('btn-midnight', { selected: ind === stage })}
              >
                {item.title}
              </Button>
            ))}
          </div>
          <div style={{ maxHeight: 445, overflowY: 'auto' }}>
            <Table variant="dark" className={cx('mb-0', { 'mt-20': stages.length })} hover>
              <thead>
                <tr>
                  <th className="th-dark">{t('TOURNAMENTS.TABLE_STAGES.TEAM')}</th>
                  <th className="th-dark">{t('TOURNAMENTS.TABLE_STAGES.POINTS')}</th>
                  <th className="th-dark">{t('TOURNAMENTS.TABLE_STAGES.WIN')}</th>
                  <th className="th-dark">{t('TOURNAMENTS.TABLE_STAGES.LOSE')}</th>
                  <th className="th-dark">{t('TOURNAMENTS.TABLE_STAGES.DRAW')}</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: '#1A2030' }}>
                {stages.find((_, ind) => ind === stage)?.standings?.map(item => (
                  <StageDetails key={item.roster.id} data={item} />
                ))}
                {!stages.find((_, ind) => ind === stage)?.standings?.length && (
                  <td colSpan={5} className="text-center pt-2 td-dark">
                    {t('TOURNAMENTS.TABLE_STAGES.NO_RESULTS')}
                  </td>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </Card>
  );
};

TournamentStages.propTypes = {
  tournamentId: PropTypes.string.isRequired
};

export { TournamentStages };
