import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Accordion, Col, Row } from 'react-bootstrap';

import { seriesActions, seriesSelectors } from '../../../redux/slices';
import { TOURNAMENT_MATCHES_TABS as TABS } from '../../../data/constants';
import { useGames, useScroll } from '../../../hooks';
import { MatchStatus } from '../../filters/MatchStatus';
import { LoadingSpinner } from '../../LoadingSpinner';

import { TournamentMatchDetails } from './TournamentMatchDetails';

const TournamentMatches = ({ tournamentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(TABS.LIVE);
  const isLoading = useSelector(state => seriesSelectors.selectIsLoading(state, 'tournamentMatches'));
  const { data, total, page } = useSelector(seriesSelectors.selectTournamentMatches);

  const options = useMemo(() => Object.keys(TABS).map(k => ({ label: k, value: TABS[k] })), []);

  const handleLoadData = (payload = {}) => dispatch(seriesActions.loadTournamentMatches.base({
    tournamentId,
    status: tab,
    page: page + 1,
    ...payload
  }));

  useEffect(() => {
    handleLoadData({ page: 1 });
  }, [tournamentId, tab]);

  useGames();

  useScroll(() => {
    if (data.length < total && !isLoading) {
      handleLoadData();
    }
  }, [data.length, total, isLoading]);

  return (
    <Accordion defaultActiveKey="tournament-matches" flush>
      <Accordion.Item eventKey="tournament-matches">
        <Accordion.Header className="d-lg-none mt-20">
          {t('TOURNAMENTS.TITLE_MATCHES')}
        </Accordion.Header>
        <Accordion.Body className="show-grid mb-20">
          <h3 className="mb-20 d-none d-lg-block text-nowrap">{t('TOURNAMENTS.TITLE_MATCHES')}</h3>
          <MatchStatus
            options={options}
            value={tab}
            onChange={setTab}
            fullFill
          />
          <Row>
            {!isLoading && data?.map(id => (
              <Col xs={12} md={6} key={id}>
                <TournamentMatchDetails id={id} showScores={tab === TABS.OVER} />
              </Col>
            ))}
          </Row>
          {data?.length === 0 && !isLoading && (
            <div className="text-white text-center mt-3">
              {t('MATCHES.NO_RESULTS')}
            </div>
          )}
          {isLoading && (
            <LoadingSpinner className="mt-3" />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

TournamentMatches.propTypes = {
  tournamentId: PropTypes.string.isRequired
};

export { TournamentMatches };
