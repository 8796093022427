import React from 'react';
import { Row, Placeholder } from 'react-bootstrap';

const TeamRowPlaceholder = () => (
  <Row className="main-row">
    <Placeholder className="d-flex justify-content-between align-items-center" animation="glow">
      <div className="d-flex align-items-center gap-3">
        <Placeholder style={{ height: 48, width: 48, borderRadius: '50%' }} />
        <Placeholder style={{ width: 150 }} />
      </div>
      <div>
        <Placeholder style={{ height: 20, width: 28 }} />
      </div>
    </Placeholder>
  </Row>
);

export { TeamRowPlaceholder };
