import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQueryFilter } from '../../../hooks';
import { FILTERS } from '../../../data/constants';

import '../styles.scss';

const GameFilter = ({ options, selection, setSelection }) => {
  const { t } = useTranslation();
  const { changeFilter } = useQueryFilter(FILTERS.GAMES, setSelection, options.map(o => o.id));

  const handleChangeFilter = ids => {
    setSelection(ids);
    changeFilter(ids);
  };

  const handleSelectGame = useCallback(({ id }) => () => {
    if (!id) {
      handleChangeFilter([]);
    } else if (selection.includes(id)) {
      handleChangeFilter(selection.filter((s) => s !== id));
    } else {
      handleChangeFilter([...selection, id]);
    }
  }, [selection]);

  return (
    <div className="filters games">
      <Button
        className={cx('btn-midnight', 'text-uppercase', { selected: selection.length === 0 })}
        onClick={handleSelectGame({})}
      >
        {t('ALL')}
      </Button>
      {options.map((item) => (
        <Button
          key={item.id}
          className={cx('btn-midnight', { selected: selection.includes(item.id) })}
          onClick={handleSelectGame(item)}
        >
          <Image src={item.images.find((i) => i.type === 'circle')?.url} alt={item.abbreviation} />
        </Button>
      ))}
    </div>
  );
};

GameFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    abbreviation: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired
  })).isRequired,
  selection: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired
};

export { GameFilter };
