import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import AccountEmpty from '../../../../assets/images/icons/account-empty.svg';
import CupYellow from '../../../../assets/images/icons/cup-yellow.svg';

import '../styles.scss';

const WinnersPlaces = ({ className, first, second, third }) => (
  <div className={cx('winners-places', className)}>
    <div className="place place-2">
      <div className="position">#2</div>
      <div className="avatar">
        <Image src={second.avatar || AccountEmpty} alt="" />
      </div>
      <div className="text-white">{second.username}</div>
      <div className="text-midnight">{parseInt(second.score, 10).toLocaleString()}</div>
    </div>
    <div className="place place-1">
      <div className="position">#1</div>
      <div className="avatar">
        <Image src={first.avatar || AccountEmpty} alt="" />
      </div>
      <div className="text-white">
        <Image src={CupYellow} alt="" className="me-1" />
        {first.username}
      </div>
      <div className="text-midnight">{parseInt(first.score, 10).toLocaleString()}</div>
    </div>
    <div className="place place-3">
      <div className="position">#2</div>
      <div className="avatar">
        <Image src={third.avatar || AccountEmpty} alt="" />
      </div>
      <div className="text-white">{third.username}</div>
      <div className="text-midnight">{parseInt(third.score, 10).toLocaleString()}</div>
    </div>
  </div>
);

const UserShape = PropTypes.shape({
  uid: PropTypes.string,
  username: PropTypes.string,
  avatar: PropTypes.string,
  score: PropTypes.number
});

WinnersPlaces.propTypes = {
  className: PropTypes.string,
  first: UserShape.isRequired,
  second: UserShape.isRequired,
  third: UserShape.isRequired,
};

WinnersPlaces.defaultProps = {
  className: undefined
};

export { WinnersPlaces };
