import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Offcanvas, Image } from 'react-bootstrap';
import CloseIcon from '../../../assets/images/icons/close.svg';
import './styles.scss';

const Aside = React.forwardRef(({ title, show, onClose, ariaLabelledby, children, classes }, ref) => (
  <Navbar.Offcanvas
    ref={ref}
    placement="start"
    className={`aside ${classes.root}`}
    aria-labelledby={ariaLabelledby}
    show={show}
    onHide={onClose}
  >
    <Offcanvas.Header className={`bg-dark ${classes.header}`}>
      {title}
      <div className="close-btn d-flex justify-content-center align-items-center" onClick={onClose}>
        <Image src={CloseIcon} alt="X" />
      </div>
    </Offcanvas.Header>
    <Offcanvas.Body className={classes.body}>
      {children}
    </Offcanvas.Body>
  </Navbar.Offcanvas>
));

Aside.propTypes = {
  title: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ariaLabelledby: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
  })
};
Aside.defaultProps = {
  ariaLabelledby: undefined,
  classes: {
    root: '',
    header: '',
    body: ''
  }
};

export { Aside };
