import { request } from '../helpers/request';

const api = `${process.env.REACT_APP_API_ENDPOINT}/v2/rewards`;

export const getMarketplaceItems = params => (
  request.get(`${api}/marketplace/items`, { params }).then(res => res.data?.success?.data)
);

export const createMarketplaceItem = data => (
  request.post(`${api}/marketplace/items`, data).then(res => res.data?.success?.data)
);

export const updateMarketplaceItem = (id, data) => (
  request.put(`${api}/marketplace/items/${id}`, data).then(res => res.data?.success?.data)
);

export const deleteMarketplaceItem = id => (
  request.delete(`${api}/marketplace/items/${id}`).then(res => res.data?.success?.data)
);

export const sendMarketplaceRedeemItem = id => (
  request.post(`${api}/marketplace/items/${id}/redeem`).then(res => res.data?.success?.data)
);

export const claimDailyLogin = () => (
  request.post(`${api}/claimdailylogin`).then(res => res.data?.success?.data)
);

export const getMissions = params => (
  request.get(`${api}/missions`, { params }).then(res => res.data?.success?.data)
);

export const createMission = data => (
  request.post(`${api}/missions`, data).then(res => res.data?.success?.data)
);

export const updateMission = (id, data) => (
  request.put(`${api}/missions/${id}`, data).then(res => res.data?.success?.data)
);

export const deleteMission = id => (
  request.delete(`${api}/missions/${id}`).then(res => res.data?.success?.data)
);

export const getMissionsStatuses = () => (
  request.get(`${api}/missions/statuses`).then(res => res.data?.success?.data)
);

export const claimMission = id => (
  request.post(`${api}/missions/${id}/claim`).then(res => res.data?.success?.data)
);

export const startMission = id => (
  request.post(`${api}/missions/${id}/start`).then(res => res.data?.success?.data)
);

export const loadUserActivities = (uid, params) => (
  request.get(`${api}/${uid}/activities`, { params }).then(res => res.data?.success?.data)
);

export const loadRedemptions = (uid, params) => (
  request.get(`${api}/${uid}/redemptions`, { params }).then(res => res.data?.success?.data)
);

export const approveRedemption = id => (
  request.post(`${api}/redemptions/${id}/approve`).then(res => res.data?.success?.data)
);
