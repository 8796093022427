import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';

import { teamsSelectors } from '../../../redux/slices';

const StageDetails = ({ data: { draws, losses, points, roster, wins } }) => {
  const team = useSelector(state => teamsSelectors.selectTeamByRoster(state, roster.id));
  return (
    <tr>
      <td className="td-dark">
        <div className="d-flex gap-2 align-items-center">
          <Image className="img-small" src={(team?.images || [])[0]?.url} />
          {team?.abbreviation || team?.name}
        </div>
      </td>
      <td className="td-dark">{points}</td>
      <td className="td-dark">{wins}</td>
      <td className="td-dark">{losses}</td>
      <td className="td-dark">{draws}</td>
    </tr>
  );
};

StageDetails.propTypes = {
  data: PropTypes.shape({
    draws: PropTypes.number,
    losses: PropTypes.number,
    points: PropTypes.number,
    wins: PropTypes.number,
    roster: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired
};

export { StageDetails };
