import React, { useCallback, useEffect, useState } from 'react';
import { Col, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LEADERBOARDS_TABS as TABS, LEADERBOARD_FILTERS } from '../data/constants';

import { PredictionSlip } from '../components/Match';
import { LatestTips, TipsterRank } from '../components/Tips';
import { MissionListWidget } from '../components/Rewards/Missions';
import { LeadersList } from '../components/Leaderboards';
// import { PartnerBanner } from '../components/PartnerBanner';

import DiamondIcon from '../assets/images/diamonds/diamond-grey.svg';
import ChartIcon from '../assets/images/icons/chart.svg';
import { useScrollTop } from '../hooks';

const TabsIcon = {
  [TABS.MOST_PREDICTIONS]: ChartIcon,
  [TABS.MOST_DIAMONDS]: DiamondIcon
};

const LeaderboardsPage = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  useScrollTop();

  const [page, setPage] = useState();

  const handleChangePage = useCallback(page => {
    navigate({ hash: page }, { replace: true });
  }, [hash]);

  useEffect(() => {
    const page = hash.replace('#', '');
    if (Object.values(TABS).includes(page)) {
      setPage(page);
    } else {
      navigate({ hash: TABS.MOST_PREDICTIONS }, { replace: true });
    }
  }, [hash]);

  return (
    <Row>
      <Col xs={{ order: 2 }} lg={{ order: 1, span: 3 }} xxl={2} className="mb-20">
        <PredictionSlip className="mb-20 d-xxl-none" />
        <MissionListWidget className="mb-20" />
        <LatestTips className="mb-20" />
        {/* <PartnerBanner className="mb-20" partner={PARTNERS.BETWAYESPORTS} /> */}
        <TipsterRank className="mb-20 d-xxl-none" />
      </Col>
      <Col xs={{ order: 1 }} lg={{ order: 2, span: 9 }} xxl={8} className="mb-20">
        <Tabs fill activeKey={page} onSelect={handleChangePage} className="mb-20 mt-20">
          {Object.keys(TABS).map(tab => (
            <Tab
              key={tab}
              eventKey={TABS[tab]}
              title={(
                <>
                  <Image src={TabsIcon[TABS[tab]]} alt="" className="me-2 text-secondary" style={{ width: 20 }} />
                  {t(`LEADERBOARDS.TABS.${tab}`)}
                </>
              )}
            />
          ))}
        </Tabs>
        {page === TABS.MOST_PREDICTIONS && (
          <LeadersList filter={LEADERBOARD_FILTERS[TABS.MOST_PREDICTIONS]} />
        )}
        {page === TABS.MOST_DIAMONDS && (
          <LeadersList filter={LEADERBOARD_FILTERS[TABS.MOST_DIAMONDS]} />
        )}
      </Col>
      <Col xs={{ order: 3 }} lg={{ order: 3, span: 3 }} xxl={2} className="mb-20 d-none d-xxl-block">
        <PredictionSlip className="mb-20" />
        <TipsterRank className="mb-20" />
      </Col>
    </Row>
  );
};

export { LeaderboardsPage };
