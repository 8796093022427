import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClockCircle, AiOutlineCalendar } from 'react-icons/ai';
import { Card, Image, Button, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  matchesActions,
  matchesSelectors,
  seriesSelectors,
  teamsSelectors,
  userSelectors
} from '../../../redux/slices';
import { getIsEnded, getPredictionPercent } from '../../../helpers/utils';
import { pages, routes } from '../../../data/menu';

const TournamentMatchDetails = ({ id, showScores }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const data = useSelector(state => seriesSelectors.selectSerieById(state, id));
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const userData = useSelector(userSelectors.selectUserDetails);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, id));
  const [teamA, teamB] = data?.participants || [];
  const teamAData = useSelector(state => (
    teamsSelectors.selectTeamByRoster(state, (data?.participants || [])[0]?.roster?.id)
  ));
  const teamBData = useSelector(state => (
    teamsSelectors.selectTeamByRoster(state, (data?.participants || [])[1]?.roster?.id)
  ));

  const isEnded = getIsEnded(data);
  const defaultImage = gamesImagesMap[data?.game?.id];

  const handleVoteTeam = useCallback(teamId => event => {
    event.preventDefault();
    event.stopPropagation();
    if (!userData) {
      loginWithRedirect();
    } else {
      dispatch(matchesActions.addPredictionData.base({
        prediction: {
          team: teamAData.id === teamId ? teamAData.id : teamB.id,
          roster: teamAData.id === teamId ? teamAData.roster.id : teamB.roster.id,
          game: data.game.id,
          serie: data.id,
          prediction: 'serie',
        },
        participants: [teamAData, teamB]
      }));
    }
  }, [userData, teamAData, teamB, data]);

  const handleOpenMatch = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    navigate(routes[pages.matchDetails].replace(':matchId', id));
  }, [id]);

  if (!data) {
    return (
      <Placeholder as={Card} animation="glow" bg="dark" className="large-card always mt-20 d-flex flex-row gap-3 ps-3">
        <div className="score flex-column">
          <Placeholder style={{ width: 30 }} />
          <div>vs</div>
          <Placeholder style={{ width: 30 }} />
        </div>
        <div className="d-flex flex-grow-1 flex-column">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex gap-3 align-items-center text-white">
              <Placeholder className="img" />
              <Placeholder style={{ width: 100 }} />
            </div>
            <div className="d-flex gap-2 align-items-center text-secondary">
              <AiOutlineClockCircle style={{ height: 17, width: 17 }} />
              <Placeholder style={{ width: 30 }} />
            </div>
          </div>
          <div className="card-divider" />
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex gap-3 align-items-center text-white">
              <Placeholder className="img" />
              <Placeholder style={{ width: 100 }} />
            </div>
            <div className="d-flex gap-2 align-items-center text-secondary">
              <AiOutlineCalendar style={{ height: 17, width: 17 }} />
              <Placeholder style={{ width: 30 }} />
            </div>
          </div>
        </div>
      </Placeholder>
    );
  }

  return (
    <Card
      bg="dark"
      className="large-card always mt-20 d-flex flex-row gap-3 ps-3 cursor-pointer"
      onClick={handleOpenMatch}
    >
      <div className="score flex-column">
        <div>
          {showScores && typeof teamA.score !== 'number' && '-'}
          {showScores && typeof teamA.score === 'number' && (teamA.score || 0)}
          {!showScores && (predictions?.teamA || predictions?.myVote || !isEnded) && (
            <Button
              variant="outline-primary"
              className={predictions?.myVote || isEnded ? 'pe-none' : undefined}
              onClick={predictions?.myVote || isEnded ? undefined : handleVoteTeam(teamAData?.id)}
            >
              {predictions?.teamA
                ? `${getPredictionPercent(predictions, teamAData?.roster?.id)}%   `
                : undefined}
              {predictions?.myVote ? undefined : t('MATCHES.VOTE')}
            </Button>
          )}
        </div>
        <div>vs</div>
        <div>
          {showScores && typeof teamB.score !== 'number' && '-'}
          {showScores && typeof teamB.score === 'number' && (teamB.score || 0)}
          {!showScores && (predictions?.teamB || predictions?.myVote || !isEnded) && (
            <Button
              variant="outline-primary"
              className={predictions?.myVote || isEnded ? 'pe-none' : undefined}
              onClick={predictions?.myVote || isEnded ? undefined : handleVoteTeam(teamBData?.id)}
            >
              {predictions?.teamB
                ? `${getPredictionPercent(predictions, teamBData?.roster?.id)}%   `
                : undefined}
              {predictions?.myVote ? undefined : t('MATCHES.VOTE')}
            </Button>
          )}
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-column">
        <div className="d-flex align-items-center justify-content-between p-3">
          <div className="d-flex gap-3 align-items-center text-white">
            <Image
              className="img"
              src={(teamAData?.images || [])[0]?.url || defaultImage}
              alt=""
            />
            <div>{teamAData?.abbreviation || teamAData?.name}</div>
          </div>
          <div className="d-flex gap-2 align-items-center text-secondary">
            <AiOutlineClockCircle style={{ height: 17, width: 17 }} />
            <div className="d-flex flex-column">
              <div className="fs-small">{moment(data?.start).startOf('day').from(moment())}</div>
            </div>
          </div>
        </div>
        <div className="card-divider" />
        <div className="d-flex align-items-center justify-content-between p-3">
          <div className="d-flex gap-3 align-items-center text-white">
            <Image
              className="img"
              src={(teamBData?.images || [])[0]?.url || defaultImage}
              alt=""
            />
            <div>{teamBData?.abbreviation || teamBData?.name}</div>
          </div>
          <div className="d-flex gap-2 align-items-center text-secondary">
            <AiOutlineCalendar style={{ height: 17, width: 17 }} />
            <div className="d-flex flex-column">
              <div className="fs-small">{moment(data?.start).format('LL')}</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

TournamentMatchDetails.propTypes = {
  id: PropTypes.number.isRequired,
  showScores: PropTypes.bool.isRequired,
};

export { TournamentMatchDetails };
