import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Accordion } from 'react-bootstrap';

import { LatestTipsItem } from './LatestTipsItem';
import { LoadingSpinner } from '../../LoadingSpinner';
import { tipsActions, tipsSelectors } from '../../../redux/slices';
import { pages, routes } from '../../../data/menu';

const LatestTips = ({ className, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(tipsSelectors.selectLatestTipsLoading);
  const tips = useSelector(tipsSelectors.selectLatestTipsData);

  useEffect(() => {
    dispatch(tipsActions.loadLatestTips.base());
  }, []);

  return (
    <Accordion defaultActiveKey="latest-tips" flush className={className} {...props}>
      <Accordion.Item eventKey="latest-tips">
        <Accordion.Header className="d-lg-none">
          {t('TIPS.LATEST_TIPS')}
        </Accordion.Header>
        <Accordion.Body>
          <Card bg="dark" className="large-card">
            <Card.Header className="d-none d-lg-block">
              {t('TIPS.LATEST_TIPS')}
            </Card.Header>
            {isLoading ? (
              <LoadingSpinner />
            ) : tips.map((item, ind) => (
              <LatestTipsItem
                key={item._id}
                data={item}
                noMargin={ind === tips.length - 1}
              />
            ))}
            <Card.Footer>
              <Link to={routes[pages.tips]}>
                {t('TIPS.VIEW_ALL_TIPS')}
              </Link>
            </Card.Footer>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

LatestTips.propTypes = {
  className: PropTypes.string
};

LatestTips.defaultProps = {
  className: undefined
};
export { LatestTips };
