import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Image, Placeholder } from 'react-bootstrap';

import { MatchOdd } from '../MatchOdd';
import { teamsSelectors } from '../../../redux/slices';
import { getPredictionPercent } from '../../../helpers/utils';

const Team = ({ className, roster, odds, defaultImage, predictions, isEnded, canVote, onVote }) => {
  const { t } = useTranslation();
  const team = useSelector(state => teamsSelectors.selectTeamByRoster(state, roster));

  const handleVote = useCallback(() => {
    onVote({ team: team?.id, roster });
  }, [team, roster]);

  if (!team) {
    return (
      <Placeholder className={className} animation="glow">
        <Placeholder className="img-largest mb-3" style={{ borderRadius: '50%' }} />
        <Placeholder className="mb-3" style={{ width: 80 }} />
        <Placeholder style={{ width: 50 }} />
      </Placeholder>
    );
  }

  return (
    <div className={className}>
      <Image
        className="img-largest mb-2"
        src={team.images[0]?.url || defaultImage}
      />
      <Link to={`/teams/${team.id}`} className="text-white text-decoration-none mb-3">
        {team.abbreviation || team.name}
      </Link>
      <div className="d-flex gap-2">
        {(Object.keys(predictions || {}).length > 0 || predictions.myVote || isEnded) && (
          <Button variant="outline-primary" className="small text-uppercase mb-3 opacity-100" disabled>
            {`${getPredictionPercent(predictions, roster)}%`}
          </Button>
        )}
        {canVote && (
          <Button
            variant="outline-primary"
            className="small text-uppercase mb-3"
            onClick={handleVote}
          >
            {t('MATCHES.VOTE')}
          </Button>
        )}
      </div>
      {odds && (
        <MatchOdd odds={odds} />
      )}
    </div>
  );
};

Team.propTypes = {
  className: PropTypes.string,
  roster: PropTypes.number,
  odds: PropTypes.number,
  defaultImage: PropTypes.node,
  predictions: PropTypes.shape({
    teamA: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    teamB: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    myVote: PropTypes.number,
  }),
  onVote: PropTypes.func.isRequired,
  isEnded: PropTypes.bool,
  canVote: PropTypes.bool,
};

Team.defaultProps = {
  className: undefined,
  roster: undefined,
  odds: undefined,
  defaultImage: undefined,
  predictions: {},
  isEnded: false,
  canVote: false,
};

export { Team };
