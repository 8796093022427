import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ChartIcon from '../../../../assets/images/icons/chart.svg';
import StarIcon from '../../../../assets/images/icons/star.svg';

const GameStatCard = ({ className, image, stats, gameId }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('profile-game-stats-card', className)}
      style={{ backgroundImage: `url('/images/bg/games/game-${gameId}.png')` }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Image className="img-large" src={image} alt="" />
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column gap-2">
        <Image src={ChartIcon} alt="" style={{ height: 30 }} />
        <div className="fs-medium text-secondary">
          {t('PROFILE.STATS.PREDICTIONS')}
        </div>
        <div className="fs-large text-white">
          {stats.predictions || 0}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column gap-2">
        <Image src={StarIcon} alt="" style={{ height: 30 }} />
        <div className="fs-medium text-secondary">
          {t('PROFILE.STATS.WIN_RATE')}
        </div>
        <div className="fs-large text-white">
          {`${stats.winRate || 0}%`}
        </div>
      </div>
    </div>
  );
};

GameStatCard.propTypes = {
  className: PropTypes.string,
  gameId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image: PropTypes.string,
  stats: PropTypes.shape({
    predictions: PropTypes.number,
    winRate: PropTypes.number,
  }),
};

GameStatCard.defaultProps = {
  className: undefined,
  image: undefined,
  stats: {}
};

export { GameStatCard };
