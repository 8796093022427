import { all, put, call, takeLatest, select } from 'redux-saga/effects';

import * as api from '../../api';
import { notificationsActions, notificationsSelectors } from '../slices';
import { NOTIFICATIONS_PAGE_SIZE } from '../../data/constants';

export function* loadNotificationsFlow() {
  try {
    const { page, filter } = yield select(notificationsSelectors.selectData);
    const data = yield call(api.getNotifications, page + 1, filter, NOTIFICATIONS_PAGE_SIZE);
    yield put(notificationsActions.loadNotifications.success(data));
  } catch (error) {
    yield put(notificationsActions.loadNotifications.failed({ error }));
  }
}

export function* setNotificationReadFlow({ payload: { id } }) {
  try {
    const data = yield call(api.setNotificationRead, id);
    yield put(notificationsActions.setNotificationRead.success(data));
  } catch (error) {
    yield put(notificationsActions.setNotificationRead.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(notificationsActions.loadNotifications.types.BASE, loadNotificationsFlow),
    takeLatest(notificationsActions.setNotificationRead.types.BASE, setNotificationReadFlow),
  ]);
}
