import { createSlice } from '../../helpers/store';
import { NOTIFICATIONS_FILTERS } from '../../data/constants';

const initialState = {
  isLoading: false,
  filter: NOTIFICATIONS_FILTERS.ALL,
  unread: 0,
  total: 0,
  page: 0,
  data: []
};

const { actions, selectors, reducer } = createSlice({
  prefix: 'notifications',
  initialState,

  selectors: {
    selectData: state => ({ ...state }),
  },

  reducers: {
    setNotificationFilter: {
      base: (state, { payload }) => {
        state.filter = payload;
        state.page = 0;
      }
    },
    loadNotifications: {
      base: state => {
        state.isLoading = true;
      },
      success: (state, { payload: { unreadCount, itemCount, results, page } }) => {
        state.isLoading = false;
        state.unread = unreadCount;
        state.total = itemCount;
        state.page = page;
        if (page === 1) {
          state.data = results;
        } else {
          state.data = state.data.concat(results);
        }
      },
      failed: state => {
        state.isLoading = false;
      }
    },
    setNotificationRead: {
      success: (state, { payload }) => {
        state.data.forEach(item => {
          if (payload.includes(item._id)) {
            item.readAt = new Date();
          }
        });
        state.unread = Math.max(0, state.unread - payload.length);
      }
    },
    addNewNotification: {
      success: (state, { payload }) => {
        state.total += 1;
        state.unread += 1;
        state.data.unshift(payload);
      }
    },
  },
});

export { reducer as notifications, actions as notificationsActions, selectors as notificationsSelectors };
