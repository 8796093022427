import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Image, ProgressBar } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { MatchTeamRowPlaceholder } from './MatchTeamRowPlaceholder';

import { getPredictionPercent } from '../../../helpers/utils';
import { teamsSelectors } from '../../../redux/slices';

const MatchTeamRow = ({ roster, onVote, canVote, predictions, defaultImage }) => {
  const { t } = useTranslation();
  const team = useSelector(state => teamsSelectors.selectTeamByRoster(state, roster));

  const handleVote = useCallback(() => {
    onVote({ team: team?.id, roster });
  }, [team, roster]);

  if (!team) {
    return <MatchTeamRowPlaceholder />;
  }

  const progress = getPredictionPercent(predictions, roster);

  return (
    <div className="team-row d-flex gap-2 justify-content-between">
      <div className="d-flex gap-2 align-items-center">
        <Image
          className="img"
          src={team.images[0]?.url || defaultImage}
          alt={team.abbreviation || team.name}
        />
        <Link
          to={`/teams/${team.id}`}
          className="text-white text-decoration-none fs-medium text-break"
        >
          {team.abbreviation || team.name}
        </Link>
      </div>
      <div className="d-flex gap-2 align-items-center justify-content-end">
        <div className="custom-progress">
          <ProgressBar now={progress} />
          <span>{`${progress}%`}</span>
        </div>
        {canVote && (
          <Button variant="outline-primary" className="small text-uppercase" onClick={handleVote}>
            {t('MATCHES.VOTE')}
          </Button>
        )}
      </div>
    </div>
  );
};

MatchTeamRow.propTypes = {
  roster: PropTypes.number.isRequired,
  onVote: PropTypes.func.isRequired,
  canVote: PropTypes.bool,
  predictions: PropTypes.shape({
    teamA: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    teamB: PropTypes.shape({
      roster: PropTypes.number.isRequired,
      predictors: PropTypes.number.isRequired,
    }),
    myVote: PropTypes.number
  }),
  defaultImage: PropTypes.node
};

MatchTeamRow.defaultProps = {
  predictions: {},
  canVote: false,
  defaultImage: undefined
};

export { MatchTeamRow };
