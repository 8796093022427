import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchesActions, matchesSelectors } from '../redux/slices';

const useGames = () => {
  const dispatch = useDispatch();
  const games = useSelector(matchesSelectors.selectGames);

  useEffect(() => {
    if (!games.length) {
      dispatch(matchesActions.loadGames.base());
    }
  }, [games]);
};

export { useGames };
