import React from 'react';
import { useTranslation } from 'react-i18next';

import { TipsList } from '../TipsList';
import { useGames } from '../../../hooks';

const AllTips = () => {
  const { t } = useTranslation();
  useGames();
  return (
    <TipsList
      title={t('TIPS.ALL_TIPS')}
      withGamesFilter
      withUsername
      withLink
    />
  );
};

export { AllTips };
