import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, Placeholder, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import CalendarIcon from '../../../assets/images/icons/calendar-icon.svg';

import { Team } from './Team';
import { PlayerEmbedded } from './PlayerEmbedded';
import { MatchScores } from '../MatchScores';
import { CustomToggle } from '../../common';

import './styles.scss';

import { matchesActions, matchesSelectors, seriesSelectors, userSelectors } from '../../../redux/slices';
import { getTournamentTitle } from '../../../helpers/utils';
import { useDurationFromNow } from '../../../hooks';
import { SHOW_START_TIMER_BEFORE, VOTING_AFTER_START } from '../../../data/constants';

const MatchWidget = ({ matchId, className, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  const userData = useSelector(userSelectors.selectUserDetails);
  const serie = useSelector(state => seriesSelectors.selectSerieById(state, matchId));
  const { isStarted, isEnded } = useSelector(state => seriesSelectors.selectSerieStatus(state, matchId));
  const { matches, streams, isLoading } = useSelector(matchesSelectors.selectMatchDetails);
  const selectedStream = useSelector(matchesSelectors.selectSelectedStream);
  const gamesImagesMap = useSelector(matchesSelectors.selectGamesImages);
  const mapsOptions = useSelector(matchesSelectors.selectMatchMapsIndexes);
  const predictions = useSelector(state => matchesSelectors.selectPredictionById(state, matchId));

  const duration = useDurationFromNow(serie?.start, SHOW_START_TIMER_BEFORE);
  const isVotable = useDurationFromNow(serie?.start, null, VOTING_AFTER_START);
  const canVote = !!isVotable && !predictions?.myVote && !isEnded;

  const [teamA, teamB] = serie?.participants || [];

  const setSelectedStream = data => dispatch(matchesActions.setSelectedStream.base(data));

  const handleVote = useCallback(({ team, roster }) => {
    if (!userData) {
      loginWithRedirect({ redirectUri: window.location.href });
    } else {
      dispatch(matchesActions.addPredictionData.base({
        prediction: {
          team,
          roster,
          game: serie.game.id,
          serie: serie.id,
          prediction: 'serie',
        },
        participants: serie.participants
      }));
    }
  }, [userData, serie]);

  const mapResults = useMemo(() => (matches || []).filter(m => !!m?.map?.id).reduce((arr, match, ind) => ({
    ...arr,
    [ind]: {
      teamA: match.participants[0]?.score,
      teamB: match.participants[1]?.score,
    }
  }), {}), [matches]);

  return (
    <Row className={cx('match-widget', 'text-secondary', className)} {...props}>
      <Placeholder as={Col} xs={12} className="header" animation="glow">
        <div className="d-flex align-items-center gap-2">
          <Image src={CalendarIcon} alt="" />
          {serie?.start ? (
            moment(serie.start).format('dddd, MMMM DD')
          ) : (
            <Placeholder style={{ width: 120 }} />
          )}
        </div>
        {streams?.length > 1 && (
          <Dropdown align="end">
            <Dropdown.Toggle as={CustomToggle} className="form-select">
              <Image src={selectedStream?.platform?.images?.[0]?.url} className="img-smallest" />
              <span className="ms-3">{selectedStream?.display_name || selectedStream?.username}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              {streams.map(({ id, stream }) => (
                <Dropdown.Item key={id} onClick={() => setSelectedStream(stream)}>
                  <Image src={stream?.platform?.images?.[0]?.url} className="img-smallest" />
                  <span className="ms-3">{stream?.display_name || stream?.username}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="bg-after" />
      </Placeholder>
      <Col xs={12} md={6} className="stream">
        {isLoading ? (
          <Placeholder animation="glow">
            <Placeholder style={{ height: 300, width: '100%' }} />
          </Placeholder>
        ) : (
          <PlayerEmbedded streams={streams} />
        )}
      </Col>
      <Col xs={12} md={6} className="match">
        <Team
          className="d-flex justify-content-center align-items-center flex-column w-25"
          roster={teamA?.roster?.id}
          odds={null}
          defaultImage={gamesImagesMap[serie?.game?.id]}
          predictions={predictions}
          canVote={canVote}
          onVote={handleVote}
          isEnded={isEnded}
        />
        <div className="d-flex justify-content-start flex-column w-50 gap-2">
          <div className="d-flex align-items-center justify-content-center gap-1 mt-4">
            <Image src={gamesImagesMap[serie?.game?.id]} className="img-small" />
            <span className="fs-small">{getTournamentTitle(serie?.tournament?.title)}</span>
          </div>
          <MatchScores
            scoreA={teamA?.score}
            scoreB={teamB?.score}
            separator=":"
          />
          <div className="d-flex justify-content-center">
            {isStarted && !isEnded && (
              <span className="status green fs-normal">{t('MATCHES.STATUS_LIVE')}</span>
            )}
            {isStarted && isEnded && (
              <span className="status red fs-normal">{t('MATCHES.STATUS_ENDED')}</span>
            )}
            {!isStarted && !isEnded && (
              <span className="status fs-normal">{t('MATCHES.STATUS_NOT_STARTED')}</span>
            )}
          </div>
          {duration && (
            <div className="d-flex justify-content-center">
              {duration}
            </div>
          )}
          <div className="d-flex flex-column align-items-center gap-1 fs-medium mt-20">
            {mapsOptions.map((map) => (
              <span key={`map-w-${map}`}>
                {`${(mapResults[map - 1] || {}).teamA || 0}`}
                {` (${t('MAP')} ${map}) `}
                {`${(mapResults[map - 1] || {}).teamB || 0}`}
              </span>
            ))}
          </div>
        </div>
        <Team
          className="d-flex justify-content-center align-items-center flex-column w-25"
          roster={teamB?.roster?.id}
          odds={null}
          defaultImage={gamesImagesMap[serie?.game?.id]}
          predictions={predictions}
          canVote={canVote}
          onVote={handleVote}
          isEnded={isEnded}
        />
      </Col>
    </Row>
  );
};

MatchWidget.propTypes = {
  matchId: PropTypes.string.isRequired,
  className: PropTypes.string
};

MatchWidget.defaultProps = {
  className: undefined
};

export { MatchWidget };
