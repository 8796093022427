import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Accordion } from 'react-bootstrap';

import { TipsterRankItem } from './TipsterRankItem';
import { LoadingSpinner } from '../../LoadingSpinner';

import { userActions, userSelectors } from '../../../redux/slices';

import { pages } from '../../../data/menu';
import { LEADERBOARD_FILTERS, LEADERBOARDS_TABS } from '../../../data/constants';

const TipsterRank = ({ className, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeData = useSelector(state => (
    userSelectors.selectLeaderboards(state)[LEADERBOARD_FILTERS[LEADERBOARDS_TABS.MOST_DIAMONDS]]
  ));
  const { isLoading, data } = storeData || {};

  useEffect(() => {
    dispatch(userActions.loadLeaderboardsList.base({
      filter: LEADERBOARD_FILTERS[LEADERBOARDS_TABS.MOST_DIAMONDS],
      page: 0
    }));
  }, []);

  return (
    <Accordion defaultActiveKey="latest-tips" flush className={className} {...props}>
      <Accordion.Item eventKey="latest-tips">
        <Accordion.Header className="d-lg-none">
          {t('TIPS.TIPSTER_RANK')}
        </Accordion.Header>
        <Accordion.Body>
          <Card bg="dark" className="large-card">
            <Card.Header className="d-none d-lg-block">
              {t('TIPS.TIPSTER_RANK')}
            </Card.Header>
            {isLoading ? (
              <LoadingSpinner />
            ) : data?.map((item, ind) => (
              <TipsterRankItem
                key={item._id}
                ind={ind}
                noMargin={ind === data.length - 1}
                data={item}
              />
            ))}
            <Card.Footer>
              <Link to={`/${pages.leaderboards}#${LEADERBOARDS_TABS.MOST_DIAMONDS}`}>
                {t('TIPS.VIEW_ALL_RANKING')}
              </Link>
            </Card.Footer>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

TipsterRank.propTypes = {
  className: PropTypes.string
};

TipsterRank.defaultProps = {
  className: undefined
};
export { TipsterRank };
