import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import * as api from '../../api';
import {
  matchesActions, notificationsActions, seriesActions, teamsActions, userActions, userSelectors
} from '../slices';
import { PROFILE_FOLLOW_TYPE, TOKEN_STORAGE } from '../../data/constants';

export function* loadUserDetailsFlow() {
  let success = true;
  if (yield select(userSelectors.selectUserDetails)) {
    return;
  }
  try {
    const data = yield call(api.getUser);
    yield put(userActions.loadUserDetails.success(data));
  } catch (error) {
    localStorage.removeItem(TOKEN_STORAGE);
    success = false;
    // yield put(userActions.loadUserDetails.failed({ error }));
  } finally {
    if (success) {
      yield all([
        put(userActions.claimDailyLogin.base()),
        put(notificationsActions.loadNotifications.base())
      ]);
    }
  }
}
export function* resendConfirmationLinkFlow() {
  try {
    yield call(api.sendConfirmationEmail);
    yield put(userActions.resendConfirmationLink.success());
  } catch (error) {
    yield put(userActions.resendConfirmationLink.failed({ error }));
  }
}

export function* loadLeaderboardsListFlow({ payload: { filter, page } }) {
  try {
    const data = yield call(api.getPredictionsLeaderboards, filter, page + 1);
    yield put(userActions.loadLeaderboardsList.success({ filter, ...data }));
  } catch (error) {
    yield put(userActions.loadLeaderboardsList.failed({ error, filter }));
  }
}

export function* loadLeaderboardEventFlow({ payload: { id } }) {
  try {
    // const data = yield call(api.getPredictionsLeaderboards, filter, page + 1);
    yield put(userActions.loadLeaderboardEvent.success({ id }));
  } catch (error) {
    yield put(userActions.loadLeaderboardEvent.failed({ error }));
  }
}

export function* loadLeaderboardEventLeadersFlow({ payload: { page } }) {
  try {
    // TODO: change request for correct one
    const data = yield call(api.getPredictionsLeaderboards, 'uid', page + 1);
    yield put(userActions.loadLeaderboardEventLeaders.success(data));
  } catch (error) {
    yield put(userActions.loadLeaderboardEventLeaders.failed({ error }));
  }
}

export function* loadUserProfileFlow({ payload: { username } }) {
  try {
    const data = yield call(api.getProfile, username);
    yield put(userActions.loadUserProfile.success(data));
  } catch (error) {
    yield put(userActions.loadUserProfile.failed({ error }));
  }
}

export function* followUserFlow({ payload: { username } }) {
  try {
    yield call(api.sendFollowUser, username);
    yield put(userActions.followUser.success());
  } catch (error) {
    yield put(userActions.followUser.failed({ error }));
  }
}

export function* unfollowUserFlow({ payload: { username, isCurrentUser } }) {
  try {
    yield call(api.sendUnFollowUser, username);
    yield put(userActions.unfollowUser.success({ username, isCurrentUser }));
  } catch (error) {
    yield put(userActions.unfollowUser.failed({ error }));
  }
}

export function* removeFollowerFlow({ payload: { username } }) {
  try {
    yield call(api.sendRemoveFollower, username);
    yield put(userActions.removeFollower.success({ username }));
  } catch (error) {
    yield put(userActions.removeFollower.failed({ error }));
  }
}

export function* loadFollowDataFlow({ payload: { username, type } }) {
  try {
    let data = [];
    if (type === PROFILE_FOLLOW_TYPE.FOLLOWERS) {
      data = yield call(api.getFollowers, username);
    }
    if (type === PROFILE_FOLLOW_TYPE.FOLLOWINGS) {
      data = yield call(api.getFollowings, username);
    }
    yield put(userActions.loadFollowData.success({ type, data }));
  } catch (error) {
    yield put(userActions.loadFollowData.failed({ error }));
  }
}

export function* loadPredictionsForProfileFlow({ payload: { username, page, game } }) {
  try {
    const response = yield call(api.getPredictionsByUser, username, { page, game });
    yield all([
      put(userActions.loadPredictionsForProfile.success(response)),
      put(seriesActions.loadSeries.base({ ids: response.results.map(r => r.serie) })),
      put(matchesActions.loadPredictions.base({
        ids: response.results.map(r => r.prediction === 'serie' ? r.serie : r.match)
      })),
      put(teamsActions.loadTeamsByRosters.base({ ids: response.results.map(r => r.roster) })),
    ]);
  } catch (error) {
    yield put(userActions.loadPredictionsForProfile.failed({ error }));
  }
}

export function* claimDailyLoginFlow() {
  try {
    const data = yield call(api.claimDailyLogin);
    yield put(userActions.claimDailyLogin.success(data));
  } catch (error) {
    yield put(userActions.claimDailyLogin.failed({ error }));
  }
}

export function* updateProfileFlow({ payload: { id, data, callback } }) {
  try {
    if (typeof data.avatar !== 'string') {
      const docId = yield call(api.uploadDocument, data.avatar);
      data.avatar = yield api.getDocumentPath(docId);
    }
    const result = yield call(api.updateProfile, id, data);
    yield put(userActions.updateProfile.success(result));
  } catch (error) {
    yield put(userActions.updateProfile.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* deleteProfileFlow({ payload: { id, resetToken, callback } }) {
  try {
    yield call(api.deleteProfile, id);
    yield put(userActions.deleteProfile.success({ id }));
    if (resetToken) {
      localStorage.removeItem(TOKEN_STORAGE);
      window.location.pathname = '/';
    }
  } catch (error) {
    yield put(userActions.deleteProfile.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* resetPasswordFlow({ payload: { id, successMsg, callback } }) {
  try {
    yield call(api.resetPassword, id);
    yield put(userActions.resetPassword.success());
    alert(successMsg); // eslint-disable-line
  } catch (error) {
    yield put(userActions.resetPassword.failed({ error }));
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

export function* loadAdminDataFlow({ payload }) {
  try {
    const data = yield call(api.searchUsers, payload);
    yield put(userActions.loadAdminData.success(data));
  } catch (error) {
    yield put(userActions.loadAdminData.failed({ error }));
  }
}

export function* loadActivitiesFlow({ payload: { uid, ...query } }) {
  try {
    const data = yield call(api.loadUserActivities, uid, query);
    yield put(userActions.loadActivities.success(data));
  } catch (error) {
    yield put(userActions.loadActivities.failed({ error }));
  }
}

export function* blockProfileFlow({ payload: { id } }) {
  try {
    yield call(api.blockUser, id);
    yield put(userActions.blockProfile.success({ id }));
  } catch (error) {
    yield put(userActions.blockProfile.failed({ error }));
  }
}

export function* unblockProfileFlow({ payload: { id } }) {
  try {
    yield call(api.unblockUser, id);
    yield put(userActions.unblockProfile.success({ id }));
  } catch (error) {
    yield put(userActions.unblockProfile.failed({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(userActions.loadUserDetails.types.BASE, loadUserDetailsFlow),
    takeLatest(userActions.resendConfirmationLink.types.BASE, resendConfirmationLinkFlow),
    takeLatest(userActions.loadLeaderboardsList.types.BASE, loadLeaderboardsListFlow),
    takeLatest(userActions.loadLeaderboardEvent.types.BASE, loadLeaderboardEventFlow),
    takeLatest(userActions.loadLeaderboardEventLeaders.types.BASE, loadLeaderboardEventLeadersFlow),
    takeLatest(userActions.loadUserProfile.types.BASE, loadUserProfileFlow),
    takeLatest(userActions.followUser.types.BASE, followUserFlow),
    takeLatest(userActions.unfollowUser.types.BASE, unfollowUserFlow),
    takeLatest(userActions.removeFollower.types.BASE, removeFollowerFlow),
    takeLatest(userActions.loadFollowData.types.BASE, loadFollowDataFlow),
    takeLatest(userActions.loadPredictionsForProfile.types.BASE, loadPredictionsForProfileFlow),
    takeLatest(userActions.claimDailyLogin.types.BASE, claimDailyLoginFlow),
    takeLatest(userActions.updateProfile.types.BASE, updateProfileFlow),
    takeLatest(userActions.deleteProfile.types.BASE, deleteProfileFlow),
    takeLatest(userActions.resetPassword.types.BASE, resetPasswordFlow),
    takeLatest(userActions.loadAdminData.types.BASE, loadAdminDataFlow),
    takeLatest(userActions.loadActivities.types.BASE, loadActivitiesFlow),
    takeLatest(userActions.blockProfile.types.BASE, blockProfileFlow),
    takeLatest(userActions.unblockProfile.types.BASE, unblockProfileFlow),
  ]);
}
