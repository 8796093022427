import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Row, Col, Accordion, Tabs, Tab } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { GameFilter } from '../../filters';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TournamentRow } from '../TournamentRow';

import { matchesSelectors, tournamentsActions, tournamentsSelectors } from '../../../redux/slices';
import { TOURNAMENTS } from '../../../data/constants';
import { useGames } from '../../../hooks';

const PAGES = [TOURNAMENTS.PAST, TOURNAMENTS.ONGOING, TOURNAMENTS.UPCOMING];

const TournamentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { t } = useTranslation();

  const [page, setPage] = useState();
  const [filterGames, setFilterGames] = useState([]);

  const games = useSelector(matchesSelectors.selectGames);
  const tournamentsLoading = useSelector(state => tournamentsSelectors.selectIsLoading(state)[page]);
  const tournaments = useSelector(state => tournamentsSelectors.selectTournamentsByStatus(state, page));

  const filteredTournaments = useMemo(() => (
    (tournaments || []).filter(t => (filterGames.length > 0 ? filterGames.includes(t.game.id) : true))
  ), [tournaments, filterGames]);

  const handleChangePage = useCallback(page => {
    navigate({ hash: page }, { replace: true });
  }, [hash]);

  useGames();

  useEffect(() => {
    const page = hash.replace('#', '');
    if (PAGES.includes(page)) {
      setPage(page);
      dispatch(tournamentsActions.loadTournamentsByStatus.base({ key: page }));
    } else {
      navigate({ hash: PAGES[1] }, { replace: true });
    }
  }, [hash]);

  return (
    <Accordion defaultActiveKey="tournaments-list" flush>
      <Accordion.Item eventKey="tournaments-list">
        <Accordion.Header className="d-lg-none">
          {t('TOURNAMENTS.TITLE')}
        </Accordion.Header>
        <Accordion.Body>
          <div className="show-grid mb-20">
            <h3 className="d-none d-lg-block mb-3 text-nowrap">{t('TOURNAMENTS.TITLE')}</h3>
            <Row>
              <Col xs={12} md={4} className="mb-2">
                <GameFilter
                  options={games}
                  selection={filterGames}
                  setSelection={setFilterGames}
                />
              </Col>
              <Col xs={12} md={8} className="d-flex justify-content-end">
                <Tabs
                  activeKey={page}
                  onSelect={handleChangePage}
                  transition={false}
                  className="full-width full-width-md-none"
                >
                  {PAGES.map(p => (
                    <Tab
                      title={t(`TOURNAMENTS.${p.toUpperCase()}`)}
                      key={p}
                      eventKey={p}
                      style={{ flexGrow: 1 }}
                    />
                  ))}
                </Tabs>
              </Col>
            </Row>
          </div>
          <div className="mb-20">
            {tournamentsLoading && (
              <LoadingSpinner />
            )}
            {!tournamentsLoading && filteredTournaments.length === 0 && (
              <div className="text-white text-center">
                {t('TOURNAMENTS.NO_RESULTS')}
              </div>
            )}
            {filteredTournaments.map(item => (
              <TournamentRow key={item.id} type={page} data={item} />
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export { TournamentsList };
