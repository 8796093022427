import React from 'react';
import cx from 'classnames';
import { Image, Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MARKETPLACE_RARITY } from '../../../data/constants';
import { userSelectors } from '../../../redux/slices';

import './styles.scss';

import CaseCommon from '../../../assets/images/marketplace/cases/case-common.png';
import CaseUncommon from '../../../assets/images/marketplace/cases/case-uncommon.png';
import CaseRare from '../../../assets/images/marketplace/cases/case-rare.png';
import CaseEpic from '../../../assets/images/marketplace/cases/case-epic.png';
import CaseLegendary from '../../../assets/images/marketplace/cases/case-legendary.png';
import Diamond from '../../../assets/images/diamonds/diamond.svg';
import { getDocumentPath } from '../../../api';

const CaseImages = {
  [MARKETPLACE_RARITY.COMMON]: CaseCommon,
  [MARKETPLACE_RARITY.UNCOMMON]: CaseUncommon,
  [MARKETPLACE_RARITY.RARE]: CaseRare,
  [MARKETPLACE_RARITY.EPIC]: CaseEpic,
  [MARKETPLACE_RARITY.LEGENDARY]: CaseLegendary,
};

const MarketplaceItem = ({ data: { slug, rarity, price, items, chanceVisible }, onOpenCase }) => {
  const { t, i18n: { language } } = useTranslation();
  const userDetails = useSelector(userSelectors.selectUserDetails);

  const isAvailable = userDetails?.wallet?.diamonds > price;

  return (
    <Row className={cx('marketplace-item', 'marketplace-card', rarity)}>
      {rarity === MARKETPLACE_RARITY.RARE && (
        <div className={cx('rarity-borders', rarity)}>
          <div className="left-right" />
        </div>
      )}
      {[MARKETPLACE_RARITY.EPIC, MARKETPLACE_RARITY.LEGENDARY].includes(rarity) && (
        <>
          <div className={cx('rarity-borders', rarity)}>
            <div className="left-right" />
          </div>
          <div className={cx('rarity-borders', rarity)}>
            <div className="top-bottom" />
          </div>
        </>
      )}
      <Col
        xs={{ order: 2, span: 12 }}
        sm={{ order: 2, span: 12 }}
        md={{ order: 1, span: 8 }}
        className="title"
      >
        <div style={{ width: 250 }}>
          <h2 className="mb-5">{slug[language]}</h2>
          <Button className="btn-midnight selected text-uppercase" disabled={!isAvailable} onClick={onOpenCase}>
            <span className="me-4">{t('REWARDS.OPEN_CASE')}</span>
            <Image src={Diamond} alt="" className="me-2" />
            {price}
          </Button>
        </div>
        <div className="flex-grow-1">
          <div className="text-secondary text-uppercase fs-small mb-2">
            {t('REWARDS.ITEMS')}
          </div>
          {items.map((item) => (
            <div className="item">
              <div
                style={{
                  backgroundImage: `url("${getDocumentPath(item.image)}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: 60,
                  width: 40,
                }}
              />
              {chanceVisible && (
                <div className="chance">
                  {`${item.chance}%`}
                </div>
              )}
              <div className="text-uppercase" style={{ color: item.color }}>
                {item.slug[language]}
              </div>
            </div>
          ))}
        </div>
      </Col>
      <Col xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} md={{ order: 2, span: 4 }} className="prise-image">
        <Image src={CaseImages[rarity]} alt={rarity} />
      </Col>
    </Row>
  );
};

MarketplaceItem.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.object,
    rarity: PropTypes.oneOf(Object.values(MARKETPLACE_RARITY)),
    price: PropTypes.number,
    chanceVisible: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.object,
      image: PropTypes.string,
      color: PropTypes.string,
      chance: PropTypes.number,
    }))
  }).isRequired,
  onOpenCase: PropTypes.func.isRequired
};

export { MarketplaceItem };
